import * as React from "react";
import { Box } from "@mui/material";

import ListItemText from "@mui/material/ListItemText";

import { useEffect } from "react";
import { useState } from "react";
import LatestNotificationItem from "./LatestNotificationItem";

const decodeLocalData = (name) => {
  let result = atob(window.localStorage.getItem(name));
  return JSON.parse(result);
};
export default function LatestNotification({}) {
  let localData = JSON.parse(localStorage.getItem("wmData"));
  const [latestNotifications, setLatestNotifications] = useState([]);
  console.log("latestNotifications:", latestNotifications);
  const [oldNotifications, setOldNotifications] = useState([]);
  //   const [oldNotifications, setOldNotifications] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);

  useEffect(() => {
    const formData = new FormData();
    formData.append("action", "check");
    formData.append("accn_id", localData?.accn_id);

    fetch(
      "https://whatsapp.ghorami.com/api/login/api_notice/notif_latest.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => setLatestNotifications(result))
      .catch((error) => console.log("fetchedServicePic=> ", error));
  }, [pageRefresh]);

  const turnOffNotification = (notification_id) => {
    const formData = new FormData();
    formData.append("action", "statr");
    formData.append("prefa", notification_id);
    formData.append("accn_id", localData?.accn_id);
    fetch("https://whatsapp.ghorami.com/api/notice/t_notify_off.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resultWWW:", result);
        setPageRefresh(!pageRefresh);
      })
      .catch((error) => console.log("fetchedServicePic=> ", error));
  };
  const DeleteNotification = (notification_id) => {
    const formData = new FormData();
    formData.append("action", "statr");
    formData.append("prefa", notification_id);
    formData.append("accn_id", localData?.accn_id);
    fetch("https://whatsapp.ghorami.com/api/notice/t_notify_delete.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resultWWW:", result);
        setPageRefresh(!pageRefresh);
      })
      .catch((error) => console.log("fetchedServicePic=> ", error));
  };

  return (
    <>
      <ListItemText
        sx={{ ml: 2 }}
        primary="Recent"
        primaryTypographyProps={{
          fontSize: 20,
          fontWeight: "bold",
          letterSpacing: 0,
          backgroundColor: "#F5F5F5",
          padding: "0.5rem",
          borderRadius: "0.5rem",
        }}
      />
      {latestNotifications?.map((notification, index) => {
        return (
          <Box>
            <LatestNotificationItem
              notification={notification}
              index={index}
              length={latestNotifications?.length}
              DeleteNotification={DeleteNotification}
              turnOffNotification={turnOffNotification}
            />
          </Box>
        );
      })}
    </>
  );
}
