import React from "react";
import {
  List,
  Button,
  Box,
  ListItemButton,
  Stack,
  Paper,
  IconButton,
  Avatar,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useEffect } from "react";
import { useState } from "react";
import { Delete, MoreHoriz, SensorsOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const decodeLocalData = (name) => {
  let result = atob(window.localStorage.getItem(name));
  return JSON.parse(result);
};
const LatestNotificationItem = ({
  index,
  notification,
  length,
  DeleteNotification,
  turnOffNotification,
}) => {
  let localData = decodeLocalData("gh_sl@g");

  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const handleView = () => {
    const formData = new FormData();
    formData.append("action", "statr");
    formData.append("prefa", notification?.rid);
    formData.append("SopnoID", localData?.sopnoid);

    fetch("https://ghorami.com/profile/notice/t_notify_stat.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("fetchedServicePic=> ", error));
  };

  if (notification?.seen == 0) {
    handleView();
  }
  return (
    <div>
      <List sx={{ width: "100%", bgcolor: "background.paper" }} key={index}>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={notification?.user_image} />
          </ListItemAvatar>
          <ListItemText
            primary={
              //   <Box
              //     sx={{
              //       fontSize: "1rem",
              //       fontWeight: "bold",
              //     }}
              //   >
              //     {/* dangerouslySetInnerHTML={{ __html: }} */}
              //     <Typography>
              //       {" "}
              //       dangerouslySetInnerHTML={{ __html: notification?.notifi }}
              //     </Typography>
              //   </Box>
              <Typography
                sx={{ fontSize: "12pt", textDecoration: "none !important" }}
                dangerouslySetInnerHTML={{ __html: notification?.notifi }}
              />
            }
            secondary={
              <Stack
                direction={"row"}
                justifyContent={"left"}
                alignItems="center"
                spacing={2}
                sx={{ marginTop: "10px" }}
              >
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={() => {
                    navigate("/" + notification?.final_ip);
                  }}
                >
                  View
                </Button>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "10pt",
                    fontStyle: "italic",
                  }}
                >
                  {notification?.timeStamp}
                </Typography>
              </Stack>
            }
          />
          <Box sx={{ position: "relative" }}>
            <IconButton
              size="medium"
              //   color="success"
              sx={{ backgroundColor: "#cadfcc", borderRadius: "50px" }}
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <MoreHoriz />
            </IconButton>
            {toggle && (
              <Paper
                elevation={4}
                sx={{
                  position: "absolute",
                  top: length - 1 !== index ? "40px" : "none",
                  zIndex: 10,
                  bottom: length - 1 !== index ? "none" : "40px",
                }}
              >
                <List
                  className="navListArea"
                  component={Stack}
                  direction="column"
                  sx={{ width: "130px" }}
                >
                  <ListItem
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                      margin: "0px",
                    }}
                    className="subNavList"
                    disablePadding
                  >
                    <ListItemButton
                      sx={{ width: "100%", paddingY: "0px" }}
                      size="small"
                      onClick={() => DeleteNotification(notification?.rid)}
                    >
                      <ListItemIcon>
                        <Delete
                          style={{ fontSize: "14pt", marginRight: "8px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className="subNavListButtonText"
                        sx={{ color: "#000", fontSize: "8px" }}
                        primary="Delete"
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",

                      margin: "0px",
                    }}
                    className="subNavList"
                    disablePadding
                  >
                    <ListItemButton
                      sx={{ width: "100%", paddingY: "0px" }}
                      size="small"
                      onClick={() => turnOffNotification(notification?.rid)}
                    >
                      <ListItemIcon>
                        <SensorsOff
                          style={{ fontSize: "14pt", marginRight: "8px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className="subNavListButtonText"
                        sx={{ color: "#000", fontSize: "8px" }}
                        primary="Turn off"
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Paper>
            )}
          </Box>
        </ListItem>

        <Divider variant="inset" component="li" />
      </List>
    </div>
  );
};

export default LatestNotificationItem;
