import { Clear, HighlightOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const InteractiveReplayActionForm = ({
  setAddNewBtn,
  referState,
  accountNumber,
  selectedTemplate,
  selectedReplyBtnInfo,
  selectedCustomer,
  dropDownBtn,
  setDropDownBtn,
  selectedBtn,
  setSelectedBtn,
  postInfo,
  setPostInfo,
  setTemplate,
  setListReplyBtn,
  setConversation,
  setSelectedTemplate,
  handleBotResponse,
}) => {
  console.log("selectedReplyBtnInfo:", selectedReplyBtnInfo);
  console.log("selectedTemplate:", selectedTemplate);

  const focus = useRef(null);
  const focus2 = useRef(null);
  const focus1 = useRef(null);
  const focus3 = useRef(null);
  const focus4 = useRef(null);
  const focus5 = useRef(null);
  const btn1 = useRef(null);
  const btn2 = useRef(null);
  const btn3 = useRef(null);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [templateMessageBotInfo, setTemplateMessageBotInfo] = useState([]);
  console.log("templateMessageBotInfoBTN:", templateMessageBotInfo?.buttons);
  const [tempParam, setTempParam] = useState([]);

  const [inputField, setInputField] = useState({});
  console.log("inputFieldinteractive:", inputField);
  const [buttonFields, setButtonFields] = useState([{ id: 0 }]);
  console.log("buttonFields:", buttonFields);
  const [nameAlert, setNameAlert] = useState(false);
  const [variable, setVariable] = useState(1);
  const [variableArray, setVariableArray] = useState([1]);
  const [buttonAlert, setButtonAlert] = useState(false);
  const [allPhone_code, setAllPhone_code] = useState([]);
  const [immediateInputValue, setImmediateInputValue] = useState("");
  console.log("immediateInputValue:", immediateInputValue);

  const [allButton, setAllButton] = useState([]);
  const [botList, setBotList] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    let newTemplateMessageBotInfo = templateMessageBotInfo;
    newTemplateMessageBotInfo.template_type = selectedTemplate;
    newTemplateMessageBotInfo.template_for = dropDownBtn
      ? selectedReplyBtnInfo?.tem_name
      : selectedBtn?.btn_title;
    setTemplateMessageBotInfo(newTemplateMessageBotInfo);
  }, [selectedTemplate, selectedReplyBtnInfo?.tem_name]);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch("https://whatsapp.ghorami.com/api/chatbot_api/get_all_item.php")
      .then((res) => res.json())
      .then((result) => {
        setBotList(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_wap_template_btn.php?poster_id=${parsedObject?.poster}&accn_id=${parsedObject?.accn_id}`
    )
      .then((res) => res.json())
      .then((result) => setAllButton(result));
  }, []);
  useEffect(() => {
    fetch(`https://ghorami.com/profile/login/api_booking/country_list.php`)
      .then((res) => res.json())
      .then((result) => {
        setAllPhone_code(result);
      });
  }, []);

  const replaceAt = (string, index, char) => {
    let a = string.split("");
    a[index] = char;
    return a.join("");
  };
  useEffect(() => {
    let newArr = [];
    let aLength = (templateMessageBotInfo?.details?.match(/{{/g) || [])?.length;
    console.log("aLength:", aLength);

    for (let i = 0; i <= aLength; i++) {
      newArr.push(i + 1);
    }

    setVariableArray([...newArr]);
    let newFieldarr = [];

    if (newArr?.length > 1) {
      newArr?.map((element, index) => {
        newFieldarr?.push({
          id: index,
          variant_no: element,
          variant_value: templateMessageBotInfo?.temp_peram?.[index]
            ? templateMessageBotInfo?.temp_peram?.[index]?.variant_value
            : "",
        });
      });
    }
    console.log("newFieldarr:", newFieldarr);
    newFieldarr.pop();

    setTempParam(newFieldarr);
    console.log(
      "lengthString",
      (templateMessageBotInfo?.details?.match(/{{/g) || []).length
    );
    setVariable(aLength);

    let splitedArray = templateMessageBotInfo?.details?.split("{{");
    console.log("splitedArray1:", splitedArray);
    for (let i = 1; i < splitedArray?.length; i++) {
      console.log("splitedArray[i][0]:", splitedArray[i][3]);
      for (let j = 1; j < newArr?.length; j++) {
        if (i === j) {
          splitedArray[i] = replaceAt(splitedArray[i], 0, "{{" + newArr[j - 1]);
        }
      }
    }
    console.log("splitedArray2:", splitedArray);
    let newTemplateMessageBotInfo = templateMessageBotInfo;
    newTemplateMessageBotInfo.details = splitedArray?.join("");
    setTemplateMessageBotInfo(newTemplateMessageBotInfo);
  }, [variable, templateMessageBotInfo?.details]);

  console.log("templateMessageBotInfostr:", templateMessageBotInfo?.details);

  const handleDownKey = (e) => {
    console.log("selectionStart:", e.target.selectionStart);
    if (e.keyCode === 8) {
      console.log("downKey", e.target.value);

      if (immediateInputValue[e.target.selectionStart - 1] === "}") {
        let str1 = immediateInputValue.substring(0, e.target.selectionStart);

        let str2 = immediateInputValue.substring(
          e.target.selectionStart,
          immediateInputValue?.length
        );

        str1.lastIndexOf("{{");
        console.log('str1.lastIndexOf("{{"):', str1.lastIndexOf("{{"));
        let subString = str1.substring(0, str1.lastIndexOf("{{"));
        console.log("subString:", subString);

        let newTemplateMessageBotInfo = templateMessageBotInfo;
        newTemplateMessageBotInfo.details = "";

        setTemplateMessageBotInfo(newTemplateMessageBotInfo);
        newTemplateMessageBotInfo.details = subString + str2;
        setTemplateMessageBotInfo(newTemplateMessageBotInfo);
      }
    }
    setImmediateInputValue(e.target.value);
  };
  const handleVariable = () => {
    console.log("variable:", variable);
    console.log("variableArray:", variableArray);

    let newTemplateMessageBotInfo = templateMessageBotInfo;
    newTemplateMessageBotInfo.details =
      newTemplateMessageBotInfo.details + "{{" + variableArray[variable] + "}}";

    setTemplateMessageBotInfo(newTemplateMessageBotInfo);
    setImmediateInputValue(newTemplateMessageBotInfo.details);
    setVariableArray([...variableArray]);
    focus5.current.focus();
  };

  const handleFormChange = (index, event) => {
    event.target.value = event.target.value.replace(" ", "_");

    let data = [...buttonFields];
    console.log("datattt:", data);
    if (event.target.name === "url_type") {
      data[index].btn_value = "";
    }
    if (event.target.name === "btn_type") {
      data[index] = {};
    }
    if (
      data[index].btn_type === "URL" &&
      data[index].url_type === "dynamic" &&
      event.target.name === "btn_value"
    ) {
      if (
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
          event.target.value
        )
      ) {
        if (event.target.value?.includes("/{")) {
          data[index][event.target.name] = event.target.value;
        } else {
          data[index][event.target.name] =
            event.target.value.replace(/^\/+|\/+$/g, "") + "/{{1}}";
        }
      } else {
        setButtonAlert("*Invalid url");
        data[index][event.target.name] = "";
      }
    } else if (
      data[index].btn_type === "URL" &&
      data[index].url_type === "static" &&
      event.target.name === "btn_value"
    ) {
      if (
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
          event.target.value
        )
      ) {
        data[index][event.target.name] = event.target.value;
      } else {
        setButtonAlert("*Invalid url");
        data[index][event.target.name] = "";
      }
    } else {
      data[index][event.target.name] = event.target.value;
      console.log("ggbrooo");
    }

    data[index].id = index;
    setButtonFields(data);

    let newtemplateMessageBotInfo = templateMessageBotInfo;
    newtemplateMessageBotInfo.buttons = buttonFields;
    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    setButtonAlert(false);
    // });
    // }
  };

  const handleNameChange = (event) => {
    event.target.value = event.target.value.replace(" ", "_");

    setNameAlert(false);
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/check_template_name.php?tem_name=${event.target.value}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resultsdsdrrrsd:", result);

        if (result[0]?.id && result) {
          setNameAlert("*Name Already Exist");
          const newtemplateMessageBotInfo = templateMessageBotInfo;
          newtemplateMessageBotInfo[event.target.name] = "";
          setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
        } else {
          const newtemplateMessageBotInfo = templateMessageBotInfo;
          newtemplateMessageBotInfo[event.target.name] = event.target.value;
          setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
          setNameAlert(false);
          // }
        }
      });
    // }
  };

  const addNewTemplate = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    let updateInfo = templateMessageBotInfo;
    updateInfo.user_id = parsedObject?.SopnoID;
    updateInfo.refer = parsedObject?.accn_id;
    updateInfo.template_type = selectedTemplate;
    updateInfo.item_name = selectedCustomer?.Name;
    updateInfo.template_for = dropDownBtn
      ? selectedReplyBtnInfo?.tem_name
      : selectedBtn?.btn_title;

    let newArray = variableArray;
    newArray?.pop();
    updateInfo.param_queue = templateMessageBotInfo?.details?.includes("{{1}}")
      ? newArray.join(",")
      : "";

    const formData = new FormData();
    formData.append("panel_idc", accountNumber);
    formData.append("template_type", selectedTemplate);
    formData.append("new_systemplate", JSON.stringify(updateInfo));

    fetch(
      "https://whatsapp.ghorami.com/api/chatbot_api/wm_autobot_template_new.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.[0]?.result === "success") {
          setTemplate(true);
          setListReplyBtn(false);
          setConversation(false);
          setSelectedTemplate(false);
          setSelectedBtn(false);
          setDropDownBtn(false);
          handleBotResponse();
        }
      })
      .catch((error) => {
        console.log("messagePost=> ", error);
      });
  };
  const addButtonFields = () => {
    let id = Math.floor(Math.random() * 1000000000 + 1);
    let newField = { id: id };
    if (buttonFields.length <= 2) {
      setButtonFields([...buttonFields, newField]);
    } else {
      setButtonFields([...buttonFields]);
    }
  };

  const handleDeleteButtonFields = (id) => {
    let filteredField = buttonFields.filter((element) => element.id != id);
    setButtonFields([...filteredField]);
  };

  const clear = () => {
    setTemplateMessageBotInfo({});
    setInputField({});
    setButtonFields([{ id: 0 }]);
    focus.current.children[0].value = "";
    focus1.current.children[1].value = "";
    focus2.current.children[1].value = "";
    // focus2.current.removeChild(focus2.current.children[0]);
    focus4.current.children[0].value = "";
    btn1.current.children[0].value = "";
    btn2.current.children[0].value = "";
    btn3.current.children[0].value = "";
    focus5.current.value = "";
    console.log("focus.current:", focus2.current.children[1]);
  };
  const handleButtonChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(value);
    const newtemplateMessageBotInfo = templateMessageBotInfo;
    newtemplateMessageBotInfo.request = value.join(", ");
    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
  };
  const handleItemChange = (event) => {
    const newtemplateMessageBotInfo = templateMessageBotInfo;
    const newInputField = inputField;
    newtemplateMessageBotInfo.item_title = event.target.value;
    newInputField.item_title = event.target.value;
    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    setInputField({ ...newInputField });
  };

  const handleVarientFormChange = (index, event) => {
    let data = [...tempParam];

    data[index][event.target.name] = event.target.value;
    data[index].id = index;
    setTempParam(data);

    let newUpdateInfo = templateMessageBotInfo;
    newUpdateInfo.temp_peram = tempParam;
    setTemplateMessageBotInfo({ ...newUpdateInfo });
    setButtonAlert(false);
  };
  const handleDeleteVarient = (variant_no) => {
    let strToReplace = "{{" + variant_no + "}}";
    console.log("strToReplace:", strToReplace);
    let newTemplateMessageBotInfo = templateMessageBotInfo;

    let remaining = tempParam?.filter(
      (element) => element?.variant_no !== variant_no
    );

    newTemplateMessageBotInfo.temp_peram = remaining;

    newTemplateMessageBotInfo.details =
      newTemplateMessageBotInfo?.details?.replace(strToReplace, "");
    console.log(
      "newTemplatd44555:",
      newTemplateMessageBotInfo?.details?.replace(strToReplace, "")
    );
    setTemplateMessageBotInfo({ ...newTemplateMessageBotInfo });
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "Name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "item_title") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "header") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "footer") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "language") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "category") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "details") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (isFieldValid) {
      if (event.target.name === "details") {
        setImmediateInputValue(event.target.value);
      }
      if (event.target.name === "item_title") {
        event.target.value = event.target.value.replace(" ", "_");
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newtemplateMessageBotInfo = templateMessageBotInfo;
        newtemplateMessageBotInfo[event.target.name] = event.target.value;
        setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
      } else {
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newtemplateMessageBotInfo = templateMessageBotInfo;
        newtemplateMessageBotInfo[event.target.name] = event.target.value;
        setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
      }
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      setTemplateMessageBotInfo(newField);
    }
  };
  return (
    <Box sx={{}}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        // sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Template Type
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              spacing={1}
            >
              {" "}
              <OutlinedInput
                ref={focus}
                type="text"
                name="template_type"
                size="small"
                value={selectedTemplate}
                placeholder="item"
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  fontSize: "9pt",
                }}
                // onChange={handleChange}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Template for
              </Typography>
              {inputField.fieldErrorAlert === "template_title" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus}
              type="text"
              name="template_for"
              size="small"
              value={
                dropDownBtn
                  ? selectedReplyBtnInfo?.tem_name
                  : selectedBtn?.btn_title
              }
              placeholder="item"
              sx={{
                width: "100%",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              // onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Name
              </Typography>
              {nameAlert && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  {nameAlert}
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus1}
              type="text"
              name="Name"
              size="small"
              placeholder="Name"
              // value={templateMessageBotInfo?.Name}
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleNameChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Language
              </Typography>
            </Stack>

            <Select
              // value={age}
              ref={focus2}
              name="language"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"en_US"}>English</MenuItem>
              <MenuItem value={"bn_BD"}>Bangla</MenuItem>
              <MenuItem value={"ar_UAE"}>Arabic</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "language" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Category
              </Typography>
            </Stack>

            <Select
              // value={age}

              name="category"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={"marketing"}>Marketing</MenuItem>
              <MenuItem value={"transactional"}>Transactional</MenuItem>
              <MenuItem value={"OTP"}>OTP</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "category" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Header
              </Typography>
              {inputField.fieldErrorAlert === "header" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Header Field missing
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus4}
              type="text"
              name="header"
              size="small"
              placeholder="Header"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Info/Details
              </Typography>
              {inputField.fieldErrorAlert === "details" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
              <Button
                size="small"
                sx={{ fontSize: "7pt", color: "black", fontWeight: 700 }}
                onClick={() => {
                  handleVariable();
                }}
              >
                Add variable
              </Button>
            </Stack>

            <TextareaAutosize
              ref={focus5}
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter about your Service"
              value={templateMessageBotInfo.details}
              inputProps={{
                maxLength: 800,
              }}
              name="details"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",

                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
              onKeyDown={handleDownKey}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
            spacing={2}
          >
            <Stack spacing={0.5} sx={{ width: "40%" }}>
              {tempParam?.map((element, index) => (
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                    }}
                  >
                    #{element?.variant_no}
                  </Typography>
                  <OutlinedInput
                    type="text"
                    name="variant_value"
                    size="small"
                    placeholder="Enter variant value"
                    // value={element?.variant_value}
                    onBlur={(event) => handleVarientFormChange(index, event)}
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "9pt",
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleDeleteVarient(element?.variant_no);
                    }}
                  >
                    <Clear sx={{ fontSize: "12pt" }} />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
            {/* {tempParam?.length > 0 && (
              <Stack spacing={0.5} sx={{ width: "15%" }}>
                {tempParam[tempParam?.length - 1]?.variant_value ? (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      // updateVariant();
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    disabled
                    sx={{ textTransform: "capitalize" }}
                  >
                    Update
                  </Button>
                )}
              </Stack>
            )} */}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Footer
              </Typography>
              {/* {inputField.fieldErrorAlert === "footer" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *footer Field missing
                </small>
              )} */}
            </Stack>

            <OutlinedInput
              ref={focus4}
              type="text"
              name="footer"
              size="small"
              placeholder="footer"
              sx={{
                width: "100%",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "9pt",
              fontWeight: 500,
              marginLeft: "9px",
              marginTop: "8px",
              marginBottom: "-3px",
              cursor: "pointer",
            }}
            onClick={addButtonFields}
          >
            Add Button +
          </Typography>

          {buttonAlert && (
            <small style={{ color: "#CB0101", fontSize: "8pt" }}>
              {buttonAlert}
            </small>
          )}
        </Stack>
        {buttonFields?.map((input, index) => {
          return (
            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ boxSizing: "border-box" }}
              >
                <Stack
                  alignItems={"flex-start"}
                  sx={{
                    width: "90%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                    }}
                  >
                    Button Type
                  </Typography>
                  <Select
                    // value={age}
                    ref={btn1}
                    type="text"
                    name="btn_type"
                    size="small"
                    placeholder="Type"
                    onChange={(event) => handleFormChange(index, event)}
                    displayEmpty
                    sx={{
                      width: "100%",

                      borderRadius: "6px",

                      fontSize: "9pt",
                    }}
                  >
                    <MenuItem value={"QUICK_REPLY"}>Quick Replay</MenuItem>
                    <MenuItem value={"PHONE_NUMBER"}>
                      Call Phone Number
                    </MenuItem>
                    <MenuItem value={"URL"}>Visit Website</MenuItem>
                    <MenuItem value={"LIST_REPLY"}>List Reply</MenuItem>
                  </Select>
                </Stack>
                <Stack
                  alignItems={"flex-start"}
                  sx={{
                    width: "90%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                    }}
                  >
                    Button Title
                  </Typography>
                  <OutlinedInput
                    ref={btn2}
                    type="text"
                    name="btn_title"
                    size="small"
                    placeholder="Title"
                    // value={input?.btn_title}
                    value={input?.btn_title ? input?.btn_title : ""}
                    sx={{
                      width: "100%",

                      borderRadius: "6px",

                      fontSize: "9pt",
                      boxSizing: "border-box",
                    }}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </Stack>
                {input?.btn_type === "PHONE_NUMBER" && (
                  <Stack
                    alignItems={"flex-start"}
                    sx={{
                      width: "40%",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "8pt",
                        fontWeight: 400,
                      }}
                    >
                      Country
                    </Typography>
                    <Select
                      displayEmpty
                      onChange={(event) => handleFormChange(index, event)}
                      size="small"
                      name="phone_code"
                      placeholder="Request"
                      value={input?.phone_code ? input?.phone_code : ""}
                      sx={{
                        width: "100%",
                        borderRadius: "6px",
                        fontSize: "9pt",
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {allPhone_code?.map((element, index) => (
                        <MenuItem key={index} value={element.phone_code}>
                          {element.phone_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                )}
                {input?.btn_type === "URL" && (
                  <Stack
                    alignItems={"flex-start"}
                    sx={{
                      width: "40%",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "8pt",
                        fontWeight: 400,
                      }}
                    >
                      Url Type
                    </Typography>
                    <Select
                      displayEmpty
                      onChange={(event) => handleFormChange(index, event)}
                      size="small"
                      name="url_type"
                      placeholder="Request"
                      sx={{
                        width: "100%",

                        borderRadius: "6px",
                        fontSize: "9pt",
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem key={index} value={"static"}>
                        Static
                      </MenuItem>
                      <MenuItem key={index} value={"dynamic"}>
                        Dynamic
                      </MenuItem>
                    </Select>
                  </Stack>
                )}
                <Stack
                  alignItems={"flex-start"}
                  sx={{
                    width: "90%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                    }}
                  >
                    {input?.btn_type === "PHONE_NUMBER" && "Phone Number"}
                    {input?.btn_type === "URL" && "Web Url"}
                    {(input?.btn_type === "QUICK_REPLY" ||
                      input?.btn_type === "LIST_REPLY" ||
                      !input?.btn_type) &&
                      "Buton Value"}
                  </Typography>{" "}
                  {console.log("templateMessageBotInfoBTN2:", input)}
                  <OutlinedInput
                    ref={btn3}
                    type={
                      input?.btn_type === "PHONE_NUMBER" ? "number" : "text"
                    }
                    // value={ 10}
                    value={input?.btn_value ? input?.btn_value : ""}
                    name="btn_value"
                    size="small"
                    placeholder="value"
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "9pt",
                      boxSizing: "border-box",
                    }}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </Stack>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    handleDeleteButtonFields(input.id);
                  }}
                >
                  <HighlightOff fontSize="inherit" />
                </IconButton>
              </Stack>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                setTemplate(true);
                setListReplyBtn(false);
                setConversation(false);
                setSelectedTemplate(false);
                setSelectedBtn(false);
                setDropDownBtn(false);
                handleBotResponse();
                // clear();
                // setAddNewBtn(false);
              }}
            >
              Back
            </Button>

            {templateMessageBotInfo.header &&
            !nameAlert &&
            !buttonAlert &&
            templateMessageBotInfo.details &&
            // templateMessageBotInfo.template_type &&
            // templateMessageBotInfo.template_for &&
            templateMessageBotInfo.language &&
            templateMessageBotInfo.category &&
            templateMessageBotInfo.Name &&
            // templateMessageBotInfo.footer &&
            ((buttonFields[0]?.btn_title &&
              buttonFields[0]?.btn_type &&
              buttonFields[0]?.btn_value) ||
              (buttonFields[0]?.btn_title &&
                buttonFields[0]?.btn_type &&
                buttonFields[0]?.phone_code &&
                buttonFields[0]?.btn_value) ||
              (buttonFields[0]?.btn_title &&
                buttonFields[0]?.btn_type &&
                buttonFields[0]?.btn_value)) ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  addNewTemplate();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          This is a success message!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InteractiveReplayActionForm;
