import { CloudUpload, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "../../css/StartCampaignForm.css";

const StartCampaignForm = ({
  startCampaignInfo,
  setStartCampaignInfo,
  setAddNewBtn,
}) => {
  const [inputField, setInputField] = useState({});
  const [pdfAlertMessage, setPdfAlertMessage] = useState("");
  const [attachment, setAttachment] = useState("");

  const clear = () => {
    setStartCampaignInfo({});
    setInputField({});
  };

  const handleAttachment = (e) => {
    if (e.target.files[0].type != "text/csv") {
      e.target.value = null;
      setPdfAlertMessage("Please upload CSV file only!");
    } else {
      setAttachment(URL.createObjectURL(e.target.files[0]));
      let newInfo = startCampaignInfo;
      newInfo.pdf = e.target.files[0];
      setStartCampaignInfo({ ...newInfo });
      setPdfAlertMessage("");
    }
  };

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "template") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "campaign_name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "set_time") {
      // isFieldValid = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/.test(event.target.value);
      isFieldValid = true;
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newstartCampaignInfo = startCampaignInfo;
      newstartCampaignInfo[event.target.name] = event.target.value;
      setStartCampaignInfo({ ...newstartCampaignInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box sx={{ paddingY: "15px", paddingX: "20px" }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Select Template
              </Typography>
              {inputField.fieldErrorAlert === "template" && (
                <small style={{ color: "#CB0101" }}>
                  *Select valid template
                </small>
              )}
            </Stack>

            <Select
              name="template"
              size="small"
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"ghorami_welcom"}>ghorami_welcom</MenuItem>
              <MenuItem value={"a20"}>Twenty</MenuItem>
              <MenuItem value={"a30"}>Thirty</MenuItem>
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Upload Number (csv file)
              </Typography>
              {pdfAlertMessage && (
                <small style={{ color: "#CB0101" }}>{pdfAlertMessage}</small>
              )}
            </Stack>

            <OutlinedInput
              type="file"
              name="attachment"
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "11pt",
              }}
              onChange={handleAttachment}
              endAdornment={
                <InputAdornment position="end">
                  <CloudUpload sx={{ color: "#760505", fontSize: "16pt" }} />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Set Time
              </Typography>
              {inputField.fieldErrorAlert === "set_time" && (
                <small style={{ color: "#CB0101" }}>*Enter valid link</small>
              )}
            </Stack>

            <OutlinedInput
              type="time"
              size="small"
              name="set_time"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "11pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Campaign Name
              </Typography>
              {inputField.fieldErrorAlert === "referenceUrl" && (
                <small style={{ color: "#CB0101" }}>*Enter valid link</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              size="small"
              name="campaign_name"
              placeholder="Name"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "11pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.template &&
            inputField.campaign_name &&
            attachment &&
            inputField.set_time ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Start
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Start
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StartCampaignForm;
