import {
  Anchor,
  Check,
  Diamond,
  Error,
  HighlightOff,
  Person,
  TextSnippet,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ChangePasswordForm = ({ setAddNewBtn }) => {
  const [regNumberInfo, setRegNumberInfo] = useState({});
  console.log("regNumberInfo:", regNumberInfo);
  const [inputField, setInputField] = useState({});
  const [oldPass, setOldPass] = useState("");
  console.log("oldPass:", oldPass);
  const [changePass, setChangePass] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState(false);
  console.log("confirmPassword:", confirmPassword);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/get_user_pass.php?user_id=${parsedObject?.SopnoID}&accn_id=${accn_id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setOldPass(result[0]);
      });
  }, []);

  const handleSend = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    const formData = new FormData();
    formData.append("password", regNumberInfo.password);
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", accn_id);

    fetch(`https://whatsapp.ghorami.com/api/setting_ap/new_pass_setup.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result[0].account_pass) {
          setOldPass(result[0].account_pass);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const clear = () => {
    setRegNumberInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "password") {
      isFieldValid =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/.test(
          event.target.value
        );
    }
    if (event.target.name === "cPassword") {
      isFieldValid = true;
      if (event.target.value == inputField.password) {
        setConfirmPassword(true);
      } else {
        setConfirmPassword(false);
      }
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newregNumberInfo = regNumberInfo;
      newregNumberInfo[event.target.name] = event.target.value;
      setRegNumberInfo({ ...newregNumberInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"flex-end"}
            spacing={2}
          >
            <Stack spacing={0.5} sx={{ width: "100%" }}>
              <Stack direction={"row"} justifyContent="space-between">
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 600,
                  }}
                >
                  Current Password
                </Typography>
              </Stack>
              <OutlinedInput
                type="text"
                name="pass"
                size="small"
                value={oldPass?.pass ? oldPass?.pass : ""}
                placeholder=""
                sx={{
                  width: "100%",

                  borderRadius: "6px",
                  fontSize: "9pt",
                }}
              />
            </Stack>
            <Button
              size="small"
              variant="outlined"
              color="info"
              sx={{
                textTransform: "capitalize",
                fontSize: "11pt",
                width: "50%",
              }}
              onClick={() => {
                setChangePass(true);
              }}
            >
              Change Password
            </Button>
          </Stack>
        </Grid>
        {changePass && (
          <>
            <Grid item xs={6}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 600,
                    }}
                  >
                    New Password
                  </Typography>
                </Stack>

                <OutlinedInput
                  type="password"
                  name="password"
                  size="small"
                  placeholder="Enter password"
                  sx={{
                    width: "100%",

                    borderRadius: "6px",

                    fontSize: "9pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 600,
                    }}
                  >
                    Confirm Password
                  </Typography>
                  {confirmPassword && (
                    <Check sx={{ fontSize: "14pt", color: "darkgreen" }} />
                  )}
                  {!confirmPassword && (
                    <Error sx={{ fontSize: "14pt", color: "#a31212" }} />
                  )}
                </Stack>

                <OutlinedInput
                  type="password"
                  name="cPassword"
                  size="small"
                  placeholder="Enter confirm password"
                  sx={{
                    width: "100%",

                    borderRadius: "6px",
                    fontSize: "9pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            {inputField.fieldErrorAlert === "password" && (
              <small style={{ color: "#CB0101" }}>
                *password must have at least one digit and one special character
                and minimum contains 6 character like (xyz@#00)
              </small>
            )}

            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                alignItems={"center"}
                justifyContent="right"
              >
                <Button
                  variant="outlined"
                  color="info"
                  sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                  onClick={() => {
                    clear();
                    // setAddNewBtn(false);
                  }}
                >
                  Clear
                </Button>

                {inputField.password && confirmPassword ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                    onClick={() => {
                      handleSend();
                      //   setAddNewBtn(false);
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                    onClick={() => {}}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ChangePasswordForm;
