import {
  Anchor,
  Diamond,
  HighlightOff,
  Person,
  TextSnippet,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";

const NewGatewayForm = ({ setAddNewBtn }) => {
  const ref = useRef(null);
  const [regNumberInfo, setRegNumberInfo] = useState({});
  console.log("regNumberInfo:", regNumberInfo);
  const [inputField, setInputField] = useState({});
  console.log("inputField:", inputField);
  const [pdfAlertMessage, setPdfAlertMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const [versionList, setVersionList] = useState([]);
  console.log("versionList:", versionList);

  useEffect(() => {
    fetch(
      `https://whatsapp.ghorami.com/api/dev_api/get_all_wp_version.php?ref=${inputField?.product}`
    )
      .then((res) => res.json())
      .then((result) => {
        setVersionList(result);
      });
  }, [inputField?.product]);

  const handleSend = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    regNumberInfo.p_user = parsedObject?.SopnoID;

    const formData = new FormData();
    formData.append("newGateway", JSON.stringify(regNumberInfo));

    fetch(`https://whatsapp.ghorami.com/api/dev_api/new_gateway.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result[0]?.account_id) {
          swal({
            title: result[0]?.resultState,
            text: result[0]?.resultMessage,
            icon: result[0]?.result,
            button: "Done",
          });
          setInputField({});
          setRegNumberInfo({});
          // ref.current.value = "";
          console.log("ref.current.value:", ref.current);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const clear = () => {
    setRegNumberInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "product") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "app_id") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }
    if (event.target.name === "version") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (event.target.name === "phone_id") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }

    if (event.target.name === "app_token") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newregNumberInfo = regNumberInfo;
      newregNumberInfo[event.target.name] = event.target.value;
      setRegNumberInfo({ ...newregNumberInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Product
              </Typography>
              {inputField.fieldErrorAlert === "product" && (
                <small style={{ color: "#CB0101" }}>*Select product</small>
              )}
            </Stack>

            <Select
              displayEmpty
              onChange={handleChange}
              size="small"
              name="product"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"whatsapp"}>whatsapp</MenuItem>
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Version
              </Typography>
              {inputField.fieldErrorAlert === "version" && (
                <small style={{ color: "#CB0101" }}>*Enter Valid city</small>
              )}
            </Stack>

            <Select
              displayEmpty
              onChange={handleChange}
              size="small"
              name="version"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {versionList?.map((element) => (
                <MenuItem value={element?.ver_code}>
                  {element?.version_name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Phone Id
              </Typography>
              {inputField.fieldErrorAlert === "phone_id" && (
                <small style={{ color: "#CB0101" }}>*Enter valid app Id</small>
              )}
            </Stack>

            <OutlinedInput
              type="number"
              name="phone_id"
              size="small"
              placeholder="Write phone id"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                App Id
              </Typography>
              {inputField.fieldErrorAlert === "app_id" && (
                <small style={{ color: "#CB0101" }}>*Enter valid app Id</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="app_id"
              size="small"
              placeholder="Write answer"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                App token
              </Typography>
              {inputField.fieldErrorAlert === "app_token" && (
                <small style={{ color: "#CB0101" }}>*Enter valid token</small>
              )}
            </Stack>

            <OutlinedInput
              ref={ref}
              type="text"
              size="small"
              name="app_token"
              placeholder="Token"
              inputComponent="input"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              // value={videoapp_token}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.product &&
            inputField.app_id &&
            inputField.app_token &&
            inputField.version ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleSend();
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {}}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewGatewayForm;
