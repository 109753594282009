import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import {
  AttachFileOutlined,
  CallOutlined,
  Check,
  EmojiEmotionsOutlined,
  HighlightOff,
  KeyboardArrowUp,
  Language,
  MicNoneOutlined,
  MoreHoriz,
  PhotoCamera,
  SendOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import NewBotForm from "./NewBotForm";
import RegNumberForm from "../Settings/MainSecSettings/Bussiness/RegNumberForm";
import NewContactForm from "./New Contact/NewContactForm";
import TextBox from "./MessageTextBox/TextBox";
import TextBoxBot from "./BotTextBox/TextBoxBot";
import TemplateListBox from "./BotTextBox/TemplateListBox";
import CampaingTable from "../Campaign Components/CampaignTable";
import CampaignDetails from "../Campaign Components/CampaignDetails";
import NewCampaignForm from "../Campaign Components/NewCampaignForm";
import { Theme, useTheme } from "@mui/material/styles";
import "./Conversation.css";
import ListButton from "./List Button/ListButton";
import InteractiveReplayActionForm from "./List Button/Template Type forms/InteractiveReplayActionForm";
import LocationForm from "./List Button/Template Type forms/LocationForm";
import TextTypeForm from "./List Button/Template Type forms/TextTypeForm";
import MediaTypeForm from "./List Button/Template Type forms/MediaTypeForm";
import ContactForm from "./List Button/Template Type forms/ContactForm";
import TextEditor from "./Conversation TextEditor/TextEditor";
// import useSound from "use-sound";
// import fanfareSfx from "../../../../Audio/small_beep_sms.mp3";
const Conversation = ({
  setSelectedTemplate,
  selectedTemplate,
  referState,
  setListReplyBtn,
  listReplyBtn,
  handleBotData,
  toggle,
  handleToggle,
  messageBtn,
  autoBotBtn,
  campaignBtn,
  addNewBtn,
  regNumBtn,
  paymentBtn,
  regNumAddNewBtn,
  paymentAddNewBtn,
  selectedCustomer,
  dropDownBtn,
  setDropDownBtn,
  selectedBtn,
  setSelectedBtn,
  setNewContactBtn,
  newContactBtn,
  setAddNewBtn,
  postInfo,
  setPostInfo,
  campaignDetailsBtn,

  handleMessageBtn,
  setCreateCampaignBtn,
  handleAddNewButtons,
  setRegNumberInfo,
  regNumberInfo,
  createCampaignBtn,
  customerResponse,

  templateList,
  campaignDetailsList,
  campaignDetails,
  handleBotResponse,
  handleTemplateList,
  templateMessageBotInfo,
  setTemplateMessageBotInfo,
  handleTemplateMessageSave,
  startCampaignInfo,
  setStartCampaignInfo,
  handleCustomerData,
  inputField,
  setInputField,
  template,
  setTemplate,
  conversation,
  setConversation,
  render,
  setRender,
  accountNumber,
  setAccountNumber,
}) => {
  console.log("postInfo:", postInfo);
  const theme = useTheme();
  const [selectedReplyBtnInfo, setSelectedReplyBtnInfo] = useState({});
  console.log("selectedReplyBtnInfo:", selectedReplyBtnInfo);
  console.log("setRender:", render);

  const [systemMessageInfo, setSystemMessageInfo] = useState({});

  const [templateMessageCampaignInfo, setTemplateMessageCampaignInfo] =
    useState({});

  const [gateWay, setAllGateWay] = useState([]);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", 1234);
    formData.append("token", Math.round(Math.random() * 10000000000));

    fetch(
      `https://whatsapp.ghorami.com/api/dev_api/get_all_gateway.php?user_id=${parsedObject?.poster}&accn_id=${parsedObject?.accn_id}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        setAllGateWay(result);
        setAccountNumber(result[0]?.temp_refer);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);
  const handleChange = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleSystemMessageBotSave = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let updateInfo = systemMessageInfo;
    updateInfo.user_id = parsedObject?.SopnoID;
    updateInfo.refer = referState;
    console.log("updateInfo:", updateInfo);
    const formData = new FormData();
    formData.append("new_sysmessage", JSON.stringify(updateInfo));

    fetch("https://whatsapp.ghorami.com/api/chatbot_api/wm_autobot_new.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("systemMessagePostBot:", result);
      })
      .catch((error) => {
        console.log("messagePost=> ", error);
      });
  };

  return (
    <Box sx={{ boxSizing: "border-box" }}>
      {!campaignBtn && (
        <Paper
          elevation={0}
          sx={{
            border: "1px solid gray",
            borderTop: 0,
            borderRadius: 0,
            paddingX: "20px",
            paddingY: "7px",
            boxSizing: "border-box",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              spacing={1}
              direction={"row"}
              alignItems="center"
              justifyContent="left"
            >
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{}}
                onClick={handleToggle}
              />
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 700 }}
              >
                {selectedCustomer?.Name}
              </Typography>
            </Stack>
            <Input
              id="standard-adornment-weight"
              //   value={values.weight}
              //   onChange={handleChange("weight")}
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              sx={{ width: "40%" }}
            />
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="right"
              alignItems="center"
            >
              {selectedCustomer?.states == 1 ? (
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems="center"
                  sx={{
                    paddingX: "12px",
                    paddingY: "2px",
                    bgcolor: "darkgreen",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "8pt", color: "white" }}
                  >
                    LIVE
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems="center"
                  sx={{
                    paddingX: "12px",
                    paddingY: "2px",
                    bgcolor: "#c50808",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "8pt", color: "white" }}
                  >
                    LIVE
                  </Typography>
                </Stack>
              )}

              <IconButton aria-label="delete" size="small">
                <MoreHoriz fontSize="small" />
              </IconButton>
              <IconButton aria-label="delete" size="small">
                <Check fontSize="small" />
              </IconButton>
              {!messageBtn && (
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems="center"
                  sx={{
                    paddingX: "12px",
                    paddingY: "2px",
                    bgcolor: "darkgreen",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleAddNewButtons();
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "8pt", color: "white" }}
                  >
                    ADD NEW
                  </Typography>
                </Stack>
              )}
              <Select
                size="small"
                value={accountNumber}
                onChange={handleChange}
                displayEmpty
                sx={{
                  fontSize: "10px",
                  width: "150px",
                  padding: "0px 0px !important",
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {gateWay?.map((element) => (
                  <MenuItem
                    value={element?.temp_refer}
                    sx={{ fontSize: "10px" }}
                  >
                    <Stack
                      direction={"column"}
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {" "}
                        {element?.busin_name}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "7pt", color: "gray" }}
                      >
                        {element?.wap_business_id}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Paper>
      )}
      {campaignBtn && (
        <Paper
          elevation={0}
          sx={{
            border: "1px solid gray",
            borderTop: 0,
            borderRadius: 0,
            paddingX: "20px",
            paddingY: "7px",
            boxSizing: "border-box",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              spacing={1}
              direction={"row"}
              alignItems="center"
              justifyContent="left"
            >
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{}}
                onClick={handleToggle}
              />
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 700 }}
              >
                {selectedCustomer?.Name}
              </Typography>
            </Stack>
            <Input
              id="standard-adornment-weight"
              //   value={values.weight}
              //   onChange={handleChange("weight")}
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              sx={{ width: "40%" }}
            />
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="right"
              alignItems="center"
            >
              {selectedCustomer?.states == 1 ? (
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems="center"
                  sx={{
                    paddingX: "12px",
                    paddingY: "2px",
                    bgcolor: "darkgreen",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "8pt", color: "white" }}
                  >
                    LIVE
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems="center"
                  sx={{
                    paddingX: "12px",
                    paddingY: "2px",
                    bgcolor: "#c50808",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "8pt", color: "white" }}
                  >
                    LIVE
                  </Typography>
                </Stack>
              )}

              <IconButton aria-label="delete" size="small">
                <MoreHoriz fontSize="small" />
              </IconButton>
              <IconButton aria-label="delete" size="small">
                <Check fontSize="small" />
              </IconButton>
              <Select
                size="small"
                value={accountNumber}
                onChange={handleChange}
                displayEmpty
                sx={{
                  fontSize: "10px",
                  width: "150px",
                  padding: "0px 0px !important",
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {gateWay?.map((element) => (
                  <MenuItem
                    value={element?.temp_refer}
                    sx={{ fontSize: "10px" }}
                  >
                    <Stack
                      direction={"column"}
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {" "}
                        {element?.busin_name}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "7pt", color: "gray" }}
                      >
                        {element?.wap_business_id}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
              {!messageBtn && !campaignBtn && (
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems="center"
                  sx={{
                    paddingX: "12px",
                    paddingY: "2px",
                    bgcolor: "darkgreen",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCreateCampaignBtn(true);
                    setStartCampaignInfo({});
                    setInputField({});
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "8pt", color: "white" }}
                  >
                    ADD NEW
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Paper>
      )}
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          height: "90.8vh",
          paddingX: "20px",
          paddingY: "20px",
          borderRadius: 0,
          border: "1px solid gray",
          borderTop: 0,
          borderBottom: 0,
          bgcolor: "#eef0f1",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        {/* {(messageBtn || autoBotBtn || campaignBtn) &&
          !addNewBtn &&
          !regNumBtn &&
          !paymentBtn &&
          !regNumAddNewBtn &&
          !paymentAddNewBtn &&
          !newContactBtn && <TextBox customerResponse={customerResponse} />} */}
        {messageBtn &&
          !addNewBtn &&
          !regNumBtn &&
          !paymentBtn &&
          !regNumAddNewBtn &&
          !paymentAddNewBtn &&
          !newContactBtn && (
            <Box sx={{ maxHeight: "65vh", overflow: "auto" }}>
              <TextBox
                customerResponse={customerResponse}
                setAddNewBtn={setAddNewBtn}
                template={template}
                conversation={conversation}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
                botResponse={templateList}
                referState={referState}
                setTemplate={setTemplate}
                setConversation={setConversation}
                listReplyBtn={listReplyBtn}
                setListReplyBtn={setListReplyBtn}
                selectedReplyBtnInfo={selectedReplyBtnInfo}
                setSelectedReplyBtnInfo={setSelectedReplyBtnInfo}
                selectedCustomer={selectedCustomer}
                dropDownBtn={dropDownBtn}
                setDropDownBtn={setDropDownBtn}
                selectedBtn={selectedBtn}
                setSelectedBtn={setSelectedBtn}
              />
            </Box>
          )}

        {autoBotBtn &&
          !addNewBtn &&
          !regNumBtn &&
          !paymentBtn &&
          !regNumAddNewBtn &&
          !paymentAddNewBtn &&
          !newContactBtn && (
            <Box sx={{ maxHeight: "75vh", overflow: "auto" }}>
              {conversation && (
                <TextBoxBot handleBotResponse={handleBotResponse} />
              )}
              {template && (
                <TemplateListBox
                  setAddNewBtn={setAddNewBtn}
                  template={template}
                  conversation={conversation}
                  setSelectedTemplate={setSelectedTemplate}
                  selectedTemplate={selectedTemplate}
                  botResponse={templateList}
                  referState={referState}
                  setTemplate={setTemplate}
                  setConversation={setConversation}
                  listReplyBtn={listReplyBtn}
                  setListReplyBtn={setListReplyBtn}
                  selectedReplyBtnInfo={selectedReplyBtnInfo}
                  setSelectedReplyBtnInfo={setSelectedReplyBtnInfo}
                  selectedCustomer={selectedCustomer}
                  dropDownBtn={dropDownBtn}
                  setDropDownBtn={setDropDownBtn}
                  selectedBtn={selectedBtn}
                  setSelectedBtn={setSelectedBtn}
                  handleBotResponse={handleTemplateList}
                />
              )}
              {listReplyBtn && (
                <ListButton
                  accountNumber={accountNumber}
                  setAddNewBtn={setAddNewBtn}
                  botResponse={templateList}
                  referState={referState}
                  setTemplate={setTemplate}
                  setConversation={setConversation}
                  listReplyBtn={listReplyBtn}
                  setListReplyBtn={setListReplyBtn}
                  selectedReplyBtnInfo={selectedReplyBtnInfo}
                  setSelectedReplyBtnInfo={setSelectedReplyBtnInfo}
                  selectedCustomer={selectedCustomer}
                  dropDownBtn={dropDownBtn}
                  setDropDownBtn={setDropDownBtn}
                  selectedBtn={selectedBtn}
                  setSelectedBtn={setSelectedBtn}
                  selectedTemplate={selectedTemplate}
                  handleBotResponse={handleTemplateList}
                  render={render}
                  setRender={setRender}
                  setSelectedTemplate={setSelectedTemplate}
                />
              )}
              {(selectedTemplate === "interactive" ||
                selectedTemplate === "replay_action") && (
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      height: "85vh",
                      width: "50%",
                      overflow: "auto",
                      borderRadius: "12px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "white",
                        padding: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <InteractiveReplayActionForm
                        handleTemplateMessageSave={handleTemplateMessageSave}
                        setAddNewBtn={setAddNewBtn}
                        referState={referState}
                        accountNumber={accountNumber}
                        selectedReplyBtnInfo={selectedReplyBtnInfo}
                        selectedTemplate={selectedTemplate}
                        selectedCustomer={selectedCustomer}
                        dropDownBtn={dropDownBtn}
                        setDropDownBtn={setDropDownBtn}
                        selectedBtn={selectedBtn}
                        setSelectedBtn={setSelectedBtn}
                        postInfo={postInfo}
                        setPostInfo={setPostInfo}
                        setTemplate={setTemplate}
                        setListReplyBtn={setListReplyBtn}
                        setConversation={setConversation}
                        setSelectedTemplate={setSelectedTemplate}
                        handleBotResponse={handleTemplateList}
                      />
                    </Box>
                  </Box>
                </Stack>
              )}
              {selectedTemplate === "location" && (
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      height: "85vh",
                      width: "50%",
                      overflow: "auto",
                      borderRadius: "12px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "white",
                        padding: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <LocationForm
                        handleTemplateMessageSave={handleTemplateMessageSave}
                        setAddNewBtn={setAddNewBtn}
                        referState={referState}
                        accountNumber={accountNumber}
                        selectedReplyBtnInfo={selectedReplyBtnInfo}
                        selectedTemplate={selectedTemplate}
                        selectedCustomer={selectedCustomer}
                        dropDownBtn={dropDownBtn}
                        setDropDownBtn={setDropDownBtn}
                        selectedBtn={selectedBtn}
                        setSelectedBtn={setSelectedBtn}
                        postInfo={postInfo}
                        setPostInfo={setPostInfo}
                        setTemplate={setTemplate}
                        setListReplyBtn={setListReplyBtn}
                        setConversation={setConversation}
                        setSelectedTemplate={setSelectedTemplate}
                        handleBotResponse={handleTemplateList}
                      />
                    </Box>
                  </Box>
                </Stack>
              )}
              {selectedTemplate === "text_type" && (
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      height: "85vh",
                      width: "50%",
                      overflow: "auto",
                      borderRadius: "12px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "white",
                        padding: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <TextTypeForm
                        handleTemplateMessageSave={handleTemplateMessageSave}
                        setAddNewBtn={setAddNewBtn}
                        referState={referState}
                        accountNumber={accountNumber}
                        selectedReplyBtnInfo={selectedReplyBtnInfo}
                        selectedTemplate={selectedTemplate}
                        selectedCustomer={selectedCustomer}
                        dropDownBtn={dropDownBtn}
                        setDropDownBtn={setDropDownBtn}
                        selectedBtn={selectedBtn}
                        setSelectedBtn={setSelectedBtn}
                        postInfo={postInfo}
                        setPostInfo={setPostInfo}
                        setTemplate={setTemplate}
                        setListReplyBtn={setListReplyBtn}
                        setConversation={setConversation}
                        setSelectedTemplate={setSelectedTemplate}
                        handleBotResponse={handleTemplateList}
                      />
                    </Box>
                  </Box>
                </Stack>
              )}
              {selectedTemplate === "media_type" && (
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      height: "85vh",
                      width: "50%",
                      overflow: "auto",
                      borderRadius: "12px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "white",
                        padding: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <MediaTypeForm
                        handleTemplateMessageSave={handleTemplateMessageSave}
                        setAddNewBtn={setAddNewBtn}
                        referState={referState}
                        accountNumber={accountNumber}
                        selectedReplyBtnInfo={selectedReplyBtnInfo}
                        selectedTemplate={selectedTemplate}
                        selectedCustomer={selectedCustomer}
                        dropDownBtn={dropDownBtn}
                        setDropDownBtn={setDropDownBtn}
                        selectedBtn={selectedBtn}
                        setSelectedBtn={setSelectedBtn}
                        postInfo={postInfo}
                        setPostInfo={setPostInfo}
                        setTemplate={setTemplate}
                        setListReplyBtn={setListReplyBtn}
                        setConversation={setConversation}
                        setSelectedTemplate={setSelectedTemplate}
                        handleBotResponse={handleTemplateList}
                      />
                    </Box>
                  </Box>
                </Stack>
              )}
              {selectedTemplate === "contact" && (
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      height: "85vh",
                      width: "50%",
                      overflow: "auto",
                      borderRadius: "12px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "white",
                        padding: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <ContactForm
                        handleTemplateMessageSave={handleTemplateMessageSave}
                        setAddNewBtn={setAddNewBtn}
                        referState={referState}
                        accountNumber={accountNumber}
                        selectedReplyBtnInfo={selectedReplyBtnInfo}
                        selectedTemplate={selectedTemplate}
                        selectedCustomer={selectedCustomer}
                        dropDownBtn={dropDownBtn}
                        setDropDownBtn={setDropDownBtn}
                        selectedBtn={selectedBtn}
                        setSelectedBtn={setSelectedBtn}
                        postInfo={postInfo}
                        setPostInfo={setPostInfo}
                        setTemplate={setTemplate}
                        setListReplyBtn={setListReplyBtn}
                        setConversation={setConversation}
                        setSelectedTemplate={setSelectedTemplate}
                        handleBotResponse={handleTemplateList}
                      />
                    </Box>
                  </Box>
                </Stack>
              )}
            </Box>
          )}
        {campaignBtn &&
          !createCampaignBtn &&
          !campaignDetailsBtn &&
          !addNewBtn &&
          !regNumBtn &&
          !paymentBtn &&
          !regNumAddNewBtn &&
          !paymentAddNewBtn &&
          !newContactBtn && (
            <>
              <CampaingTable campaignDetailsList={campaignDetailsList} />
            </>
          )}
        {campaignBtn &&
          campaignDetailsBtn &&
          !addNewBtn &&
          !regNumBtn &&
          !paymentBtn &&
          !regNumAddNewBtn &&
          !paymentAddNewBtn &&
          !newContactBtn && (
            <>
              <CampaignDetails campaignDetails={campaignDetails} />
            </>
          )}
        {createCampaignBtn &&
          !campaignDetailsBtn &&
          !addNewBtn &&
          !regNumBtn &&
          !paymentBtn &&
          !regNumAddNewBtn &&
          !paymentAddNewBtn &&
          !newContactBtn && (
            <>
              <NewCampaignForm
                createCampaignBtn={createCampaignBtn}
                setCreateCampaignBtn={setCreateCampaignBtn}
                startCampaignInfo={startCampaignInfo}
                setStartCampaignInfo={setStartCampaignInfo}
                inputField={inputField}
                setInputField={setInputField}
                setAddNewBtn={setAddNewBtn}
                accountNumber={accountNumber}
                toggle={toggle}
                selectedCustomer={selectedCustomer}
              />
            </>
          )}

        {addNewBtn && autoBotBtn && (
          // {((addNewBtn && messageBtn) ||
          //   (addNewBtn && autoBotBtn) ||
          //   // (addNewBtn && leftBarBtn == "contact") ||
          //   (addNewBtn && campaignBtn)) && (
          <Stack direction={"row"} justifyContent="center" alignItems="center">
            <Box sx={{ width: "70%", height: "85vh", overflow: "auto" }}>
              <NewBotForm
                handleBotData={handleBotData}
                systemMessageInfo={systemMessageInfo}
                setSystemMessageInfo={setSystemMessageInfo}
                setTemplateMessageBotInfo={setTemplateMessageBotInfo}
                templateMessageBotInfo={templateMessageBotInfo}
                templateMessageCampaignInfo={templateMessageCampaignInfo}
                setTemplateMessageCampaignInfo={setTemplateMessageCampaignInfo}
                startCampaignInfo={startCampaignInfo}
                setStartCampaignInfo={setStartCampaignInfo}
                handleSystemMessageBotSave={handleSystemMessageBotSave}
                handleTemplateMessageSave={handleTemplateMessageSave}
                setAddNewBtn={setAddNewBtn}
                campaignBtn={campaignBtn}
                autoBotBtn={autoBotBtn}
                referState={referState}
                selectedCustomer={selectedCustomer}
                accountNumber={accountNumber}
              />
            </Box>
          </Stack>
        )}

        {regNumBtn && <Box>reg</Box>}
        {regNumAddNewBtn && (
          <Stack direction={"row"} justifyContent="center" alignItems="center">
            <Paper
              sx={{
                width: "70%",
                height: "auto",
                overflowY: "scroll",
                paddingX: "25px",
                paddingTop: "20px",
                boxSizing: "border-box",
              }}
            >
              <RegNumberForm
                setRegNumberInfo={setRegNumberInfo}
                regNumberInfo={regNumberInfo}
                setAddNewBtn={setAddNewBtn}
              />
            </Paper>
          </Stack>
        )}
        {paymentBtn && <Box>pay</Box>}
        {paymentAddNewBtn && <Box>payment</Box>}

        {newContactBtn && (
          <Stack direction={"row"} justifyContent="center" alignItems="center">
            <Paper
              sx={{
                width: "70%",
                paddingX: "25px",
                paddingTop: "20px",
                boxSizing: "border-box",
              }}
            >
              <NewContactForm
                setRegNumberInfo={setRegNumberInfo}
                regNumberInfo={regNumberInfo}
                setAddNewBtn={setAddNewBtn}
                handleCustomerData={handleCustomerData}
                setNewContactBtn={setNewContactBtn}
                handleMessageBtn={handleMessageBtn}
              />
            </Paper>
          </Stack>
        )}

        {!addNewBtn &&
          !campaignBtn &&
          !regNumBtn &&
          !paymentBtn &&
          !regNumAddNewBtn &&
          !paymentAddNewBtn &&
          !newContactBtn && (
            <Box
              sx={{
                width: "99%",
                paddingX: "20px",
                position: "absolute",
                bottom: "25px",
                left: ".5%",
                boxSizing: "border-box",
              }}
            >
              <TextEditor
                setSelectedTemplate={setSelectedTemplate}
                setListReplyBtn={setListReplyBtn}
                autoBotBtn={autoBotBtn}
                selectedCustomer={selectedCustomer}
                setDropDownBtn={setDropDownBtn}
                setSelectedBtn={setSelectedBtn}
                postInfo={postInfo}
                setPostInfo={setPostInfo}
                setRegNumberInfo={setRegNumberInfo}
                regNumberInfo={regNumberInfo}
                handleTemplateList={handleTemplateList}
                template={template}
                setTemplate={setTemplate}
                conversation={conversation}
                setConversation={setConversation}
                accountNumber={accountNumber}
              />
            </Box>
          )}
      </Paper>
    </Box>
  );
};

export default Conversation;
