// import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../App";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  let newObject = localStorage.getItem("wmData");
  let parsedObject = JSON.parse(newObject);

  console.log("newObject:", parsedObject);
  console.log("newObject?.SopnoID:", parsedObject?.SopnoID);
  console.log("newObject?.MobileNumber :", parsedObject?.MobileNumber);
  //   const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  return parsedObject?.SopnoID && parsedObject?.MobileNumber ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
export default PrivateRoute;
