import * as React from "react";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Switch, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const columns = [
  { label: "Off/On" },
  { label: "Campaign" },
  { label: "Delivery" },
  { label: "Bid Strategy" },
  { label: "Budget" },
  { label: "Attribution setting" },
  { label: "Results" },
  { label: "Reach" },
  { label: "Impressions" },
  { label: "Amount spent" },
  { label: "Ends" },
];

export default function CampaignTable({ campaignDetailsList }) {
  console.log("campaignDetailsList:", campaignDetailsList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [checked, setChecked] = useState({});

  const handleChange = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 520 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography
                    sx={{ fontSize: "9pt", fontWeight: 700 }}
                    variant="p"
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {campaignDetailsList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                    <TableCell align="left" size="small">
                      <Stack
                        direction={"row"}
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        {/* <Button sx={{}}></Button> */}
                        <Switch
                          checked={checked.name}
                          name={item.refer}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.Name ? item.Name : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.delivery ? item.delivery : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.strategy ? item.strategy : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.budget ? item.budget : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.attribute ? item.attribute : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.result ? item.result : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.reach ? item.reach : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.impression ? item.impression : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.amount_spend ? item.amount_spend : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.end_date ? item.end_date : "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={campaignDetailsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
