import './App.css';
import { Box } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import Pages from './Pages/Pages';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <Box className="App">
          <Pages />
        </Box>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
