import {
  Anchor,
  Diamond,
  HighlightOff,
  Person,
  TextSnippet,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const DefaultNumberForm = ({ setAddNewBtn }) => {
  const [regNumberInfo, setRegNumberInfo] = useState({});
  console.log("regNumberInfo:", regNumberInfo);
  const [inputField, setInputField] = useState({});
  const [allPhone, setAllPhone] = useState([]);
  const [defaultNumber, setDefaultNumber] = useState([]);
  console.log("defaultNumber:", defaultNumber);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/get_active_number_all.php?user_id=${parsedObject?.SopnoID}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllPhone(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/get_default_number.php?user_id=${parsedObject?.SopnoID}&accn_id=${accn_id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setDefaultNumber(result[0]);
      });
  }, []);

  const handleSend = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    const formData = new FormData();
    formData.append("phone_id", regNumberInfo.phone_id);
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", accn_id);

    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/new_default_number.php`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result[0].account_id) {
          console.log("account_sasasid:", result.account_id);
          fetch(
            `https://whatsapp.ghorami.com/api/setting_ap/get_default_number.php?user_id=${parsedObject?.SopnoID}&accn_id=${accn_id}`
          )
            .then((res) => res.json())
            .then((result) => {
              setDefaultNumber(result[0]);
            });
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const clear = () => {
    setRegNumberInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "phone_id") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newregNumberInfo = regNumberInfo;
      newregNumberInfo[event.target.name] = event.target.value;
      setRegNumberInfo({ ...newregNumberInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"flex-end"}
            spacing={2}
          >
            <Stack spacing={0.5} sx={{ width: "100%" }}>
              <Stack direction={"row"} justifyContent="space-between">
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 600,
                  }}
                >
                  Select Phone ID
                </Typography>
                {inputField.fieldErrorAlert === "phone_id" && (
                  <small style={{ color: "#CB0101" }}>*Select phone_id</small>
                )}
              </Stack>

              <Select
                displayEmpty
                onChange={handleChange}
                size="small"
                name="phone_id"
                placeholder="Request"
                sx={{
                  width: "100%",

                  borderRadius: "6px",
                  fontSize: "9pt",
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {allPhone?.map((element, index) => (
                  <MenuItem key={index} value={element.phone}>
                    {element.phone}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {/* <Stack
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent="right"
            >
              <Button
                variant="outlined"
                color="info"
                sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                onClick={() => {
                  clear();
                  setAddNewBtn(false);
                }}
              >
                Clear
              </Button>

              {inputField.phone_id ? (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                  onClick={() => {
                    handleSend();
                    setAddNewBtn(false);
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled
                  variant="contained"
                  color="success"
                  sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                  onClick={() => {}}
                >
                  Save
                </Button>
              )}
            </Stack> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"flex-end"}
            spacing={2}
          >
            <Stack spacing={0.5} sx={{ width: "80%" }}>
              {defaultNumber?.default_number ? (
                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                >
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "10pt",
                        fontWeight: 600,
                      }}
                    >
                      Default Phone No
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "10pt",
                        fontWeight: 600,
                      }}
                    >
                      -
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "10pt",
                        fontWeight: 600,
                      }}
                    >
                      {defaultNumber?.default_number}
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 600,
                  }}
                >
                  Default Phone not found!
                </Typography>
              )}
            </Stack>
            {/* <Stack
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent="right"
            >
              <Button
                variant="outlined"
                color="info"
                sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                onClick={() => {
                  clear();
                  setAddNewBtn(false);
                }}
              >
                Clear
              </Button>

              {inputField.phone_id ? (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                  onClick={() => {
                    handleSend();
                    setAddNewBtn(false);
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled
                  variant="contained"
                  color="success"
                  sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                  onClick={() => {}}
                >
                  Save
                </Button>
              )}
            </Stack> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize", fontSize: "10pt" }}
              onClick={() => {
                clear();
                // setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.phone_id ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                onClick={() => {
                  handleSend();
                  //   setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize", fontSize: "10pt" }}
                onClick={() => {}}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DefaultNumberForm;
