import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const SceletonChatRight = () => {
  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent={"left"}
      alignItems="flex-end"
    >
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={60} />
      <Stack spacing={0.5}>
        <Skeleton variant="rounded" width={350} height={90} />
        <Stack
          spacing={0.5}
          direction="row"
          justifyContent={"left"}
          alignItems="flex-end"
        >
          <Skeleton variant="rounded" width={"100%"} height={30} />
          <Skeleton variant="rounded" width={"100%"} height={30} />
        </Stack>
      </Stack>{" "}
      <Skeleton variant="circular" width={35} height={35} />
    </Stack>
  );
};

export default SceletonChatRight;
