import { HighlightOff } from "@mui/icons-material";

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TemplateDetails = ({
  referState,
  selectedTempId,
  accountNumber,
  setTempUpdate,
  setAddNewBtn,
  setTempDetailsBtn,
}) => {
  console.log("selectedTempId:", selectedTempId);

  let newObject = localStorage.getItem("wmData");
  let parsedObject = JSON.parse(newObject);

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  console.log("personName:", personName);
  const [selectedTemp, setSelectedTemp] = useState([]);
  const [tempParam, setTempParam] = useState([]);
  console.log("tempParam:", tempParam);

  const [inputField, setInputField] = useState({});
  const [updateInfo, setUpdateInfo] = useState({});
  console.log("updateInfo:", updateInfo);
  const [buttonFields, setButtonFields] = useState([{ id: 0 }]);
  console.log("buttonFields:", buttonFields);
  const [nameAlert, setNameAlert] = useState(false);
  const [buttonAlert, setButtonAlert] = useState(false);

  const [tempDetailsList, setTempDetailsList] = useState([]);
  console.log("tempDetailsList:", tempDetailsList);
  useEffect(() => {
    fetch(
      `https://whatsapp.ghorami.com/api/wap_template/get_template_info.php?temple_id=${selectedTempId}&panel_idc=${accountNumber}&poster=${parsedObject?.poster}`
    )
      .then((res) => res.json())
      .then((result) => {
        setTempDetailsList(result);
      });
  }, [referState, selectedTempId, accountNumber]);
  useEffect(() => {
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_all_active_template_param.php?tem_name=${tempDetailsList?.name}&panel_idc=${accountNumber}&poster=${parsedObject?.poster}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("rerrrsult:", result);
        let newFieldarr = [];
        if (result?.[0]?.param_que) {
          result?.[0]?.param_que?.split(",")?.map((element, index) => {
            newFieldarr.push({
              id: index,
              variant_no: element,
              variant_value: "",
            });
          });
        } else if (result?.[0]?.param_que?.length == 1) {
          newFieldarr = { id: 0, variant_no: 1, variant_value: "" };
        } else {
          newFieldarr = [];
        }

        console.log("newFieldarr:", newFieldarr);
        setTempParam(newFieldarr);
      });
  }, [tempDetailsList?.name, accountNumber]);

  const handleBack = () => {
    setTempParam([]);
    setUpdateInfo({});
  };
  const updateVariant = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    updateInfo.user_id = parsedObject?.SopnoID;
    updateInfo.refer = parsedObject?.accn_id;

    const formData = new FormData();
    formData.append("panel_idc", accountNumber);
    formData.append("template_id", selectedTempId);
    formData.append("template_queue", JSON.stringify(updateInfo));

    fetch(
      "https://whatsapp.ghorami.com/api/chatbot_api/wm_autobot_template_dummy.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result[0]?.result === "success") {
        }
      })
      .catch((error) => {
        console.log("messagePost=> ", error);
      });
  };

  const handleDeleteTemp = () => {
    fetch(
      `https://whatsapp.ghorami.com/api/wap_template/delete_a_template.php?temple_name=${tempDetailsList?.name}&panel_idc=${accountNumber}&poster=${parsedObject?.poster}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAddNewBtn(false);
        setTempDetailsBtn(false);
      });
  };
  const handleFormChange = (index, event) => {
    // event.target.value = event.target.value.replace(" ", "_");

    let data = [...tempParam];

    data[index][event.target.name] = event.target.value;
    data[index].id = index;
    setTempParam(data);

    let newUpdateInfo = updateInfo;
    newUpdateInfo.temp_peram = tempParam;
    setUpdateInfo({ ...newUpdateInfo });
    setButtonAlert(false);
  };
  return (
    <Container>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Name
              </Typography>
            </Stack>

            <OutlinedInput
              type="text"
              name="Name"
              size="small"
              placeholder="Name"
              value={tempDetailsList?.name}
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              // onChange={handleNameChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Language
              </Typography>
            </Stack>

            <OutlinedInput
              type="text"
              name="Name"
              size="small"
              placeholder="Name"
              value={tempDetailsList?.language}
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              // onChange={handleNameChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Category
              </Typography>
            </Stack>

            <OutlinedInput
              type="text"
              name="Name"
              size="small"
              placeholder="Name"
              value={tempDetailsList?.category}
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {tempDetailsList?.components?.map((element) => (
            <>
              {element?.type === "HEADER" && element?.format === "TEXT" && (
                <Stack spacing={0.5}>
                  <Stack direction={"row"} justifyContent="space-between">
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      Header
                    </Typography>
                  </Stack>

                  <OutlinedInput
                    type="text"
                    name="header"
                    size="small"
                    placeholder="Header"
                    value={element?.text}
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "9pt",
                    }}
                  />
                </Stack>
              )}
              {element?.type === "HEADER" &&
                element?.format === "IMAGE" &&
                element?.example && (
                  <Stack spacing={0.5}>
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "9pt",
                          fontWeight: 500,
                        }}
                      >
                        Header
                      </Typography>
                      {inputField.fieldErrorAlert === "header" && (
                        <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                          *Header Field missing
                        </small>
                      )}
                    </Stack>

                    <Box
                      component={"img"}
                      sx={{ height: "auto", width: "55px" }}
                      src={element?.example?.header_handle?.[0]}
                    />
                  </Stack>
                )}
              {element?.type === "HEADER" &&
                element?.format !== "IMAGE" &&
                element?.format !== "TEXT" && (
                  <Stack spacing={0.5}>
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "9pt",
                          fontWeight: 500,
                        }}
                      >
                        Header - {element?.format}
                      </Typography>
                      {inputField.fieldErrorAlert === "header" && (
                        <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                          *Header Field missing
                        </small>
                      )}
                    </Stack>

                    <OutlinedInput
                      type="text"
                      name="header"
                      size="small"
                      placeholder="Header"
                      value={""}
                      sx={{
                        width: "100%",
                        borderRadius: "6px",
                        fontSize: "9pt",
                      }}
                    />
                  </Stack>
                )}
            </>
          ))}
        </Grid>{" "}
        <Grid item xs={12}>
          {tempDetailsList?.components?.map((element) => (
            <>
              {element?.type === "BODY" && (
                <Stack spacing={0.5}>
                  <Stack direction={"row"} justifyContent="space-between">
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      Info/Details
                    </Typography>
                    {inputField.fieldErrorAlert === "details" && (
                      <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                        *Title field missing
                      </small>
                    )}
                  </Stack>

                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={2}
                    placeholder="Enter about your Service"
                    value={element?.text}
                    name="details"
                    className="customTextField"
                    style={{
                      fontSize: "9pt",
                      fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                             Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                             sans-serif`,
                      width: "100%",
                      maxWidth: "100%",

                      boxSizing: "border-box",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "6px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      paddingTop: "10px",
                      paddingBottom: "5px",
                    }}
                    //onChange={handleChange}
                  />
                </Stack>
              )}
            </>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
            spacing={2}
          >
            <Stack spacing={0.5} sx={{ width: "40%" }}>
              {tempParam?.map((element, index) => (
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                    }}
                  >
                    #{element?.variant_no}
                  </Typography>
                  <OutlinedInput
                    type="text"
                    name="variant_value"
                    size="small"
                    placeholder="Enter variant value"
                    // value={element?.text}
                    onChange={(event) => handleFormChange(index, event)}
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "9pt",
                    }}
                  />{" "}
                </Stack>
              ))}
            </Stack>
            {tempParam?.length > 0 && (
              <Stack spacing={0.5} sx={{ width: "15%" }}>
                {updateInfo?.temp_peram?.[0]?.variant_value &&
                updateInfo?.temp_peram?.[1]?.variant_value &&
                updateInfo?.temp_peram?.[2]?.variant_value ? (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      updateVariant();
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    disabled
                    sx={{ textTransform: "capitalize" }}
                  >
                    Update
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {tempDetailsList?.components?.map((element) => (
            <>
              {element?.type === "FOOTER" && (
                <Stack spacing={0.5}>
                  <Stack direction={"row"} justifyContent="space-between">
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      Footer
                    </Typography>
                  </Stack>

                  <OutlinedInput
                    type="text"
                    name="Name"
                    size="small"
                    placeholder="Name"
                    value={element?.text}
                    sx={{
                      width: "100%",

                      borderRadius: "6px",

                      fontSize: "9pt",
                    }}
                  />
                </Stack>
              )}
            </>
          ))}
        </Grid>
        {
          <Grid item xs={12}>
            {tempDetailsList?.components?.map((element) => (
              <>
                {element?.type === "BUTTONS" && (
                  <Stack spacing={0.5} sx={{ width: "100%" }}>
                    <Stack direction={"row"} justifyContent="left">
                      <Typography
                        variant="p"
                        sx={{
                          width: "51%",
                          fontSize: "9pt",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        Button Type
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "9pt",
                          fontWeight: 500,
                        }}
                      >
                        Button Value
                      </Typography>
                    </Stack>

                    {element?.buttons?.map((btn) => (
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"left"}
                        // flexWrap="wrap"
                        spacing={1}
                        sx={{ width: "100%" }}
                      >
                        <OutlinedInput
                          type="text"
                          name="Name"
                          size="small"
                          placeholder="Name"
                          value={btn?.type}
                          sx={{
                            width: "50%",

                            borderRadius: "6px",

                            fontSize: "9pt",
                          }}
                        />
                        <OutlinedInput
                          type="text"
                          name="Name"
                          size="small"
                          placeholder="Name"
                          value={btn?.text}
                          sx={{
                            width: "50%",

                            borderRadius: "6px",

                            fontSize: "9pt",
                          }}
                        />
                      </Stack>
                    ))}
                  </Stack>
                )}
              </>
            ))}
          </Grid>
        }
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              size="small"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                // clear();
                setAddNewBtn(false);
                setTempDetailsBtn(false);
                handleBack();
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                // setAddNewBtn(false);
                // handleTemplateMessageSave();
                handleDeleteTemp();
              }}
            >
              Delete
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TemplateDetails;
