import { Box } from "@mui/material";
import React from "react";

export const PHP = `<?php


$params=array(
'token' => '{TOKEN}',
'to' => '',
'body' => 'WhatsApp API on UltraMsg.com works good'
);
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => "https://whatsapp.ghorami.com/api/chatbot_api/wm_auto_template_new.php",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_SSL_VERIFYHOST => 0,
  CURLOPT_SSL_VERIFYPEER => 0,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => http_build_query($params),
  CURLOPT_HTTPHEADER => array(
    "content-type: application/x-www-form-urlencoded"
  ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}`;

export const HTTP_REQUEST = `<?php

require_once 'HTTP/Request2.php';

$params=array(
'token' => '{TOKEN}',
'to' => '',
'body' => 'WhatsApp API on UltraMsg.com works good'
);

$request = new HTTP_Request2();

$request->setUrl('https://whatsapp.ghorami.com/api/chatbot_api/wm_auto_template_new.php');
$request->setMethod(HTTP_Request2::METHOD_POST);
$request->setConfig(array(
  'follow_redirects' => TRUE
));
$request->setHeader(array(
  'Content-Type' => 'application/x-www-form-urlencoded'
));
$request->addPostParameter($params);
try {
  $response = $request->send();
  if ($response->getStatus() == 200) {
    echo $response->getBody();
  }
  else {
    echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .
    $response->getReasonPhrase();
  }
}
catch(HTTP_Request2_Exception $e) {
  echo 'Error: ' . $e->getMessage();
}`;
const TemplateCode = () => {
  return <Box></Box>;
};

export default TemplateCode;
