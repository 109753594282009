import {
  Anchor,
  Check,
  Diamond,
  Edit,
  Email,
  Error,
  Facebook,
  Flag,
  GitHub,
  HighlightOff,
  LinkedIn,
  LocationOn,
  Person,
  Phone,
  TextSnippet,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const Profile = ({ setAddNewBtn }) => {
  const [regNumberInfo, setRegNumberInfo] = useState({});
  console.log("regNumberInfo:", regNumberInfo);
  const [inputField, setInputField] = useState({});
  const [userProfile, setUserProfile] = useState("");
  console.log("userProfile:", userProfile);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/get_user_details.php?user_id=67347077683666672209202211&accn_id=6d6c3669667a7839`
      // `https://whatsapp.ghorami.com/api/setting_ap/get_user_details.php?user_id==${parsedObject?.SopnoID}&accn_id=${accn_id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setUserProfile(result[0]);
      });
  }, []);

  const handleSend = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    const formData = new FormData();
    formData.append("password", regNumberInfo.password);
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", accn_id);

    fetch(`https://whatsapp.ghorami.com/api/setting_ap/new_pass_setup.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result[0].account_pass) {
          setUserProfile(result[0].account_pass);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const clear = () => {
    setRegNumberInfo({});
    setInputField({});
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={6}>
          <Stack
            direction={"row"}
            justifyContent="right"
            alignItems={"center"}
            spacing={2}
            sx={{ height: "40vh" }}
          >
            {userProfile?.pic ? (
              <Box component={"img"} src={""} />
            ) : (
              <Person sx={{ fontSize: "130pt" }} />
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            direction={"row"}
            justifyContent="left"
            alignItems={"center"}
            spacing={1}
            sx={{ height: "40vh" }}
          >
            <Stack
              alignItems={"flex-start"}
              sx={{ height: "20vh" }}
              spacing={0.5}
            >
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "13pt", fontWeight: 600 }}
                >
                  {userProfile?.name}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                spacing={0.5}
              >
                <Email sx={{ fontSize: "13pt", color: "#35a8cb" }} />
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {userProfile?.email}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                spacing={0.5}
              >
                <Phone sx={{ fontSize: "13pt", color: "#35a8cb" }} />
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {userProfile?.phone}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                spacing={0.5}
              >
                <LocationOn sx={{ fontSize: "13pt", color: "#35a8cb" }} />
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {userProfile?.business_address}, {userProfile?.city}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                spacing={0.5}
              >
                <Flag sx={{ fontSize: "13pt", color: "#35a8cb" }} />
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {userProfile?.country}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                spacing={1}
              >
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    position: "absolute",
                    top: "10px",
                    right: "0px",
                  }}
                  startIcon={
                    <Edit
                      sx={{
                        fontSize: "13pt",
                      }}
                    />
                  }
                >
                  Update
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
