import * as React from "react";
import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import StartCampaignForm from "./StartCampaignForm";
import TemplateMessageFormCampaign from "./TemplateMessageFormCampaign";
import TemplateMessageFormBot from "./TemplateMessageFormBot";
import SystemMessageFormBot from "./SystemMessageFormBot";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewBotForm = ({
  handleBotData,
  autoBotBtn,
  campaignBtn,
  systemMessageInfo,
  startCampaignInfo,
  setSystemMessageInfo,
  setStartCampaignInfo,
  templateMessageBotInfo,
  templateMessageCampaignInfo,
  setTemplateMessageCampaignInfo,
  setTemplateMessageBotInfo,
  handleSystemMessageBotSave,
  handleTemplateMessageSave,
  setAddNewBtn,
  referState,
  selectedCustomer,
  accountNumber,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={autoBotBtn ? "System message" : "Start Campaign"}
            {...a11yProps(0)}
            sx={{ width: "50%", textTransform: "capitalize" }}
          />
          <Tab
            label="Template message"
            {...a11yProps(1)}
            sx={{ width: "50%", textTransform: "capitalize" }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {autoBotBtn && (
          <SystemMessageFormBot
            systemMessageInfo={systemMessageInfo}
            setSystemMessageInfo={setSystemMessageInfo}
            setAddNewBtn={setAddNewBtn}
            handleSystemMessageBotSave={handleSystemMessageBotSave}
          />
        )}
        {campaignBtn && (
          <StartCampaignForm
            startCampaignInfo={startCampaignInfo}
            setStartCampaignInfo={setStartCampaignInfo}
            setAddNewBtn={setAddNewBtn}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {autoBotBtn && (
          <TemplateMessageFormBot
            templateMessageBotInfo={templateMessageBotInfo}
            setTemplateMessageBotInfo={setTemplateMessageBotInfo}
            handleTemplateMessageSave={handleTemplateMessageSave}
            setAddNewBtn={setAddNewBtn}
            referState={referState}
            accountNumber={accountNumber}
            selectedCustomer={selectedCustomer}
            handleBotData={handleBotData}
          />
        )}
        {campaignBtn && (
          <TemplateMessageFormCampaign
            templateMessageCampaignInfo={templateMessageCampaignInfo}
            setTemplateMessageCampaignInfo={setTemplateMessageCampaignInfo}
            handleTemplateMessageSave={handleTemplateMessageSave}
            setAddNewBtn={setAddNewBtn}
          />
        )}
      </TabPanel>
    </Paper>
  );
};

export default NewBotForm;
