import { Add } from "@mui/icons-material";
import {
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import BillingPaymentModal from "./BillingPaymentModal";
import BillingTable from "./BillingTable";
import PaymentMethodCashInModal from "./PaymentMethodCashInModal/PaymentMethodCashInModal";

const Billing = ({ setRender, render }) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [gateWay, setAllGateWay] = useState([]);
  console.log("gateWay:", gateWay);

  const [allBilling, setAllBilling] = useState([]);
  const [balanceDetails, setBalanceDetails] = useState({});
  console.log("balanceDetails:", balanceDetails);
  const [paymentInfoInfo, setPaymentInfoInfo] = useState({});
  const [inputField, setInputField] = useState({});

  const [trnxId, setTrnxId] = useState("");
  const [payOrReq, setPayOrReq] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [newBalance, setNewBalance] = useState("");
  const [dueAmount, setDueAmount] = useState({ amount: 1000, currency: "BDT" });
  console.log("dueAmountttt:", dueAmount);
  const [selectedMethod, setselectedMethod] = useState("");

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setPaymentInfoInfo({});
    // setInputField({});
  };
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    setPaymentInfoInfo({});
    setInputField({});
  };

  const handleChange = (event) => {
    setAccountNumber(event.target.value);
  };

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", parsedObject?.accn_id);
    formData.append("token", Math.round(Math.random() * 10000000000));

    fetch(`https://whatsapp.ghorami.com/api/billing_api/get_all_billing.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setAllBilling(result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", 1234);
    formData.append("token", Math.round(Math.random() * 10000000000));

    fetch(
      `https://whatsapp.ghorami.com/api/dev_api/get_all_gateway.php?user_id=${parsedObject?.poster}&accn_id=${parsedObject?.accn_id}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        setAllGateWay(result);
        setAccountNumber(result[0]?.temp_refer);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);
  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    fetch(
      `https://whatsapp.ghorami.com/api/billing_api/get_balance_summery.php?user_id=${parsedObject?.poster}&accn_id=${parsedObject?.accn_id}&panel_id=${accountNumber}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("result555:", result);
        setBalanceDetails(result?.[0]);
        setDueAmount({
          amount: result?.[0]?.due,
          currency: result?.[0]?.currency,
        });
        setSelectedCurrency(result?.[0]?.currency);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [render, accountNumber]);

  return (
    <Stack
      spacing={1}
      sx={{
        bgcolor: "#dbf0f7",
        width: "100%",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <BillingPaymentModal
        open={open}
        handleClose={handleClose}
        setPaymentInfoInfo={setPaymentInfoInfo}
        paymentInfoInfo={paymentInfoInfo}
        setInputField={setInputField}
        inputField={inputField}
        handleOpen2={handleOpen2}
        selectedMethod={selectedMethod}
        setselectedMethod={setselectedMethod}
        setTrnxId={setTrnxId}
        setNewBalance={setNewBalance}
        setSelectedCurrency={setSelectedCurrency}
      />
      <PaymentMethodCashInModal
        open1={open}
        open2={open2}
        accountNumber={accountNumber}
        gateWay={gateWay}
        handleClose={handleClose2}
        amount={paymentInfoInfo.amount}
        currency={paymentInfoInfo.currency}
        note={paymentInfoInfo.note}
        setselectedMethod={setselectedMethod}
        selectedMethod={selectedMethod}
        selectedCurrency={selectedCurrency}
        trnxId={trnxId}
        newBalance={newBalance}
        setPayOrReq={setPayOrReq}
        payOrReq={payOrReq}
        dueAmount={dueAmount}
      />
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="p" sx={{ fontWeight: 600 }}>
          Payment activity
        </Typography>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="center"
          justifyContent="right"
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              fontSize: "9pt",
              color: "black",
              bgcolor: "#c1e9f7",
            }}
          >
            Payment settings
          </Button>
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="right"
          >
            <Select
              size="small"
              value={accountNumber}
              onChange={handleChange}
              displayEmpty
              sx={{
                fontSize: "10px",
                width: "150px",
                padding: "0px 0px !important",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {gateWay?.map((element) => (
                <MenuItem value={element?.temp_refer} sx={{ fontSize: "10px" }}>
                  <Stack
                    direction={"column"}
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <Typography variant="p" sx={{ fontSize: "8pt" }}>
                      {" "}
                      {element?.busin_name}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "7pt", color: "gray" }}
                    >
                      {element?.wap_business_id}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      </Stack>
      <Paper sx={{ padding: "10px" }}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack justifyContent={"flex-start"}>
            <Typography variant="p" sx={{ fontSize: "9pt", textAlign: "left" }}>
              Ad account
            </Typography>
            <Typography variant="p" sx={{ fontWeight: 600, textAlign: "left" }}>
              Shopnobari Manager
            </Typography>
          </Stack>
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack justifyContent={"flex-start"}>
              <Typography
                variant="p"
                sx={{ fontSize: "9pt", textAlign: "left" }}
              >
                Current balance
              </Typography>
              <Typography
                variant="p"
                sx={{ fontWeight: 600, textAlign: "left" }}
              >
                {balanceDetails?.balance} {balanceDetails?.currency}
              </Typography>
            </Stack>
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "capitalize",
                fontSize: "9pt",
                // color: "black",
                // bgcolor: "#c1e9f7",
              }}
              onClick={() => {
                setPayOrReq(true);
                handleOpen();
              }}
            >
              Request balance
            </Button>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ padding: "10px" }}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
          >
            <Select
              size="small"
              value={accountNumber}
              onChange={handleChange}
              displayEmpty
              sx={{ fontSize: "10px", width: "100px" }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" sx={{ fontSize: "10px" }}>
                <em>Transaction</em>
              </MenuItem>
              <MenuItem sx={{ fontSize: "10px" }} value={10}>
                Ten
              </MenuItem>
              <MenuItem sx={{ fontSize: "10px" }} value={20}>
                Twenty
              </MenuItem>
              <MenuItem sx={{ fontSize: "10px" }} value={30}>
                Thirty
              </MenuItem>
            </Select>
            <IconButton size="small">
              <Add sx={{ fontSize: "14pt" }} />
            </IconButton>
            <Typography
              variant="p"
              sx={{
                fontSize: "9pt",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              Use filters to refine your search
            </Typography>
          </Stack>
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack justifyContent={"flex-start"}>
              <Typography
                variant="p"
                sx={{ fontSize: "9pt", textAlign: "left" }}
              >
                Bill amount
              </Typography>
              <Typography
                variant="p"
                sx={{ fontWeight: 600, textAlign: "left" }}
              >
                {dueAmount?.amount} {balanceDetails?.currency}
              </Typography>
            </Stack>
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "capitalize",
                fontSize: "9pt",
                // color: "black",
                // bgcolor: "#c1e9f7",
              }}
              onClick={() => {
                handleOpen2();
                setPayOrReq(false);
              }}
            >
              Pay now
            </Button>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ padding: "10px" }}>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="center"
          justifyContent="right"
        >
          <Select
            size="small"
            value={accountNumber}
            onChange={handleChange}
            displayEmpty
            sx={{ fontSize: "10px", width: "100px" }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" sx={{ fontSize: "10px" }}>
              <em>Download</em>
            </MenuItem>
            <MenuItem sx={{ fontSize: "10px" }} value={10}>
              Ten
            </MenuItem>
            <MenuItem sx={{ fontSize: "10px" }} value={20}>
              Twenty
            </MenuItem>
            <MenuItem sx={{ fontSize: "10px" }} value={30}>
              Thirty
            </MenuItem>
          </Select>
        </Stack>
        <BillingTable allBilling={allBilling} />
      </Paper>
    </Stack>
  );
};

export default Billing;
