import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const TemplateUpdate = ({
  templateMessageBotInfo,
  setTemplateMessageBotInfo,
  handleTemplateMessageSave,
  setAddNewBtn,
  referState,
  selectedTempId,
  accountNumber,
}) => {
  console.log("templateMessageBotInfo:", templateMessageBotInfo);
  console.log("referStateasasa:", referState);
  let newObject = localStorage.getItem("wmData");
  let parsedObject = JSON.parse(newObject);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  console.log("personName:", personName);

  const [inputField, setInputField] = useState({});
  console.log("inputField:", inputField);
  const [buttonFields, setButtonFields] = useState([{ id: 0 }]);
  const [nameAlert, setNameAlert] = useState(false);
  const [buttonAlert, setButtonAlert] = useState(false);
  const [tempDetailsList, setTempDetailsList] = useState([]);
  console.log("tempDetailsList:", tempDetailsList);

  const [allButton, setAllButton] = useState([]);
  console.log("allButton:", allButton);

  useEffect(() => {
    fetch(
      `https://whatsapp.ghorami.com/api/wap_template/get_template_info.php?temple_id=${selectedTempId}&panel_idc=${accountNumber}&poster=${parsedObject?.poster}`
    )
      .then((res) => res.json())
      .then((result) => {
        setTempDetailsList(result);
        let newTemplateMessageBotInfo = templateMessageBotInfo;
        let newInputField = inputField;

        newTemplateMessageBotInfo.language = result?.language;
        newInputField.language = result?.language;
        newTemplateMessageBotInfo.Name = result?.name;
        newInputField.Name = result?.name;

        result?.components?.map((element) => {
          if (element?.type === "HEADER") {
            newTemplateMessageBotInfo.header = element?.text;
            newInputField.header = element?.text;
          }
        });

        setTemplateMessageBotInfo(newTemplateMessageBotInfo);
        setInputField(newInputField);
      });
  }, [referState, selectedTempId, accountNumber]);

  useEffect(() => {
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_all_button_text.php?item_ref=${referState}`
    )
      .then((res) => res.json())
      .then((result) => setAllButton(result));
  }, [referState]);

  const handleFormChange = (index, event) => {
    if (!/^[^\s]+$/.test(event.target.value)) {
      setButtonAlert("*Space not allowed");
      let newtemplateMessageBotInfo = templateMessageBotInfo;
      newtemplateMessageBotInfo.buttons = [];
      setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    } else if (/^[^\s]+$/.test(event.target.value)) {
      setButtonAlert(false);
      fetch(
        `https://whatsapp.ghorami.com/api/chatbot_api/check_button.php?btn_title=${event.target.value}`
      )
        .then((res) => res.json())
        .then((result) => {
          console.log("resultsdsdsd:", result);

          if (result[0]?.id && result) {
            setButtonAlert("*Name Already Exist");
            let newtemplateMessageBotInfo = templateMessageBotInfo;
            newtemplateMessageBotInfo.buttons = [];
            setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
          } else {
            let data = [...buttonFields];
            data[index][event.target.name] = event.target.value;
            data[index].id = index;
            setButtonFields(data);

            let newtemplateMessageBotInfo = templateMessageBotInfo;
            newtemplateMessageBotInfo.buttons = buttonFields;
            setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
            setButtonAlert(false);
            // }
          }
        });
    }
  };

  const handleNameChange = (event) => {
    if (!/^[^\s]+$/.test(event.target.value)) {
      setNameAlert("*Space not allowed");
      const newtemplateMessageBotInfo = templateMessageBotInfo;
      newtemplateMessageBotInfo[event.target.name] = "";
      setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    } else if (/^[^\s]+$/.test(event.target.value)) {
      setNameAlert(false);
      fetch(
        `https://whatsapp.ghorami.com/api/chatbot_api/check_template_name.php?tem_name=${event.target.value}`
      )
        .then((res) => res.json())
        .then((result) => {
          console.log("resultsdsdsd:", result);

          if (result[0]?.id && result) {
            setNameAlert("*Name Already Exist");
            const newtemplateMessageBotInfo = templateMessageBotInfo;
            newtemplateMessageBotInfo[event.target.name] = "";
            setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
          } else {
            const newtemplateMessageBotInfo = templateMessageBotInfo;
            newtemplateMessageBotInfo[event.target.name] = event.target.value;
            setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
            setNameAlert(false);
            // }
          }
        });
    }
  };

  const addButtonFields = () => {
    let id = Math.floor(Math.random() * 1000000000 + 1);
    let newField = { id: id };
    if (buttonFields.length <= 2) {
      setButtonFields([...buttonFields, newField]);
    } else {
      setButtonFields([...buttonFields]);
    }
  };

  const handleDeleteButtonFields = (id) => {
    let filteredField = buttonFields.filter((element) => element.id != id);
    setButtonFields([...filteredField]);
  };

  const clear = () => {
    setTemplateMessageBotInfo({});
    setInputField({});
  };
  const handleButtonChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(value);
    const newtemplateMessageBotInfo = templateMessageBotInfo;
    newtemplateMessageBotInfo.request = value.join(", ");
    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
  };

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "header") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "language") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "category") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "details") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newtemplateMessageBotInfo = templateMessageBotInfo;
      newtemplateMessageBotInfo[event.target.name] = event.target.value;
      setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Name
              </Typography>
              {nameAlert && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  {nameAlert}
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="Name"
              size="small"
              value={templateMessageBotInfo?.Name}
              placeholder="Name"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleNameChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Language
              </Typography>
            </Stack>

            <Select
              value={templateMessageBotInfo?.language}
              name="language"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={"en-US"}>English</MenuItem>
              <MenuItem value={"bn-BD"}>Bangla</MenuItem>
              <MenuItem value={"ar-UAE"}>Arabic</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "language" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Category
              </Typography>
            </Stack>

            <Select
              value={templateMessageBotInfo?.category}
              name="category"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={"marketing"}>Marketing</MenuItem>
              <MenuItem value={"transactional"}>Transactional</MenuItem>
              <MenuItem value={"OTP"}>OTP</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "category" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Header
              </Typography>
              {inputField.fieldErrorAlert === "header" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Header Field missing
                </small>
              )}
            </Stack>

            {
              <OutlinedInput
                type="text"
                name="header"
                size="small"
                placeholder="Header"
                value={templateMessageBotInfo?.header}
                sx={{
                  width: "100%",

                  borderRadius: "6px",

                  fontSize: "9pt",
                }}
                onChange={handleChange}
              />
            }
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Info/Details
              </Typography>
              {inputField.fieldErrorAlert === "details" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
            </Stack>

            <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter about your Service"
              // value={about}
              name="details"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",

                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        {/* <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Template for
              </Typography>
              {inputField.fieldErrorAlert === "template_title" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
            </Stack>

            <Select
              multiple
              displayEmpty
              value={personName}
              // name="buttons"
              onChange={handleButtonChange}
              input={<OutlinedInput />}
              size="small"
              placeholder="Request"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select template</em>;
                }

                return selected.join(", ");
              }}
              // MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {allButton?.map((element, index) => (
                <MenuItem
                  key={index}
                  value={element.btn_value}
                  style={getStyles(element.btn_value, personName, theme)}
                >
                  {element.btn_value}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid> */}
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "9pt",
              fontWeight: 500,
              marginLeft: "10px",
              // marginTop: "8px",
              cursor: "pointer",
            }}
            onClick={addButtonFields}
          >
            Add Button +
          </Typography>

          {buttonAlert && (
            <small style={{ color: "#CB0101", fontSize: "8pt" }}>
              {buttonAlert}
            </small>
          )}
        </Stack>
        {buttonFields?.map((input, index) => {
          return (
            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ boxSizing: "border-box" }}
              >
                <OutlinedInput
                  type="text"
                  name="btn_title"
                  size="small"
                  placeholder="Title"
                  sx={{
                    width: "90%",

                    borderRadius: "6px",

                    fontSize: "9pt",
                    boxSizing: "border-box",
                  }}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <OutlinedInput
                  type="text"
                  name="btn_value"
                  size="small"
                  placeholder="value"
                  sx={{
                    width: "90%",

                    borderRadius: "6px",

                    fontSize: "9pt",
                    boxSizing: "border-box",
                  }}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    handleDeleteButtonFields(input.id);
                  }}
                >
                  <HighlightOff fontSize="inherit" />
                </IconButton>
              </Stack>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.header &&
            !nameAlert &&
            !buttonAlert &&
            personName[0] &&
            inputField.details &&
            inputField.language &&
            inputField.category &&
            buttonFields[0]?.btn_title &&
            buttonFields[0]?.btn_value ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                  handleTemplateMessageSave();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TemplateUpdate;
