import { Avatar, Skeleton, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import OneConversation from "./OneConversation";
import SceletonChatLeft from "./SceletonChatRight";

const TextBox = ({
  customerResponse,
  template,
  setAddNewBtn,
  setSelectedTemplate,
  selectedTemplate,
  botResponse,
  setTemplate,
  setConversation,
  referState,
  listReplyBtn,
  setListReplyBtn,
  setSelectedReplyBtnInfo,
  selectedCustomer,
  dropDownBtn,
  setDropDownBtn,
  selectedBtn,
  setSelectedBtn,
  handleBotResponse,
}) => {
  let newObject = localStorage.getItem("wmData");
  let parsedObject = JSON.parse(newObject);
  const [panelName, setPanelName] = useState(parsedObject?.name);
  const [panelPhone, setPanelPhone] = useState(parsedObject?.phone);
  console.log("newObjectInfo---:", parsedObject.phone);
  console.log("customerResponserrrr:", customerResponse);
  console.log("selectedCustomer22222:", selectedCustomer);
  console.log("customerResponserrrr:", customerResponse);

  return (
    <Box sx={{ width: "100%" }}>
      {customerResponse.map(
        (element) =>
          element?.wap_type == "sequence_order" &&
          element?.message && (
            <Box sx={{ width: "100%" }}>
              {element?.message ? (
                <Stack sx={{ width: "100%" }}>
                  {/* "8801849225187" */}
                  {element?.wap_number === panelPhone ? (
                    <Stack
                      spacing={1}
                      direction={"row"}
                      alignItems="flex-start"
                      justifyContent="left"
                      sx={{ marginY: "20px" }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: "30px", height: "30px" }}
                      />
                      <Stack
                        spacing={1}
                        alignItems="flex-start"
                        justifyContent="left"
                        sx={{
                          marginTop: "-44px",
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "9pt",
                            fontWeight: 700,
                            color: "gray",
                          }}
                        >
                          {element?.time_stamp.split(" ")[0]}
                        </Typography>
                        <Stack
                          spacing={1}
                          alignItems="flex-start"
                          justifyContent="right"
                          sx={{
                            padding: "15px",
                            bgcolor: "white",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "9.5pt",
                              fontWeight: 700,
                              lineHeight: "8px",
                              color: "gray",
                            }}
                          >
                            {panelName}
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "11pt",
                              lineHeight: "15px",
                              textAlign: "left",
                              maxWidth: "150px",
                            }}
                          >
                            {element?.message}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      spacing={1}
                      direction={"row"}
                      alignItems="flex-end"
                      justifyContent="right"
                      sx={{ marginY: "20px" }}
                    >
                      <Stack
                        spacing={1}
                        alignItems="flex-start"
                        justifyContent="left"
                        sx={{
                          marginBottom: "0px",
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "9pt",
                            fontWeight: 700,
                            color: "gray",
                          }}
                        >
                          {element?.time_stamp.split(" ")[0]}
                        </Typography>
                        <Stack
                          spacing={1}
                          alignItems="flex-start"
                          justifyContent="left"
                          sx={{
                            padding: "15px",
                            color: "black",
                            bgcolor: "#c4e1eb",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "9.5pt",
                              fontWeight: 700,
                              lineHeight: "8px",
                              color: "black",
                            }}
                          >
                            {element?.name}
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "11pt",
                              lineHeight: "15px",
                              textAlign: "left",
                            }}
                          >
                            {element?.message}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: "30px", height: "30px" }}
                      />
                    </Stack>
                  )}
                  {/* <Stack
                    spacing={1}
                    direction={"row"}
                    alignItems="flex-end"
                    justifyContent="right"
                    sx={{ marginY: "20px" }}
                  >
                    <Typography
                      variant="p"
                      sx={{ fontSize: "8pt", fontWeight: 600, color: "gray" }}
                    >
                      {element?.time_stamp}
                    </Typography>
                    <Stack
                      spacing={1}
                      alignItems="flex-end"
                      justifyContent="right"
                      sx={{
                        padding: "15px",
                        color: "white",
                        bgcolor: "#1976d2",
                        borderRadius: "8px",
                        maxWidth: "45%",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "9.5pt",
                          fontWeight: 700,
                          lineHeight: "8px",
                          color: "white",
                        }}
                      >
                        {element?.name}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", lineHeight: "15px" }}
                      >
                        {element?.message}
                      </Typography>
                    </Stack>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: "30px", height: "30px" }}
                    />
                  </Stack> */}
                </Stack>
              ) : (
                <SceletonChatLeft />
              )}
              {/* <Stack spacing={1}>
                {element?.track_recode?.[0]?.idt &&
                  element?.track_recode?.map((element) => (
                    <OneConversation
                      eachItem={element}
                      setAddNewBtn={setAddNewBtn}
                      template={template}
                      // conversation={conversation}
                      setSelectedTemplate={setSelectedTemplate}
                      selectedTemplate={selectedTemplate}
                      // botResponse={templateList}
                      referState={referState}
                      setTemplate={setTemplate}
                      setConversation={setConversation}
                      listReplyBtn={listReplyBtn}
                      setListReplyBtn={setListReplyBtn}
                      // selectedReplyBtnInfo={selectedReplyBtnInfo}
                      setSelectedReplyBtnInfo={setSelectedReplyBtnInfo}
                      selectedCustomer={selectedCustomer}
                      dropDownBtn={dropDownBtn}
                      setDropDownBtn={setDropDownBtn}
                      selectedBtn={selectedBtn}
                      setSelectedBtn={setSelectedBtn}
                    />
                  ))}
              </Stack> */}
            </Box>
          )
      )}
    </Box>
  );
};

export default TextBox;
