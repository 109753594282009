import {
  Groups,
  KeyboardArrowUp,
  Menu,
  MoreHoriz,
  MoreVert,
  Search,
  Visibility,
  WhatsApp,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Typography,
  Avatar,
  Paper,
  CircularProgress,
  circularProgressClasses,
  TextField,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "../../../css/inbox.css";
import CreateNewBotModal from "../../Inbox Components/CreateNewBotModal";
import CustomerCard from "../../Inbox Components/CustomerCard";

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      {/* <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        /> */}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const ContactGroup = ({
  handleCampaignBtn,
  regNumBtn,
  setNewContactBtn,
  setSearchFound,
  setRegNumAddNewBtn,
  setPaymentAddNewBtn,
  setRegNumBtn,
  handleSelectedCustomer,
  handleContactGroupData,
  allCustomer,
  contactGroup,
  setShowTable,
  setReferState,
  setPaymentBtn,
  setAddNewBtn,
  handleAutoBotBtn,
  handleMessageBtn,
  setNewContactListBtn,
}) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("allCustomer:", allCustomer);

  console.log("search:", search);
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setSearchFound(false);
  };
  return (
    <Box>
      {/* <FacebookCircularProgress /> */}

      <Stack sx={{ padding: "20px", paddingBottom: "0px" }}>
        <Typography
          variant="p"
          sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "left" }}
        >
          Contact Group
        </Typography>

        <Stack sx={{ marginTop: "10px" }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Box sx={{ width: "20%" }}>
              <Typography variant="p">Live(1)</Typography>
            </Box>
            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{ width: "30%" }}
            >
              <IconButton aria-label="delete" size="small">
                <KeyboardArrowUp fontSize="small" />
              </IconButton>

              <Box className="outer" sx={{ position: "relative" }}>
                <IconButton aria-label="delete" size="small">
                  <MoreHoriz fontSize="small" />
                </IconButton>
                <Paper
                  className="inner"
                  sx={{
                    display: "none",
                    zIndex: 10,
                    paddingY: "10px",
                    bgcolor: "white",
                    // borderRadius: 0,
                    position: "absolute",
                    width: "150px",
                    height: "auto",
                    top: 30,
                    left: "-200%",
                  }}
                >
                  <Stack spacing={0.5} alignItems="flex-start">
                    <Stack
                      direction={"row"}
                      justifyContent="left"
                      alignItems={"center"}
                      // size="small"
                      sx={{
                        boxSizing: "border-box",
                        width: "100%",
                        padding: "5px",
                        color: "black",
                        cursor: "pointer",
                        "&:hover": {
                          color: "black",
                          backgroundColor: "#D4EAFF",
                        },
                      }}
                      onClick={() => {
                        setNewContactBtn(true);
                        setRegNumBtn(false);
                        setPaymentBtn(false);
                        handleMessageBtn(false);
                        handleAutoBotBtn(false);
                        handleCampaignBtn(false);
                        setAddNewBtn(false);
                        setRegNumAddNewBtn(false);
                        setPaymentAddNewBtn(false);
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", textAlign: "left" }}
                      >
                        New
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Box>
            </Stack>
          </Stack>
          <Stack></Stack>
        </Stack>

        <Stack
          // spacing={1}
          justifyContent={
            contactGroup.length == 0 ? "flex-end" : "space-between"
          }
          alignItems="flex-start"
          sx={{ marginTop: "10px", height: "85vh" }}
        >
          {contactGroup.length > 0 && (
            <Box sx={{ height: "66vh", overflow: "auto" }}>
              {contactGroup?.map((element) => (
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems="center"
                  sx={{ marginTop: "10px", cursor: "pointer" }}
                  onClick={() => {
                    handleSelectedCustomer(element);
                    setReferState(element.refer);
                    setShowTable(true);
                    setAddNewBtn(false);
                    setNewContactListBtn(false);
                  }}
                >
                  {/* <Avatar
                    alt="Remy Sharp"
                    src="/static/images/avatar/1.jpg"
                    sx={{ width: "35px", height: "35px" }}
                  /> */}
                  <Paper
                    sx={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Groups />
                    </Stack>
                  </Paper>
                  <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{ width: "100%" }}
                  >
                    <Stack alignItems={"flex-start"}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "12pt", fontWeight: 600 }}
                      >
                        {element?.Name}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "9pt", fontWeight: 500 }}
                      >
                        {element?.state == "1" ? "Active" : "Inactive"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Box>
          )}
          <Button
            variant="contained"
            sx={{ width: "100%", textTransform: "capitalize" }}
            onClick={() => {
              setNewContactListBtn(true);
              setShowTable(false);
              setAddNewBtn(false);
            }}
          >
            Create new Contact group
          </Button>
        </Stack>

        {regNumBtn && (
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent="left"
            alignItems="center"
            sx={{ marginTop: "10px" }}
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: "45px", height: "45px" }}
            />
            <Stack
              justifyContent="center"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Stack alignItems={"flex-start"}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 600 }}
                >
                  Number 1
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", fontWeight: 600 }}
                >
                  online
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ContactGroup;
