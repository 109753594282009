import React from "react";
import { Box } from "@mui/material";
import nexus from "../Card_pic/download.jpg";
import dbdebit from "../Card_pic/butch.jpg";
import master from "../Card_pic/mastercard-logo-4EB70F23D3-seeklogo.com.png";
import visa from "../Card_pic/VISA_Logo1.svg_.png";
const Card = ({
  amount,
  note,
  handleClose,
  setToggleDisplay,
  setselectedMethod,
}) => {
  const ImagesInfo = [
    { name: "mastercard", image: master, id: "4" },
    { name: "visa", image: visa, id: "5" },
    { name: "dbdebit", image: dbdebit, id: "6" },
    { name: "nexus", image: nexus, id: "7" },
  ];
  return (
    <div>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          py: "1rem",
        }}
      >
        {ImagesInfo?.map((item) => {
          return (
            item.image && (
              <img
                src={item?.image}
                alt=""
                key={item?.id}
                style={{
                  width: "18%",
                  margin: "0 5px",
                  objectFit: "cover",
                }}
                onClick={() => {
                  setselectedMethod(item);
                  setToggleDisplay(true);
                }}
              />
            )
          );
        })}
      </Box>
    </div>
  );
};

export default Card;
