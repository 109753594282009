import { Button, Modal, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};
const ConfirmModal = ({
  open,
  handleClose,
  selectedReplyBtnInfo,
  handleClick,
}) => {
  console.log("selectedReplyBtnInfo:", selectedReplyBtnInfo);
  const navigate = useNavigate();

  const handleConfirm = () => {
    // let newObject = localStorage.getItem("wmData");
    // let parsedObject = JSON.parse(newObject);
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/fun_wm_button_list_delete.php?template_name=${selectedReplyBtnInfo?.tem_name}&btnListid=${selectedReplyBtnInfo?.id}&btnName=${selectedReplyBtnInfo?.btn_title}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("result:", result);
        if (result?.errState === "success") {
          handleClick(result?.errMsg);
          handleClose();
        }
      });
  };

  return (
    <Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="p" sx={{ fontSize: "16pt", fontWeight: 700 }}>
              Delete Confirmation
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ marginTop: "5px" }}
          >
            <Typography variant="p" sx={{ fontSize: "15pt" }}>
              Do you want to confirm to delete
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ marginTop: "30px" }}
          >
            <Button
              variant="contained"
              sx={{
                // marginTop: "15px",
                width: "40%",
                borderRadius: "30px",
                bgcolor: "gray",
                fontSize: { xs: "8pt", md: "10pt" },
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#14506A",
                },
              }}
              size="small"
              onClick={handleClose}
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{
                // marginTop: "15px",
                width: "40%",
                borderRadius: "30px",
                bgcolor: "#22AB7D",
                fontSize: { xs: "8pt", md: "10pt" },
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#14506A",
                },
              }}
              size="small"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default ConfirmModal;
