import {
  Add,
  KeyboardArrowUp,
  Menu,
  MoreHoriz,
  MoreVert,
  Search,
  Visibility,
  WhatsApp,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Typography,
  Avatar,
  Paper,
  CircularProgress,
  circularProgressClasses,
  TextField,
  OutlinedInput,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import "../css/inbox.css";
import CreateNewBotModal from "./Inbox Components/CreateNewBotModal";
import CustomerCard from "./Inbox Components/CustomerCard";

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      {/* <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      /> */}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const Inbox = ({
  setListReplyBtn,
  setSelectedTemplate,
  render,
  setTemplate,
  setConversation,
  setRender,
  setToggle,
  messageBtn,
  autoBotBtn,
  campaignBtn,
  handleCampaignBtn,
  regNumBtn,
  setNewContactBtn,
  searchFound,
  setSearchFound,
  newContactBtn,
  setRegNumAddNewBtn,
  setPaymentAddNewBtn,
  setRegNumBtn,
  setSelectedCustomer,
  handleSelectedCustomer,
  handleSearchCustomer,
  paymentBtn,
  selectedCustomer,
  handleCustomerData,
  handleBotData,
  allCustomer,
  allBot,
  campaignDetailsList,
  setReferState,
  campaignDetailsBtn,
  setCampaignDetailsBtn,
  setPaymentBtn,
  setAddNewBtn,
  handleAutoBotBtn,
  handleMessageBtn,
  handleCustomerResponse,
  handleBotResponse,
  handleTemplateList,
  handleCampaignDetailsList,
  handleCampaignDetails,
  handleCampaignNumber,
  handleCampaignWaitingNumber,
  setCreateCampaignBtn,
  setStartCampaignInfo,
  setInputField,
}) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("allCustomer:", allCustomer);

  console.log("search:", search);
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setSearchFound(false);
  };
  return (
    <Box>
      {/* <FacebookCircularProgress /> */}

      <Stack spacing={1.5} sx={{ padding: "20px", paddingBottom: "0px" }}>
        <Typography
          variant="p"
          sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "left" }}
        >
          Inbox
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ width: "10px" }}></Box>
          <Box className="outer" sx={{ position: "relative" }}>
            {/* <IconButton aria-label="delete" size="small">
              <MoreVert />
            </IconButton> */}
            {/* <Paper
              className="inner"
              sx={{
                display: "none",
                zIndex: 10,
                paddingY: "10px",
                bgcolor: "white",
                // borderRadius: 0,
                position: "absolute",
                width: "150px",
                height: "auto",
                top: 30,
                left: "-200%",
              }}
            >
              <Stack spacing={0.5} alignItems="flex-start">
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  // size="small"
                  sx={{
                    boxSizing: "border-box",
                    width: "100%",
                    padding: "5px",
                    color: "black",
                    cursor: "pointer",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#D4EAFF",
                    },
                  }}
                  onClick={() => {
                    setRegNumBtn(true);
                    setPaymentBtn(false);
                    handleMessageBtn(false);
                    handleAutoBotBtn(false);
                    handleCampaignBtn(false);
                    setAddNewBtn(false);
                    setRegNumAddNewBtn(false);
                    setPaymentAddNewBtn(false);
                    setNewContactBtn(false);
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", textAlign: "left" }}
                  >
                    Registered number
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{
                    width: "100%",
                    boxSizing: "border-box",
                    textTransform: "capitalize",
                    padding: "5px",
                    color: "black",
                    cursor: "pointer",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#D4EAFF",
                    },
                  }}
                  onClick={() => {
                    setRegNumBtn(false);
                    setPaymentBtn(true);
                    handleMessageBtn(false);
                    handleAutoBotBtn(false);
                    handleCampaignBtn(false);
                    setAddNewBtn(false);
                    setRegNumAddNewBtn(false);
                    setPaymentAddNewBtn(false);
                    setNewContactBtn(false);
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", textAlign: "left" }}
                  >
                    Payment
                  </Typography>
                </Stack>
              </Stack>
            </Paper> */}
          </Box>
        </Stack>
        <Stack
          spacing={1}
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          sx={{}}
        >
          <Button
            variant={messageBtn ? "contained" : "outlined"}
            color="success"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              handleMessageBtn(true);
              handleAutoBotBtn(false);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumBtn(false);
              setPaymentBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setNewContactBtn(false);
              setCampaignDetailsBtn(false);
              setCreateCampaignBtn(false);
              setConversation(true);
              setTemplate(false);
              setSelectedCustomer({});
              handleCustomerData();
            }}
          >
            Message
          </Button>
          <Button
            variant={autoBotBtn ? "contained" : "outlined"}
            color="success"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              handleMessageBtn(false);
              handleAutoBotBtn(true);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumBtn(false);
              setPaymentBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setNewContactBtn(false);
              setSelectedCustomer({});
              setCampaignDetailsBtn(false);
              setCreateCampaignBtn(false);
              setTemplate(true);
              setConversation(false);
              handleBotData();
            }}
          >
            AutoBot
          </Button>
          <Button
            variant={campaignBtn ? "contained" : "outlined"}
            color="success"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              handleMessageBtn(false);
              handleAutoBotBtn(false);
              handleCampaignBtn(true);
              setAddNewBtn(false);
              setRegNumBtn(false);
              setPaymentBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setSelectedCustomer({});
              setNewContactBtn(false);
              setCampaignDetailsBtn(false);
              setCreateCampaignBtn(false);
              handleCampaignDetailsList();
            }}
          >
            Campaign
          </Button>
        </Stack>
        <Stack sx={{ marginTop: "10px" }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack
              sx={{ width: "20%" }}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
            >
              <Typography variant="p">Live(1)</Typography>

              {messageBtn && (
                <IconButton
                  aria-label=""
                  size="small"
                  sx={{ color: "black" }}
                  onClick={() => {
                    // handleMessageBtn(false);
                    // handleAutoBotBtn(false);
                    // handleCampaignBtn(true);
                    setNewContactBtn(true);
                    setAddNewBtn(false);
                    setRegNumBtn(false);
                    setPaymentBtn(false);
                    setRegNumAddNewBtn(false);
                    setPaymentAddNewBtn(false);
                    // setSelectedCustomer({});

                    setCampaignDetailsBtn(false);
                    setCreateCampaignBtn(false);
                  }}
                >
                  <Add fontSize="small" />
                </IconButton>
              )}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{ width: "30%" }}
            >
              <IconButton aria-label="delete" size="small">
                <KeyboardArrowUp fontSize="small" />
              </IconButton>

              <Box className="outer" sx={{ position: "relative" }}>
                {/* <IconButton aria-label="delete" size="small">
                  <MoreHoriz fontSize="small" />
                </IconButton> */}
                {/* <Paper
                  className="inner"
                  sx={{
                    display: "none",
                    zIndex: 10,
                    paddingY: "10px",
                    bgcolor: "white",
                    // borderRadius: 0,
                    position: "absolute",
                    width: "150px",
                    height: "auto",
                    top: 30,
                    left: "-200%",
                  }}
                >
                  <Stack spacing={0.5} alignItems="flex-start">
                    <Stack
                      direction={"row"}
                      justifyContent="left"
                      alignItems={"center"}
                      // size="small"
                      sx={{
                        boxSizing: "border-box",
                        width: "100%",
                        padding: "5px",
                        color: "black",
                        cursor: "pointer",
                        "&:hover": {
                          color: "black",
                          backgroundColor: "#D4EAFF",
                        },
                      }}
                      onClick={() => {
                        setNewContactBtn(true);
                        setRegNumBtn(false);
                        setPaymentBtn(false);
                        handleMessageBtn(false);
                        handleAutoBotBtn(false);
                        handleCampaignBtn(false);
                        setAddNewBtn(false);
                        setRegNumAddNewBtn(false);
                        setPaymentAddNewBtn(false);
                        setCreateCampaignBtn(false);
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", textAlign: "left" }}
                      >
                        New
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper> */}
              </Box>
            </Stack>
          </Stack>
          <Stack></Stack>
        </Stack>

        {messageBtn && (
          <Box>
            <OutlinedInput
              size="small"
              placeholder="Please enter text"
              onChange={handleSearch}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleSearchCustomer(search);
                    }}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ fontSize: "10pt", width: "100%", marginBottom: "10px" }}
            />

            {searchFound && selectedCustomer && (
              <CustomerCard
                customer={selectedCustomer}
                handleSelectedCustomer={handleSelectedCustomer}
                handleCustomerResponse={handleCustomerResponse}
              />
            )}
            {searchFound && !selectedCustomer && (
              <Paper
                sx={{
                  padding: "15px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
              >
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  // sx={{ marginTop: "10px" }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "8pt", fontWeight: 600 }}
                  >
                    Customer Not found!
                  </Typography>
                </Stack>
              </Paper>
            )}
            {!searchFound && (
              <Box
                sx={{
                  height: "69.6vh",
                  overflowY: "scroll",
                  marginRight: "-20px",
                }}
              >
                {allCustomer?.length > 0 ? (
                  allCustomer?.map((element) => (
                    <CustomerCard
                      customer={element}
                      handleSelectedCustomer={handleSelectedCustomer}
                      handleCustomerResponse={handleCustomerResponse}
                      setConversation={setConversation}
                      setNewContactBtn={setNewContactBtn}
                      setTemplate={setTemplate}
                      handleBotResponse={handleBotResponse}
                    />
                  ))
                ) : (
                  <Stack spacing={1}>
                    {/* For variant="text", adjust the height via font-size */}

                    <Skeleton variant="rounded" width={"100%"} height={60} />
                    <Skeleton variant="rounded" width={"100%"} height={60} />
                    <Skeleton variant="rounded" width={"100%"} height={60} />
                    <Skeleton variant="rounded" width={"100%"} height={60} />
                    <Skeleton variant="rounded" width={"100%"} height={60} />
                    <Skeleton variant="rounded" width={"100%"} height={60} />
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        )}
        {autoBotBtn && (
          <Stack
            // spacing={1}
            justifyContent={allBot.length == 0 ? "flex-end" : "space-between"}
            alignItems="flex-start"
            sx={{ marginTop: "10px", height: "75vh" }}
          >
            {allBot.length > 0 ? (
              <Box
                sx={{
                  height: "66vh",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                {allBot?.map((element) => (
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems="center"
                    sx={{ marginTop: "10px", cursor: "pointer" }}
                    onClick={() => {
                      handleSelectedCustomer(element);
                      setReferState(element.refer);
                      handleBotResponse(element.refer);
                      handleTemplateList(element.Name);
                      setSelectedTemplate(false);
                      setAddNewBtn(false);
                      setTemplate(true);

                      setConversation(false);
                      setListReplyBtn(false);
                    }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: "35px", height: "35px" }}
                    />
                    <Stack
                      justifyContent="center"
                      alignItems="flex-start"
                      sx={{ width: "100%" }}
                    >
                      <Stack alignItems={"flex-start"}>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "12pt", fontWeight: 600 }}
                        >
                          {element?.Name}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "9pt", fontWeight: 500 }}
                        >
                          {element?.state == "1" ? "Active" : "Inactive"}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Box>
            ) : (
              <Stack spacing={1}>
                {/* For variant="text", adjust the height via font-size */}

                <Skeleton variant="rounded" width={"100%"} height={60} />
                <Skeleton variant="rounded" width={"100%"} height={60} />
                <Skeleton variant="rounded" width={"100%"} height={60} />
                <Skeleton variant="rounded" width={"100%"} height={60} />
                <Skeleton variant="rounded" width={"100%"} height={60} />
                <Skeleton variant="rounded" width={"100%"} height={60} />
              </Stack>
            )}
            <Button
              variant="contained"
              sx={{ width: "100%", textTransform: "capitalize" }}
              onClick={handleOpen}
            >
              Create new item
            </Button>
            <CreateNewBotModal
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              setAddNewBtn={setAddNewBtn}
              handleBotData={handleBotData}
            />
          </Stack>
        )}
        {campaignBtn && (
          <Stack
            // spacing={1}
            justifyContent={
              campaignDetailsList.length == 0 ? "flex-end" : "space-between"
            }
            alignItems="flex-start"
            sx={{ marginTop: "10px", height: "75vh" }}
          >
            {campaignDetailsList.length > 0 && (
              <Box
                sx={{
                  height: "66vh",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                {campaignDetailsList?.map((element) => (
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems="center"
                    sx={{ marginTop: "10px", cursor: "pointer" }}
                    onClick={() => {
                      handleSelectedCustomer(element);
                      setReferState(element.refer);
                      setCampaignDetailsBtn(true);
                      handleCampaignDetails(element.refer);
                      handleCampaignNumber(element.refer);
                      handleCampaignWaitingNumber(element.refer);
                      setCreateCampaignBtn(false);
                      setAddNewBtn(false);
                      setToggle(false);
                    }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: "35px", height: "35px" }}
                    />
                    <Stack
                      justifyContent="center"
                      alignItems="flex-start"
                      sx={{ width: "100%" }}
                    >
                      <Stack alignItems={"flex-start"}>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "12pt", fontWeight: 600 }}
                        >
                          {element?.Name}
                          {console.log("elemdsfdfdent:", element)}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "9pt", fontWeight: 500 }}
                        >
                          {element?.state == "1" ? "Active" : "Inactive"}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Box>
            )}
            <Button
              variant="contained"
              sx={{ width: "100%", textTransform: "capitalize" }}
              onClick={() => {
                setCreateCampaignBtn(true);
                setCampaignDetailsBtn(false);
                setAddNewBtn(false);
                setToggle(false);
                setStartCampaignInfo({});
                setInputField({});
              }}
            >
              Create Campaign
            </Button>
          </Stack>
        )}
        {regNumBtn && (
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent="left"
            alignItems="center"
            sx={{ marginTop: "10px" }}
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: "45px", height: "45px" }}
            />
            <Stack
              justifyContent="center"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Stack alignItems={"flex-start"}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 600 }}
                >
                  Number 1
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", fontWeight: 600 }}
                >
                  online
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default Inbox;
