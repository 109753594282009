import { CheckCircle } from "@mui/icons-material";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const columns = [{ label: "Phone" }, { label: "Gateway" }];

const RightBarCampaignTable = ({ tableData }) => {
  console.log("tableData:", tableData);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 520 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                  size="small"
                >
                  <Typography
                    sx={{ fontSize: "8pt", fontWeight: 600 }}
                    variant="p"
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((item) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                  <TableCell align="center" size="small">
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"center"}
                      spacing={0.4}
                    >
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.mnumber ? item.mnumber : "-"}
                      </Typography>
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.states == "2" ? (
                          <CheckCircle
                            sx={{
                              color: "darkgreen",
                              fontSize: "12pt",
                              paddingTop: "4px",
                            }}
                          />
                        ) : (
                          <CheckCircle
                            sx={{
                              color: "gray",
                              fontSize: "9pt",
                              paddingTop: "4px",
                            }}
                          />
                        )}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center" size="small">
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"center"}
                      spacing={0.4}
                    >
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.switch ? item.switch : "-"}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RightBarCampaignTable;
