import React, { useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { useState } from "react";

const New = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("wmData"));
  console.log("user555:", user);
  const [accType, setAccType] = useState([]);
  const [accTypeValue, setAccTypeValue] = useState("");
  const [accNo, setAccNo] = useState("");
  const [currency, setCurrency] = useState("");
  const [accInfo, setAccInfo] = useState("");
  const [reload, setReload] = useState(false);
  
  const handleNewPayment = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("acType", accTypeValue);
    formData.append("acNumber", accNo);
    formData.append("acName", accInfo);
    formData.append("poster", user?.poster);
    formData.append("portal_id", user?.accn_id);
    formData.append("currency", currency);
    axios
      .post(`https://whatsapp.ghorami.com/api/api_web/ac_insert.php`, formData)
      .then((res) => {
        if (res.data === "success") {
          swal({
            title: "Success",
            text: "Payment method added successfully",
            icon: "success",
          }).then((willSuccess) => {
            if (willSuccess) {
              navigate("/");
            }
          });
        }
      });
    e.target.reset();
    setAccType([]);
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("SopnoID", user?.sopnoid);
    axios
      .post(
        `https://whatsapp.ghorami.com/api/profile/ac_bil/api_web/fun_actype.php`,
        formData
      )
      .then((res) => {
        setAccType(res.data);
      });
  }, [user?.sopnoid, user?.master_url, reload]);

  const handleReset = () => {
    setAccTypeValue("");
    setAccNo("");
    setCurrency("");
    setAccInfo("");
    setAccType([]);
    setReload(!reload);
  };

  return (
    <div>
      <form onSubmit={handleNewPayment}>
        {" "}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField
              label="Account Number"
              variant="outlined"
              fullWidth
              onChange={(e) => setAccNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Account Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accTypeValue || ""}
                label="Account Type"
                onChange={(e) => {
                  setAccTypeValue(e.target.value);
                }}
              >
                {accType.map((item) => (
                  <MenuItem key={item?.gida} value={item?.gida}>
                    {item?.gname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label1">Currency</InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                id="demo-simple-select"
                value={currency || ""}
                label="Currency"
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
              >
                <MenuItem value={1}>BD</MenuItem>
                <MenuItem value={2}>US</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Accounts Name/Accounts Info"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              placeholder="Enter Note"
              onChange={(e) => setAccInfo(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{
              mr: 2,
            }}
            type="reset"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mr: 2,
            }}
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default New;
