import { Box, Button, Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import swal from "sweetalert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};
const DefaultGatewayModal = ({ open, handleClose, settingItem }) => {
  const handleConfirm = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", accn_id);
    formData.append("phone_id", settingItem?.phone_id);

    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/new_default_number.php`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.[0]?.resultState) {
          swal({
            title: result?.[0]?.resultState,
            text: result?.[0]?.resultMessage,
            icon: result?.[0]?.result,
            button: "Done",
          });

          handleClose();
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={3} alignItems="center" justifyContent={"center"}>
            <Typography variant="p">
              Do you want to make it Default Gateway?
            </Typography>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              spacing={2}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  bgcolor: "gray",
                  textTransform: "capitalize",
                }}
                onClick={handleClose}
              >
                Cancle
              </Button>
              <Button
                variant="contained"
                size="small"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default DefaultGatewayModal;
