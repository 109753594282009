import { HighlightOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RightSide from "./RightSide";
const ListButton = ({
  render,
  setRender,
  accountNumber,
  botResponse,
  setTemplate,
  setConversation,
  referState,
  listReplyBtn,
  setListReplyBtn,
  setAddNewBtn,
  selectedReplyBtnInfo,
  setSelectedReplyBtnInfo,
  selectedCustomer,
  selectedTemplate,
  setSelectedTemplate,
  setSelectedBtn,
  setDropDownBtn,
  handleBotResponse,
}) => {
  const [inputField, setInputField] = useState({});
  const [ListButtonInfo, setListButtonInfo] = useState({});
  console.log("ListButtonInfo:", ListButtonInfo);
  const [milestoneFields, setMilestoneFields] = useState([{ id: 0 }]);

  const focus = useRef(null);
  const focus2 = useRef(null);
  const focus1 = useRef(null);
  const focus3 = useRef(null);
  const focus4 = useRef(null);
  const focus5 = useRef(null);
  const btn1 = useRef(null);
  const btn2 = useRef(null);
  const btn3 = useRef(null);
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "Name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "language") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "category") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
      setRender(!render);
    }

    if (isFieldValid) {
      if (event.target.name === "item_title") {
        event.target.value = event.target.value.replace(" ", "_");
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newListButtonInfo = ListButtonInfo;
        newListButtonInfo[event.target.name] = event.target.value;
        setListButtonInfo({ ...newListButtonInfo });
      } else {
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newListButtonInfo = ListButtonInfo;
        newListButtonInfo[event.target.name] = event.target.value;
        setListButtonInfo({ ...newListButtonInfo });
      }
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      setListButtonInfo(newField);
    }
  };

  const handleMilestoneFormChange = (index, event) => {
    if (event.target.name == "subtitle") {
      event.target.name.replace("/", "-");
      let data = [...milestoneFields];
      data[index][event.target.name] = event.target.value;
      data[index].id = index;
      setMilestoneFields(data);
      let newListButtonInfo = ListButtonInfo;
      newListButtonInfo.buttonValue = milestoneFields;
      setListButtonInfo({ ...newListButtonInfo });
    } else {
      let data = [...milestoneFields];
      data[index][event.target.name] = event.target.value;
      data[index].id = index;
      setMilestoneFields(data);
      let newListButtonInfo = ListButtonInfo;
      newListButtonInfo.buttonValue = milestoneFields;
      setListButtonInfo({ ...newListButtonInfo });
    }
  };
  const addMilestoneFields = () => {
    let id = Math.floor(Math.random() * 1000000000 + 1);
    let newfield = { id: id };
    setMilestoneFields([...milestoneFields, newfield]);
  };
  const handleDeleteMS = (id) => {
    let filteredField = milestoneFields.filter((element) => element.id != id);
    setMilestoneFields([...filteredField]);
  };

  const addNewTemplate = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    let updateInfo = ListButtonInfo;
    updateInfo.refer = parsedObject?.accn_id;
    updateInfo.poster = parsedObject?.poster;
    updateInfo.item_name = selectedCustomer?.Name;
    updateInfo.template_name = selectedReplyBtnInfo?.tem_name;

    updateInfo.button_value = selectedReplyBtnInfo?.btn_value;
    updateInfo.button_type = selectedReplyBtnInfo?.btn_type;

    const formData = new FormData();
    formData.append("panel_idc", accountNumber);
    formData.append("template_name", selectedReplyBtnInfo?.tem_name);
    formData.append("new_systemplate", JSON.stringify(updateInfo));

    fetch(
      "https://whatsapp.ghorami.com/api/chatbot_api/fun_wm_button_list_new.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.errTyp === "success") {
          setRender(!render);
          //   handleClick();
          //   setAddNewBtn(false);
        }
      })
      .catch((error) => {
        console.log("messagePost=> ", error);
      });
  };
  const clear = () => {
    setListButtonInfo({});
    setInputField({});
    // setmilestoneFields([{ id: 0 }]);
    focus.current.children[0].value = "";
    focus1.current.children[1].value = "";
    focus2.current.children[1].value = "";
    // focus2.current.removeChild(focus2.current.children[0]);
    focus4.current.children[0].value = "";
    btn1.current.children[0].value = "";
    btn2.current.children[0].value = "";
    btn3.current.children[0].value = "";
    focus5.current.value = "";
    console.log("focus.current:", focus2.current.children[1]);
  };
  return (
    <Box sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </Typography>
                  {inputField.fieldErrorAlert === "Name" && (
                    <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                      *Name Field missing
                    </small>
                  )}
                </Stack>

                <OutlinedInput
                  type="text"
                  name="Name"
                  size="small"
                  placeholder="Name"
                  // value={ListButtonInfo?.Name}
                  sx={{
                    width: "100%",

                    borderRadius: "6px",

                    fontSize: "11pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Language
                  </Typography>
                </Stack>

                <Select
                  name="language"
                  onChange={handleChange}
                  displayEmpty
                  size="small"
                  sx={{
                    width: "100%",

                    borderRadius: "6px",

                    fontSize: "9pt",
                  }}
                >
                  <MenuItem value={""}></MenuItem>
                  <MenuItem value={"en_US"}>English</MenuItem>
                  <MenuItem value={"bn_BD"}>Bangla</MenuItem>
                  <MenuItem value={"ar_UAE"}>Arabic</MenuItem>
                </Select>
                {inputField.fieldErrorAlert === "language" && (
                  <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                    *Header Field missing
                  </small>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Category
                  </Typography>
                  {inputField.fieldErrorAlert === "category" && (
                    <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                      *Category Field missing
                    </small>
                  )}
                </Stack>
                <OutlinedInput
                  type="text"
                  name="category"
                  size="small"
                  placeholder="Category"
                  // value={ListButtonInfo?.Name}
                  sx={{
                    width: "100%",

                    borderRadius: "6px",

                    fontSize: "9pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            <Button
              onClick={addMilestoneFields}
              size="small"
              sx={{
                marginLeft: "10px",
                marginTop: "10px",
                textTransform: "capitalize",
              }}
            >
              Add Value
            </Button>
            {milestoneFields?.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"flex-start"}
                    sx={
                      {
                        //   padding: "10px",
                      }
                    }
                  >
                    <Stack spacing={0.5} sx={{ width: "50%" }}>
                      <Stack
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "11pt",
                            fontWeight: 500,
                          }}
                        >
                          Title
                        </Typography>
                        {/* {inputField.fieldErrorAlert === "budget" && (
                                <small style={{ color: "#CB0101" }}>
                                  *Enter budget
                                </small>
                              )} */}
                      </Stack>
                      <OutlinedInput
                        name="title"
                        type="text"
                        size="small"
                        placeholder="Enter title"
                        sx={{
                          width: "100%",
                          borderRadius: "6px",
                          fontSize: "10pt",
                        }}
                        onChange={(event) =>
                          handleMilestoneFormChange(index, event)
                        }
                      />
                    </Stack>
                    <Stack spacing={0.5} sx={{ width: "50%" }}>
                      <Stack
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems={"flex-start"}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "11pt",
                            fontWeight: 500,
                          }}
                        >
                          Value
                        </Typography>
                        {/* <IconButton aria-label="delete" size="small"> */}
                        <HighlightOff
                          fontSize="inherit"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleDeleteMS(input.id);
                          }}
                        />
                        {/* </IconButton> */}

                        {/* {inputField.fieldErrorAlert === "budget" && (
                                <small style={{ color: "#CB0101" }}>
                                  *Enter milestone details
                                </small>
                              )} */}
                      </Stack>

                      <OutlinedInput
                        name="value"
                        type="text"
                        size="small"
                        value={input.value}
                        placeholder="Enter your question"
                        sx={{
                          width: "100%",
                          borderRadius: "6px",
                          fontSize: "10pt",
                        }}
                        onChange={(event) =>
                          handleMilestoneFormChange(index, event)
                        }
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "11pt",
                        fontWeight: 500,
                        marginTop: "8px",
                      }}
                    >
                      Sub-title
                    </Typography>
                    {/* {inputField.fieldErrorAlert === "budget" && (
                                <small style={{ color: "#CB0101" }}>
                                  *Enter budget
                                </small>
                              )} */}
                  </Stack>
                  <OutlinedInput
                    name="subtitle"
                    type="text"
                    size="small"
                    value={input.subtitle}
                    placeholder="Enter your question"
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "10pt",
                    }}
                    onChange={(event) =>
                      handleMilestoneFormChange(index, event)
                    }
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                alignItems={"center"}
                justifyContent="right"
              >
                <Button
                  variant="outlined"
                  color="info"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    // clear();
                    // // setAddNewBtn(false);
                    setTemplate(true);
                    setListReplyBtn(false);
                    setConversation(false);
                    setSelectedTemplate(false);
                    setSelectedBtn(false);
                    setDropDownBtn(false);
                    handleBotResponse();
                  }}
                >
                  Back
                </Button>

                {inputField.language &&
                inputField.category &&
                inputField.Name &&
                milestoneFields[0]?.title &&
                milestoneFields[0]?.subtitle &&
                milestoneFields[0]?.value ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      addNewTemplate();
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      setAddNewBtn(false);
                    }}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <RightSide
              render={render}
              selectedCustomer={selectedCustomer}
              selectedReplyBtnInfo={selectedReplyBtnInfo}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListButton;
