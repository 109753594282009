import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Wallet from "./Wallet/Wallet";
import Card from "./Card/Card";
import International from "./International/International";
import { MdCancel } from "react-icons/md";
import { Stack } from "@mui/system";
import {
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  AccountBalanceWallet,
  ArrowBackIos,
  ArrowRightAlt,
  Clear,
} from "@mui/icons-material";
import swal from "sweetalert";
import { useEffect } from "react";

const PaymentMethodCashInModal = ({
  handleClose,
  open1,
  open2,
  amount,
  currency,
  note,
  setselectedMethod,
  selectedMethod,
  trnxId,
  newBalance,
  selectedCurrency,
  dueAmount,
  setPayOrReq,
  payOrReq,
  accountNumber,
  gateWay,
}) => {
  console.log("currency666:", currency);
  console.log("accountNumber666:", accountNumber);
  console.log("selectedCurrenckyuy:", selectedCurrency);
  console.log("selectedMethod:", selectedMethod);
  const [toggleDisplay, setToggleDisplay] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [component, setComponent] = useState("");
  const [accountName, setAccountName] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("BDT");
  const handleCurrentCurrencyChange = (e) => {
    setCurrentCurrency(e.target.value);
  };
  console.log("payAmount:", payAmount);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
  };

  useEffect(() => {
    setCurrentCurrency(payOrReq ? currency : dueAmount.currency);
  }, [currency, dueAmount, payOrReq]);
  useEffect(() => {
    setComponent(currentCurrency === "BDT" ? "Wallet" : "International");
  }, [currentCurrency]);
  useEffect(() => {
    let found = gateWay?.find(
      (element) => element?.temp_refer === accountNumber
    );
    setAccountName(found?.busin_name?.replace(" ", "")?.slice(0, 7));
  }, [gateWay?.[0]?.id]);

  const afterHit = (e) => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    const formData = new FormData();

    let name = parsedObject?.name;
    let phone = parsedObject?.MobileNumber;
    let address = "dhaka";
    let email = parsedObject?.email;

    const form = document.createElement("form");
    form.method = "POST";
    if (selectedMethod?.name === "Nagad") {
      form.action = "https://yeapbe.com/ghpay/nagad/index.php";
      // console.log("singleHitted");
      // Sample 1
      const hiddenFieldSample1 = document.createElement("input");
      hiddenFieldSample1.type = "hidden";
      hiddenFieldSample1.name = "cname";
      hiddenFieldSample1.value = accountName;
      form.appendChild(hiddenFieldSample1);

      // Sample 2
      const hiddenFieldSample2 = document.createElement("input");
      hiddenFieldSample2.type = "hidden";
      hiddenFieldSample2.name = "pamount";
      hiddenFieldSample2.value = payOrReq ? amount : payAmount;
      form.appendChild(hiddenFieldSample2);
      // Sample 2

      const hiddenFieldSample3 = document.createElement("input");
      hiddenFieldSample3.type = "hidden";
      hiddenFieldSample3.name = "order_id";
      hiddenFieldSample3.value = Math.round(Math.random() * 1000000000 + 1);
      form.appendChild(hiddenFieldSample3);

      // Sample 2
      const hiddenFieldSample4 = document.createElement("input");
      hiddenFieldSample4.type = "hidden";
      hiddenFieldSample4.name = "organization";
      hiddenFieldSample4.value = "routeMessage";
      form.appendChild(hiddenFieldSample4);
    }
    if (
      selectedMethod?.name == "mastercard" ||
      selectedMethod?.name == "nexus" ||
      selectedMethod?.name == "dbdebit" ||
      selectedMethod?.name == "visa" ||
      selectedMethod?.name == "ghorami"
      // paymentType != "cash"
    ) {
      form.action = "https://stripe.ghorami.com/pay";
      console.log("doubleHitted");
      // Sample 1
      const hiddenFieldSample1 = document.createElement("input");
      hiddenFieldSample1.type = "hidden";
      hiddenFieldSample1.name = "name";
      hiddenFieldSample1.value = name;
      form.appendChild(hiddenFieldSample1);

      // Sample 2
      const hiddenFieldSample2 = document.createElement("input");
      hiddenFieldSample2.type = "hidden";
      hiddenFieldSample2.name = "phone";
      hiddenFieldSample2.value = phone;
      form.appendChild(hiddenFieldSample2);
      // Sample 2
      const hiddenFieldSample3 = document.createElement("input");
      hiddenFieldSample3.type = "hidden";
      hiddenFieldSample3.name = "amount";
      hiddenFieldSample3.value = payOrReq ? amount : payAmount;
      form.appendChild(hiddenFieldSample3);
      // Sample 2
      const hiddenFieldSample4 = document.createElement("input");
      hiddenFieldSample4.type = "hidden";
      hiddenFieldSample4.name = "currency";
      hiddenFieldSample4.value = currentCurrency;
      form.appendChild(hiddenFieldSample4);
      // Sample 2
      const hiddenFieldSample5 = document.createElement("input");
      hiddenFieldSample5.type = "hidden";
      hiddenFieldSample5.name = "address";
      hiddenFieldSample5.value = address;
      form.appendChild(hiddenFieldSample5);
      // Sample 2
      const hiddenFieldSample6 = document.createElement("input");
      hiddenFieldSample6.type = "hidden";
      hiddenFieldSample6.name = "email";
      hiddenFieldSample6.value = email;
      form.appendChild(hiddenFieldSample6);
    }
    if (selectedMethod?.name === "paypal") {
      form.action = "https://paypal.ghorami.com/products/buyProduct";
      console.log("doubleHitted");
      // Sample 1
      const hiddenFieldSample1 = document.createElement("input");
      hiddenFieldSample1.type = "hidden";
      hiddenFieldSample1.name = "cus_name";
      // hiddenFieldSample1.value = "ntest";
      hiddenFieldSample1.value = name;
      form.appendChild(hiddenFieldSample1);

      // Sample 2
      const hiddenFieldSample2 = document.createElement("input");
      hiddenFieldSample2.type = "hidden";
      hiddenFieldSample2.name = "item_name";
      hiddenFieldSample2.value = "whatsapp";
      // hiddenFieldSample2.value = phone;
      form.appendChild(hiddenFieldSample2);
      // Sample 2
      const hiddenFieldSample3 = document.createElement("input");
      hiddenFieldSample3.type = "hidden";
      hiddenFieldSample3.name = "amount";
      // hiddenFieldSample3.value = "45";
      hiddenFieldSample3.value = payOrReq ? amount : payAmount;
      form.appendChild(hiddenFieldSample3);
      // Sample 2
      const hiddenFieldSample4 = document.createElement("input");
      hiddenFieldSample4.type = "hidden";
      hiddenFieldSample4.name = "currency";
      hiddenFieldSample4.value = "USD";
      // hiddenFieldSample4.value = currentCurrency;
      form.appendChild(hiddenFieldSample4);
      // Sample 2
      const hiddenFieldSample5 = document.createElement("input");
      hiddenFieldSample5.type = "hidden";
      hiddenFieldSample5.name = "orgranization";
      hiddenFieldSample5.value = "route";
      form.appendChild(hiddenFieldSample5);
      // Sample 2
      const hiddenFieldSample6 = document.createElement("input");
      hiddenFieldSample6.type = "hidden";
      hiddenFieldSample6.name = "cus_email";
      // hiddenFieldSample6.value = "tpu@ghorami.com";
      hiddenFieldSample6.value = email;
      form.appendChild(hiddenFieldSample6);

      const hiddenFieldSample7 = document.createElement("input");
      hiddenFieldSample7.type = "hidden";
      hiddenFieldSample7.name = "order_id";
      hiddenFieldSample7.value = Math.round(Math.random() * 1000000000 + 1);
      form.appendChild(hiddenFieldSample7);
    }

    document.body.appendChild(form);
    form.submit();
  };
  const handlePayAmountChange = (e) => {
    setPayAmount(e.target.value);
  };
  const handleConfirm = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", parsedObject?.accn_id);
    formData.append("amount", payOrReq ? amount : payAmount);
    formData.append("currency", currentCurrency);
    formData.append(
      "trnx_id",
      payOrReq ? trnxId : Math.round(Math.random() * 1000000000)
    );
    formData.append("pay_method", selectedMethod.name);
    console.log("hittedFast");

    fetch(`https://whatsapp.ghorami.com/api/billing_api/neb_bal_confirm.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resultc:", result);
        setToggleDisplay(false);
        handleClose();
        setPayOrReq(false);
        afterHit();
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open2}>
          <Box
            sx={[
              {
                ...style,
                "@media (max-width:768px)": {
                  width: "98%",
                },
              },
            ]}
          >
            {!toggleDisplay ? (
              <>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack spacing={1} mb={2}>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 700 }}
                    >
                      Amount to pay
                    </Typography>

                    {payOrReq == true ? (
                      <Box
                        sx={{
                          padding: "5px",
                          border: "1px solid gray",
                          width: "242px",
                          borderRadius: "4px",
                        }}
                      >
                        <Stack>
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "8pt",
                              fontWeight: 700,
                              color: "gray",
                            }}
                          >
                            Amount to pay
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{ fontSize: "9pt", fontWeight: 700 }}
                          >
                            {currency} {amount}
                          </Typography>
                        </Stack>
                      </Box>
                    ) : (
                      <Stack
                        spacing={2}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          sx={{
                            padding: "5px",
                            border: "1px solid #d1cfcf",
                            width: "100%",
                            borderRadius: "4px",
                          }}
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{
                                fontSize: "8pt",
                                fontWeight: 700,
                                color: "gray",
                              }}
                            >
                              Total amount due
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "9pt", fontWeight: 700 }}
                            >
                              {currentCurrency} {dueAmount?.amount}
                            </Typography>{" "}
                          </Stack>
                        </Box>

                        <OutlinedInput
                          placeholder="Pay amount"
                          onChange={handlePayAmountChange}
                          type={"number"}
                          name={"paying_amount"}
                          sx={{ width: "100%", bgcolor: "white" }}
                        />
                        <Select
                          name="template"
                          size="small"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={currentCurrency}
                          onChange={handleCurrentCurrencyChange}
                          sx={{ width: "50%" }}
                        >
                          <MenuItem value={"BDT"}>BDT</MenuItem>
                          <MenuItem value={"USD"}>USD</MenuItem>
                          <MenuItem value={"AED"}>AED</MenuItem>
                        </Select>
                      </Stack>
                    )}
                  </Stack>
                  <Box
                    sx={{
                      fontSize: "1.5rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    <MdCancel />
                  </Box>
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      mb: 3,
                    }}
                  >
                    {currentCurrency === "BDT" && (
                      <Button
                        variant="contained"
                        color={component === "Wallet" ? "warning" : "primary"}
                        sx={{
                          mr: 2,
                        }}
                        onClick={() => {
                          setComponent("Wallet");
                        }}
                      >
                        Wallet
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color={component === "Card" ? "warning" : "primary"}
                      sx={{ mr: 2 }}
                      onClick={() => {
                        setComponent("Card");
                      }}
                    >
                      Card
                    </Button>

                    <Button
                      variant="contained"
                      color={
                        component === "International" ? "warning" : "primary"
                      }
                      onClick={() => {
                        setComponent("International");
                      }}
                    >
                      International
                    </Button>
                  </Box>
                </Box>
                <Box>
                  {component === "Wallet" && currentCurrency == "BDT" && (
                    <Wallet
                      amount={payOrReq ? amount : payAmount}
                      note={note}
                      handleClose={handleClose}
                      setToggleDisplay={setToggleDisplay}
                      setselectedMethod={setselectedMethod}
                    />
                  )}
                  {component === "Card" && (
                    <Card
                      amount={payOrReq ? amount : payAmount}
                      note={note}
                      handleClose={handleClose}
                      setToggleDisplay={setToggleDisplay}
                      setselectedMethod={setselectedMethod}
                    />
                  )}
                  {component === "International" && (
                    <International
                      amount={payOrReq ? amount : payAmount}
                      note={note}
                      handleClose={handleClose}
                      setToggleDisplay={setToggleDisplay}
                      setselectedMethod={setselectedMethod}
                      currentCurrency={currentCurrency}
                    />
                  )}
                </Box>
              </>
            ) : (
              <Stack spacing={1}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{ marginTop: "-30px" }}
                    onClick={() => {
                      setToggleDisplay(false);
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                  </IconButton>
                  <Typography variant="p" sx={{ fontWeight: 600 }}>
                    Review your payment
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{ marginTop: "-30px" }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{ paddingX: "40%" }}
                >
                  <img
                    src={selectedMethod.image}
                    alt=""
                    style={{
                      width: "35px",
                      // margin: "0 5px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                  <ArrowRightAlt />
                  <AccountBalanceWallet
                    sx={{ fontSize: "22pt", color: "#0aa5a5" }}
                  />
                </Stack>

                {payOrReq == true ? (
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 600, textAlign: "center" }}
                    >
                      You are paying {currency} {amount}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "12pt", textAlign: "center" }}
                    >
                      You are paying with {selectedMethod.name} and your new
                      balance will be {currentCurrency} {newBalance}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 600, textAlign: "center" }}
                    >
                      You are paying {currency} {payAmount}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "12pt", textAlign: "center" }}
                    >
                      You are paying with {selectedMethod.name} and your new
                      balance will be {currentCurrency}{" "}
                      {parseFloat(dueAmount?.amount) - parseFloat(payAmount)}
                    </Typography>
                  </Stack>
                )}
                <Stack direction={"row"} justifyContent={"right"}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleConfirm}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default PaymentMethodCashInModal;
