import {
  Group,
  Groups,
  Key,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ManageAccounts,
  Menu,
  MoreHoriz,
  MoreVert,
  Notifications,
  Payments,
  PhonelinkSetup,
  RoomPreferences,
  Search,
  Visibility,
  WhatsApp,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Typography,
  Avatar,
  Paper,
  CircularProgress,
  circularProgressClasses,
  TextField,
  OutlinedInput,
  InputAdornment,
  Divider,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import "../../../css/inbox.css";
import CreateNewBotModal from "../../Inbox Components/CreateNewBotModal";
import CustomerCard from "../../Inbox Components/CustomerCard";
import "./css/LeftSide.css";

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      {/* <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            }}
            size={40}
            thickness={4}
            {...props}
            value={100}
          /> */}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const LeftSide = ({ setLeftSideButton, leftSideButton, setAddNewBtn }) => {
  const focus = useRef("");
  const [toggle, setToggle] = useState(true);
  const [categoryHeight, setCategoryHeight] = useState("");
  useEffect(() => {
    handleCategoryHeight(focus.current.offsetHeight);
  }, [toggle]);

  const handleCategoryHeight = (value) => {
    setCategoryHeight(value);
  };
  const handleToggle = (event) => {
    setToggle(!toggle);
  };
  console.log("categoriCategoryHeight:", categoryHeight);

  return (
    <Box>
      <Stack spacing={2} sx={{ padding: "20px", paddingBottom: "0px" }}>
        <Stack sx={{ marginTop: "10px" }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "left" }}
            >
              Settings
            </Typography>

            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{ width: "30%" }}
            >
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  handleToggle();
                }}
              >
                <KeyboardArrowUp
                  sx={{
                    transform: toggle === false ? "rotate(-180deg)" : "",
                    transition: "transform 1s",
                  }}
                />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>

        <Box
          className={toggle === false ? "lowCategoryHeight" : ""}
          sx={{
            height:
              categoryHeight && toggle
                ? `${categoryHeight}px !important`
                : "0px",
            overflow: "hidden",
            transition: "height 1s",
          }}
        >
          <Stack
            spacing={1.5}
            justifyContent={"flex-start"}
            alignItems="flex-start"
            sx={{
              maxHeight: "85vh",
              overflow: "auto",
              // bgcolor: "#dbf0f7",
              paddingBottom: "20px",
            }}
            ref={focus}
          >
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
              className={leftSideButton === "businessS" ? "active" : ""}
              sx={{ marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                setLeftSideButton("businessS");
                setAddNewBtn(false);
              }}
            >
              <RoomPreferences sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Business Settings
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
              className={leftSideButton === "defaultNumS" ? "active" : ""}
              sx={{ marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                setLeftSideButton("defaultNumS");
                setAddNewBtn(false);
              }}
            >
              <PhonelinkSetup sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Default Number Settings
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
              className={leftSideButton === "profileS" ? "active" : ""}
              sx={{ marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                setLeftSideButton("profileS");
                setAddNewBtn(false);
              }}
            >
              <ManageAccounts sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                My Profile
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
              className={leftSideButton === "userS" ? "active" : ""}
              sx={{ marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                setLeftSideButton("userS");
                setAddNewBtn(false);
              }}
            >
              <Group sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Users
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
              className={leftSideButton === "passS" ? "active" : ""}
              sx={{ marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                setLeftSideButton("passS");
                setAddNewBtn(false);
              }}
            >
              <Key sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Password
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
              className={leftSideButton === "billS" ? "active" : ""}
              sx={{ marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                setLeftSideButton("billS");
                setAddNewBtn(false);
              }}
            >
              <Payments sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Billing
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
              className={leftSideButton === "NotifS" ? "active" : ""}
              sx={{ marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                setLeftSideButton("NotifS");
                setAddNewBtn(false);
              }}
            >
              <Notifications sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Notification
              </Typography>
            </Stack>
          </Stack>
          <Divider />
        </Box>
      </Stack>
    </Box>
  );
};

export default LeftSide;
