import {
  AccountBalanceWallet,
  Add,
  Architecture,
  Contacts,
  Group,
  Markunread,
  Receipt,
  RemoveRedEye,
  Synagogue,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";

const DashboardMain = ({ setAddNewBtn }) => {
  const [dbSummery, setDbSummery] = useState({});
  console.log("dbSummery:", dbSummery);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    fetch(
      `https://whatsapp.ghorami.com/api/dashboard_api/get_summery.php?user_id=${parsedObject?.SopnoID}&accn_id=${accn_id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setDbSummery(result);
      });
  }, []);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", 1234);
    formData.append("token", Math.round(Math.random() * 10000000000));

    fetch(`https://whatsapp.ghorami.com/api/billing_api/get_all_billing.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        // setAllBilling(result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);

  return (
    <Stack
      spacing={1}
      sx={{
        bgcolor: "#dbf0f7",
        width: "100%",
        height: "100vh",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="p"
          sx={{ fontSize: "14pt", fontWeight: 600, marginLeft: "12px" }}
        >
          Dashboard
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box>
            <Card sx={{}}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{
                          height: "40px",
                          width: "40px",
                          bgcolor: "#66a1b5",
                        }}
                      >
                        <AccountBalanceWallet sx={{ color: "white" }} />
                      </Stack>
                    </Box>
                    <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
                      <Typography sx={{ fontSize: "11pt" }}>Balance</Typography>
                      <Typography sx={{ fontSize: "20pt" }}>
                        {dbSummery.balance}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider></Divider>
                  <Typography sx={{ fontSize: "10pt" }}>
                    Start from 1 jan 2023
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Card sx={{}}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{
                          height: "40px",
                          width: "40px",
                          bgcolor: "#66a1b5",
                        }}
                      >
                        <Receipt sx={{ color: "white" }} />
                      </Stack>
                    </Box>
                    <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
                      <Typography sx={{ fontSize: "11pt" }}>Bill</Typography>
                      <Typography sx={{ fontSize: "20pt" }}>
                        {dbSummery.bill}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider></Divider>
                  <Typography sx={{ fontSize: "10pt" }}>
                    Start from 1 jan 2023
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Card sx={{}}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{
                          height: "40px",
                          width: "40px",
                          bgcolor: "#66a1b5",
                        }}
                      >
                        <Markunread sx={{ color: "white" }} />
                      </Stack>
                    </Box>
                    <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
                      <Typography sx={{ fontSize: "11pt" }}>
                        Bot message
                      </Typography>
                      <Typography sx={{ fontSize: "20pt" }}>
                        {dbSummery.bot_message}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider></Divider>
                  <Typography sx={{ fontSize: "10pt" }}>
                    Start from 1 jan 2023
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Card sx={{}}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{
                          height: "40px",
                          width: "40px",
                          bgcolor: "#66a1b5",
                        }}
                      >
                        <Architecture sx={{ color: "white" }} />
                      </Stack>
                    </Box>
                    <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
                      <Typography sx={{ fontSize: "11pt" }}>Hit</Typography>
                      <Typography sx={{ fontSize: "20pt" }}>
                        {dbSummery.hit}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider></Divider>
                  <Typography sx={{ fontSize: "10pt" }}>
                    Start from 1 jan 2023
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Card sx={{}}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Box sx={{ width: "25%" }}>
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{
                          height: "40px",
                          width: "40px",
                          bgcolor: "#66a1b5",
                        }}
                      >
                        <Contacts sx={{ color: "white" }} />
                      </Stack>
                    </Box>
                    <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
                      <Typography sx={{ fontSize: "11pt" }}>
                        Phone number
                      </Typography>
                      <Typography sx={{ fontSize: "20pt" }}>
                        {dbSummery.number}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider></Divider>

                  <Button
                    startIcon={<Add sx={{ fontSize: "10pt" }} />}
                    sx={{ fontSize: "10pt", textTransform: "capitalize" }}
                    onClick={() => {
                      setAddNewBtn("number");
                    }}
                  >
                    Add new number
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Card sx={{}}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{
                          height: "40px",
                          width: "40px",
                          bgcolor: "#66a1b5",
                        }}
                      >
                        <Synagogue sx={{ color: "white" }} />
                      </Stack>
                    </Box>
                    <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
                      <Typography sx={{ fontSize: "11pt" }}>
                        Template
                      </Typography>
                      <Typography sx={{ fontSize: "20pt" }}>
                        {dbSummery.template}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider></Divider>
                  {/* <Typography sx={{ fontSize: "10pt" }}>
                    Start from 1 jan 2023
                  </Typography> */}
                  <Button
                    startIcon={<Add sx={{ fontSize: "10pt" }} />}
                    sx={{ fontSize: "10pt", textTransform: "capitalize" }}
                    onClick={() => {
                      setAddNewBtn("template");
                    }}
                  >
                    Add new template
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Card sx={{}}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{
                          height: "40px",
                          width: "40px",
                          bgcolor: "#66a1b5",
                        }}
                      >
                        <Group sx={{ color: "white" }} />
                      </Stack>
                    </Box>
                    <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
                      <Typography sx={{ fontSize: "11pt" }}>User</Typography>
                      <Typography sx={{ fontSize: "20pt" }}>
                        {dbSummery.user}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider></Divider>
                  {/* <Typography sx={{ fontSize: "10pt" }}>
                    Start from 1 jan 2023
                  </Typography> */}
                  <Button
                    startIcon={<Add sx={{ fontSize: "10pt" }} />}
                    sx={{ fontSize: "10pt", textTransform: "capitalize" }}
                    onClick={() => {
                      setAddNewBtn("user");
                    }}
                  >
                    Add new user
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DashboardMain;
