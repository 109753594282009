import { WhatsApp } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 3,
    top: 21,
    // bgcolor: "darkgreen",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "3px",
    borderRadius: "50%",
  },
}));

const CustomerCard = ({
  customer,
  handleSelectedCustomer,
  handleCustomerResponse,
  setConversation,
  setNewContactBtn,
  setTemplate,
  handleBotResponse,
}) => {
  //   const { Name, states, timeStamp, userId } = customer;
  return (
    <Paper
      sx={{ padding: "15px", marginBottom: "10px", cursor: "pointer" }}
      onClick={() => {
        handleSelectedCustomer(customer);
        handleCustomerResponse(customer?.MobileNumber);
        setConversation(true);
        setNewContactBtn(false);
        setTemplate(false);
      }}
    >
      <Stack
        spacing={1}
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        // sx={{ marginTop: "10px" }}
      >
        <Typography variant="p" sx={{ fontSize: "8pt", fontWeight: 600 }}>
          conversation {"#" + customer?.userId}
        </Typography>
        <Stack direction={"row"} justifyContent="right" alignItems="center">
          <IconButton size="small" color="success">
            <WhatsApp sx={{ fontSize: "14pt" }} />
          </IconButton>
          {customer?.states == 1 ? (
            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{
                paddingX: "12px",
                paddingY: "1px",
                // bgcolor: "darkgreen",

                border: "1px solid darkgreen",
                borderRadius: "12px",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "7pt",
                  fontWeight: 700,
                  // color: "white",
                  color: "#1b5e20",
                  marginTop: "-1px",
                }}
              >
                LIVE
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{
                paddingX: "12px",
                paddingY: "1px",
                // bgcolor: "darkgreen",

                border: "1px solid #c50808",
                borderRadius: "12px",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "7pt",
                  fontWeight: 700,
                  // color: "white",
                  color: "#c50808",
                  marginTop: "-1px",
                }}
              >
                LIVE
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        spacing={0.5}
        direction={"row"}
        justifyContent="space-between"
        alignItems="flex-start"
        // sx={{ marginTop: "10px" }}
      >
        <Stack
          spacing={0.5}
          direction={"row"}
          justifyContent="left"
          alignItems="center"
          // sx={{ marginTop: "10px" }}
        >
          <StyledBadge badgeContent={""} color="success" variant="dot">
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: "25px", height: "25px" }}
            />
          </StyledBadge>

          <Stack
            justifyContent="left"
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <Stack alignItems={"flex-start"}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "8pt",
                  fontWeight: 700,
                  lineHeight: "8px",
                }}
              >
                {customer?.Name.length > 13
                  ? customer?.Name.slice(0, 13) + "..."
                  : customer?.Name}
              </Typography>

              <Typography variant="p" sx={{ fontSize: "8pt", fontWeight: 600 }}>
                online
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography
          variant="p"
          sx={{
            fontSize: "8pt",
            fontWeight: 600,
            lineHeight: "8px",
          }}
        >
          {customer?.timeStamp.split(" ")[0]}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default CustomerCard;
