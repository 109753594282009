import { Box } from "@mui/material";
import React from "react";
import WhatsApp from "../Components/Message/WhatsApp";

const Home = () => {
  return (
    <Box>
      <WhatsApp />
    </Box>
  );
};

export default Home;
