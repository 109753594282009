import { Reply } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import DeleteTemplateModal from "../List Button/Template Type forms/DeleteTemplateModal";
import OneConversation from "./TemplateListBoxComponents/OneConversation";

const TemplateListBox = ({
  template,
  setAddNewBtn,
  setSelectedTemplate,
  selectedTemplate,
  botResponse,
  setTemplate,
  setConversation,
  referState,
  listReplyBtn,
  setListReplyBtn,
  setSelectedReplyBtnInfo,
  selectedCustomer,
  dropDownBtn,
  setDropDownBtn,
  selectedBtn,
  setSelectedBtn,
  handleBotResponse,
}) => {
  console.log("botResponseeee:", botResponse);
  console.log("selectedTemplate:", selectedTemplate);
  console.log("selectedCustomer:", selectedCustomer);

  console.log("selectedBtn:", selectedBtn);
  const [open, setOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  console.log("deleteItem:", deleteItem);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <DeleteTemplateModal
        open={open}
        handleClose={handleClose}
        // handleClick={handleClick}
        deleteItem={deleteItem}
        selectedCustomer={selectedCustomer}
        selectedBtn={selectedBtn}
        handleBotResponse={handleBotResponse}
      />

      {botResponse?.map((element) => (
        <OneConversation
          eachItem={element}
          setAddNewBtn={setAddNewBtn}
          template={template}
          // conversation={conversation}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          // botResponse={templateList}
          referState={referState}
          setTemplate={setTemplate}
          setConversation={setConversation}
          listReplyBtn={listReplyBtn}
          setListReplyBtn={setListReplyBtn}
          // selectedReplyBtnInfo={selectedReplyBtnInfo}
          setSelectedReplyBtnInfo={setSelectedReplyBtnInfo}
          selectedCustomer={selectedCustomer}
          dropDownBtn={dropDownBtn}
          setDropDownBtn={setDropDownBtn}
          selectedBtn={selectedBtn}
          setSelectedBtn={setSelectedBtn}
          handleBotResponse={handleBotResponse}
          handleOpen={handleOpen}
          setDeleteItem={setDeleteItem}
        />
      ))}
    </Box>
  );
};

export default TemplateListBox;
