import {
  Cancel,
  Group,
  Groups,
  Key,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ManageAccounts,
  Menu,
  MoreHoriz,
  MoreVert,
  Notifications,
  Payments,
  RoomPreferences,
  Search,
  Visibility,
  WhatsApp,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Typography,
  Avatar,
  Paper,
  CircularProgress,
  circularProgressClasses,
  TextField,
  OutlinedInput,
  InputAdornment,
  ListItemText,
  ListItem,
  List,
  Collapse,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import "../../../css/inbox.css";
import CreateNewBotModal from "../../Inbox Components/CreateNewBotModal";
import CustomerCard from "../../Inbox Components/CustomerCard";
import { category } from "./CategoryListData";
import "./css/LeftSide.css";

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      {/* <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            }}
            size={40}
            thickness={4}
            {...props}
            value={100}
          /> */}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const LeftSideDev = ({ setLeftSideButton, leftSideButton, setAddNewBtn }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toggle, setToggle] = useState(true);
  const [subCategoryData, setSubCategoryData] = useState([]);
  console.log("subCategoryData:", subCategoryData);
  console.log("toggle:", toggle);

  const [categoryHeight, setCategoryHeight] = useState("");
  console.log("categoryHeight:", categoryHeight);

  const focus = useRef("");

  const handleCategoryHeight = (value) => {
    setCategoryHeight(value);
  };
  const handleOffsetHeight = () => {
    handleCategoryHeight(focus.current.offsetHeight);
  };
  useEffect(() => {
    handleCategoryHeight(focus.current.offsetHeight);
  }, [subCategoryData?.[0], open]);
  return (
    <Box>
      {/* <FacebookCircularProgress /> */}

      <Stack
        spacing={2}
        sx={{ padding: "20px", paddingBottom: "0px", boxSizing: "border-box" }}
      >
        <Stack sx={{ marginTop: "10px", boxSizing: "border-box" }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", boxSizing: "border-box" }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "left" }}
            >
              Developer
            </Typography>

            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{ width: "30%" }}
            >
              <IconButton aria-label="delete" size="small">
                <KeyboardArrowUp />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          spacing={1.5}
          justifyContent={"flex-start"}
          alignItems="flex-start"
          sx={{
            marginTop: "10px",
            height: "85vh",
            overflow: "auto",
            boxSizing: "border-box",
          }}
        >
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent="left"
            alignItems="center"
            className={leftSideButton === "GatewayS" ? "active" : ""}
            sx={{ marginTop: "10px", cursor: "pointer" }}
            onClick={() => {
              setLeftSideButton("GatewayS");
              setAddNewBtn(false);
            }}
          >
            <RoomPreferences sx={{ fontSize: "14pt" }} />

            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
              Gateway Settings
            </Typography>
          </Stack>
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            className={leftSideButton === "API" ? "active" : ""}
            sx={{
              marginTop: "10px",
              cursor: "pointer",
              width: "100%",
              boxSizing: "border-box",
            }}
            onClick={() => {
              setLeftSideButton("API");
              setAddNewBtn(false);
            }}
          >
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems="center"
            >
              <ManageAccounts sx={{ fontSize: "14pt" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                API
              </Typography>
            </Stack>
            <IconButton
              aria-label="comment"
              size="small"
              sx={{}}
              variant="outlined"
              onClick={() => {
                // handleSubCategory(value?.category_id);
                setToggle(!toggle);
              }}
            >
              <KeyboardArrowDown
                fontSize="inherit"
                color="black"
                sx={{
                  // transform:
                  //   open === value?.category_id && open != false
                  //     ? "rotate(-180deg)"
                  //     : "",
                  transition: "transform 1s",
                }}
              />
            </IconButton>
          </Stack>
          <Box
            // className={toggle === false ? "lowCategoryHeight" : ""}
            sx={{
              paddingLeft: "15px",
              width: "100%",
              boxSizing: "border-box",
              height:
                categoryHeight && toggle
                  ? `${categoryHeight}px !important`
                  : "0px",
              overflow: "hidden",
              transition: "height 1s",
              // bgcolor: "red",
            }}
          >
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                boxSizing: "border-box",
              }}
              ref={focus}
            >
              {category?.map((value) => (
                <>
                  <ListItem
                    key={value.title}
                    disableGutters
                    sx={{
                      padding: 0,
                      cursor: "pointer",
                      boxSizing: "border-box",
                    }}
                    secondaryAction={
                      <IconButton
                        aria-label="comment"
                        size="small"
                        sx={{}}
                        variant="outlined"
                        onClick={() => {
                          // handleSubCategory(value?.category_id);
                          // if (!open) {
                          setSubCategoryData(value?.sub_category);
                          setOpen(value?.category_id);
                          // } else {
                          //   setOpen(false);
                          // }
                        }}
                      >
                        <KeyboardArrowDown
                          fontSize="inherit"
                          color="black"
                          sx={{
                            transform:
                              open === value?.category_id && open != false
                                ? "rotate(-180deg)"
                                : "",
                            transition: "transform .5s",
                          }}
                        />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: 500,
                            fontSize: "12pt",
                          }}
                        >
                          {value.category_name}
                        </Typography>
                      }
                      sx={{}}
                      onClick={() => {
                        // handleSubCategory(value?.category_id);
                      }}
                    />
                  </ListItem>
                  <Collapse
                    in={open === value?.category_id}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      boxSizing: "border-box",
                    }}
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        boxSizing: "border-box",
                      }}
                    >
                      {subCategoryData?.map((sub) => (
                        <ListItem
                          key={sub?.subcategory_id}
                          disableGutters
                          sx={{
                            padding: 0,
                            cursor: "pointer",
                            boxSizing: "border-box",
                            width: "100%",
                          }}
                          onClick={() => {
                            setLeftSideButton(sub?.subcategory_name);
                            setAddNewBtn(false);
                          }}
                          secondaryAction={
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                marginRight: "0px",
                                padding: "0px",
                                borderRadius: "12px",
                                minWidth: "40px",
                                bgcolor:
                                  sub?.post_method === "POST"
                                    ? "#085B48"
                                    : "#3c95ed",
                              }}
                            >
                              <Typography
                                variant="p"
                                sx={{
                                  fontSize: "8pt",
                                  fontWeight: 600,
                                }}
                              >
                                {sub?.post_method}
                              </Typography>
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="p"
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "12pt",
                                }}
                              >
                                {sub?.subcategory_name}
                              </Typography>
                            }
                            sx={{ paddingLeft: "15px", paddingRight: "10px" }}
                            onClick={() => {
                              // handleSubCatItems(sub?.subcategory_id);
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              ))}
            </List>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LeftSideDev;
