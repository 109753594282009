import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const TemplateMessageFormCampaign = ({
  templateMessageCampaignInfo,
  setTemplateMessageCampaignInfo,
  handleTemplateMessageSave,
  setAddNewBtn,
}) => {
  const [inputField, setInputField] = useState({});
  const [buttonFields, setButtonFields] = useState([{ id: 0 }]);

  const handleFormChange = (index, event) => {
    let data = [...buttonFields];
    data[index][event.target.name] = event.target.value;
    data[index].id = index;
    setButtonFields(data);
    let newtemplateMessageCampaignInfo = templateMessageCampaignInfo;
    newtemplateMessageCampaignInfo.buttons = buttonFields;
    setTemplateMessageCampaignInfo({ ...newtemplateMessageCampaignInfo });
  };
  const addButtonFields = () => {
    let id = Math.floor(Math.random() * 1000000000 + 1);
    let newField = { id: id };
    if (buttonFields.length <= 2) {
      setButtonFields([...buttonFields, newField]);
    } else {
      setButtonFields([...buttonFields]);
    }
  };

  const handleDeleteButtonFields = (id) => {
    let filteredField = buttonFields.filter((element) => element.id != id);
    setButtonFields([...filteredField]);
  };

  const clear = () => {
    setTemplateMessageCampaignInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "header") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "template_title") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "details") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newtemplateMessageCampaignInfo = templateMessageCampaignInfo;
      newtemplateMessageCampaignInfo[event.target.name] = event.target.value;
      setTemplateMessageCampaignInfo({ ...newtemplateMessageCampaignInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box sx={{ paddingY: "15px", paddingX: "20px" }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Header
              </Typography>
              {inputField.fieldErrorAlert === "header" && (
                <small style={{ color: "#CB0101" }}>
                  *Header Field missing
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="header"
              size="small"
              placeholder="Header"
              sx={{
                width: "100%",

                borderRadius: "6px",
                padding: "2px",
                fontSize: "11pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Info/Details
              </Typography>
              {inputField.fieldErrorAlert === "details" && (
                <small style={{ color: "#CB0101" }}>*Info field missing</small>
              )}
            </Stack>

            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter about your Service"
              // value={about}
              name="details"
              className="customTextField"
              style={{
                fontSize: "11pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",

                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Template for
              </Typography>
              {inputField.fieldErrorAlert === "template_title" && (
                <small style={{ color: "#CB0101" }}>
                  *Template title field missing
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="template_title"
              size="small"
              placeholder="Title"
              sx={{
                width: "100%",

                borderRadius: "6px",
                padding: "2px",
                fontSize: "11pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Typography
          variant="p"
          sx={{
            fontSize: "11pt",
            fontWeight: 500,
            marginLeft: "24px",
            marginTop: "8px",
            cursor: "pointer",
          }}
          onClick={addButtonFields}
        >
          Add Button +
        </Typography>
        {buttonFields?.map((input, index) => {
          return (
            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ boxSizing: "border-box" }}
              >
                <OutlinedInput
                  type="text"
                  name="btn_title"
                  size="small"
                  placeholder="Title"
                  sx={{
                    width: "90%",

                    borderRadius: "6px",
                    padding: "2px",
                    fontSize: "11pt",
                    boxSizing: "border-box",
                  }}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <OutlinedInput
                  type="text"
                  name="btn_value"
                  size="small"
                  placeholder="value"
                  sx={{
                    width: "90%",

                    borderRadius: "6px",
                    padding: "2px",
                    fontSize: "11pt",
                    boxSizing: "border-box",
                  }}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    handleDeleteButtonFields(input.id);
                  }}
                >
                  <HighlightOff fontSize="inherit" />
                </IconButton>
              </Stack>
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.header &&
            inputField.template_title &&
            inputField.details &&
            buttonFields[0]?.btn_title &&
            buttonFields[0]?.btn_value ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                  handleTemplateMessageSave();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TemplateMessageFormCampaign;
