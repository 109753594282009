import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import {
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BillingPaymentModal = ({
  handleOpen,
  handleClose,
  open,
  setAddNewBtn,
  paymentInfoInfo,
  setPaymentInfoInfo,
  inputField,
  setInputField,
  handleOpen2,
  setTrnxId,
  setNewBalance,
  setSelectedCurrency,
}) => {
  console.log("paymentInfoInfo:", paymentInfoInfo);

  const clear = () => {
    setPaymentInfoInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "info") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "amount") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }
    if (event.target.name === "currency") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newpaymentInfoInfo = paymentInfoInfo;
      newpaymentInfoInfo[event.target.name] = event.target.value;
      setPaymentInfoInfo({ ...newpaymentInfoInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };

  const handleNewBalanceReqCustomer = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let updateInfo = paymentInfoInfo;
    updateInfo.user_id = parsedObject?.SopnoID;

    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", 1234);
    formData.append("amount", paymentInfoInfo.amount);
    formData.append("currency", paymentInfoInfo.currency);
    formData.append("note", paymentInfoInfo.info);

    fetch(`https://whatsapp.ghorami.com/api/billing_api/new_bal_request.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resultc:", result);
        setTrnxId(result[0]?.trnxId);
        setNewBalance(result[0]?.new_baln);
        setSelectedCurrency(result[0]?.currency);
        handleClose();
        handleOpen2();
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={1}
            sx={{ marginBottom: "20px" }}
          >
            <Grid item xs={8}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Amount
                  </Typography>
                  {inputField.fieldErrorAlert === "amount" && (
                    <small style={{ color: "#CB0101" }}>
                      *Enter item title
                    </small>
                  )}
                </Stack>

                <OutlinedInput
                  type="number"
                  name="amount"
                  size="small"
                  placeholder="Enter amount"
                  sx={{
                    width: "100%",
                    borderRadius: "6px",
                    fontSize: "11pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Currency
                  </Typography>
                </Stack>

                <Select
                  name="currency"
                  size="small"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    width: "100%",
                    borderRadius: "6px",
                    fontSize: "11pt",
                  }}
                >
                  <MenuItem value="">
                    <em>select</em>
                  </MenuItem>
                  <MenuItem value={"BDT"}>BDT</MenuItem>
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"AED"}>AED</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Note
                  </Typography>
                  {inputField.fieldErrorAlert === "info" && (
                    <small style={{ color: "#CB0101" }}>
                      *Info field empty
                    </small>
                  )}
                </Stack>

                <TextareaAutosize
                  type="text"
                  name="info"
                  size="small"
                  placeholder="Write some notes"
                  aria-label="minimum height"
                  minRows={2}
                  className="customTextField"
                  style={{
                    fontSize: "11pt",
                    fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                    width: "100%",
                    maxWidth: "100%",
                    boxSizing: "border-box",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "6px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingTop: "10px",
                    // paddingBottom: "5px",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                alignItems={"center"}
                justifyContent="right"
              >
                <Button
                  variant="outlined"
                  color="info"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    clear();
                    setAddNewBtn(false);
                    handleClose();
                  }}
                >
                  Clear
                </Button>

                {inputField.amount && inputField.info && inputField.currency ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      handleNewBalanceReqCustomer();
                    }}
                  >
                    Send now
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Send now
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default BillingPaymentModal;
