import React from "react";

export const category = [
  {
    category_id: "1",
    category_name: "Message",
    sub_category: [
      {
        subcategory_id: "1",
        subcategory_name: "Chat",
        post_method: "POST",
      },
      {
        subcategory_id: "2",
        subcategory_name: "Document",
        post_method: "POST",
      },
    ],
  },
  {
    category_id: "2",
    category_name: "AutoBot",
    sub_category: [
      {
        subcategory_id: "1",
        subcategory_name: "TemplateApi",
        post_method: "POST",
      },
    ],
  },
  {
    category_id: "3",
    category_name: "Campaign",
    sub_category: [
      {
        subcategory_id: "1",
        subcategory_name: "NewCampaignApi",
        post_method: "POST",
      },
    ],
  },
  {
    category_id: "4",
    category_name: "OTP",
    sub_category: [
      {
        subcategory_id: "1",
        subcategory_name: "NewOTPApi",
        post_method: "POST",
      },
    ],
  },
];
const CategoryListData = () => {
  return <div></div>;
};

export default CategoryListData;
