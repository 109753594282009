import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Divider,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableContainer,
} from "@mui/material";
import {
  AttachFileOutlined,
  CallOutlined,
  Check,
  EmojiEmotionsOutlined,
  ExitToApp,
  KeyboardArrowUp,
  Language,
  MicNoneOutlined,
  MoreHoriz,
  SendOutlined,
} from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import RightBarCampaignTable from "./RightBar Components/RightBarCampaignTable";
import Preview from "./RightBar Components/Preview";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RightBar = ({
  handleToggle,
  selectedCustomer,
  campaignBtn,
  campaignNumber,
  campaignWaitingNumber,
  createCampaignBtn,
  startCampaignInfo,
  inputField,
}) => {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  let phone;
  if (
    selectedCustomer?.MobileNumber &&
    selectedCustomer?.MobileNumber.length < 11
  ) {
    phone = selectedCustomer?.MobileNumber;
  } else {
    phone = selectedCustomer?.MobileNumber;
    // phone = "88" + selectedCustomer?.MobileNumber;
  }

  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Box sx={{ boxSizing: "border-box" }}>
      {!campaignBtn && (
        <>
          <Paper
            elevation={0}
            sx={{
              border: "1px solid gray",
              borderTop: 0,
              borderLeft: 0,
              borderRadius: 0,
              paddingX: "20px",
              paddingY: "15px",
              boxSizing: "border-box",
            }}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 700 }}
              >
                Details
              </Typography>

              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleToggle}
              >
                <ExitToApp fontSize="small" />
              </IconButton>
            </Stack>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              height: "90.8vh",
              paddingX: "20px",
              paddingY: "20px",
              borderRadius: 0,
              border: "1px solid gray",
              borderLeft: 0,
              borderTop: 0,
              borderBottom: 0,
              bgcolor: "#eef0f1",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Box sx={{ width: "45px" }}></Box>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: "45px", height: "45px" }}
                />
                <Box sx={{ width: "45px" }}>
                  <IconButton aria-label="delete" size="small">
                    <MoreHoriz fontSize="small" />
                  </IconButton>
                </Box>
              </Stack>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9.5pt",
                  fontWeight: 700,
                  //   lineHeight: "8px",
                  color: "black",
                }}
              >
                {selectedCustomer?.Name}
              </Typography>
              <Select
                size="small"
                value={age}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  fontSize: "9pt",
                  fontWeight: 700,
                  borderRadius: "30px",
                  paddingY: 0,
                }}
              >
                {/* <MenuItem value="">Contact Info</MenuItem> */}
                <MenuItem value="">Active</MenuItem>
                <MenuItem value={20}>Pause</MenuItem>
                <MenuItem value={30}>Inactive</MenuItem>
              </Select>
            </Stack>

            <Stack alignItems="flex-start" justifyContent="left" spacing={1}>
              {selectedCustomer?.Name && (
                <>
                  <Stack alignItems="flex-start" justifyContent="left">
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 700 }}
                    >
                      Name
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "10pt", fontWeight: 600 }}
                    >
                      {selectedCustomer?.Name}
                    </Typography>
                  </Stack>
                </>
              )}
              {selectedCustomer?.Email && (
                <>
                  <Divider sx={{ width: "100%" }} />
                  <Stack alignItems="flex-start" justifyContent="left">
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 700 }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "10pt", fontWeight: 600 }}
                    >
                      {selectedCustomer?.Email}
                    </Typography>
                  </Stack>
                </>
              )}
              {selectedCustomer?.MobileNumber && (
                <>
                  <Divider sx={{ width: "100%" }} />
                  <Stack alignItems="flex-start" justifyContent="left">
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 700 }}
                    >
                      Phone
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "10pt", fontWeight: 600 }}
                    >
                      {selectedCustomer?.MobileNumber
                        ? phone
                        : selectedCustomer?.MobileNumber}
                    </Typography>
                  </Stack>
                </>
              )}
              {selectedCustomer?.item_info && (
                <>
                  <Divider sx={{ width: "100%" }} />
                  <Stack alignItems="flex-start" justifyContent="left">
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 700 }}
                    >
                      Info
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "10pt", fontWeight: 600 }}
                    >
                      {selectedCustomer?.item_info}
                    </Typography>
                  </Stack>
                </>
              )}
            </Stack>
          </Paper>
        </>
      )}
      {campaignBtn && !createCampaignBtn && (
        <>
          <Paper
            elevation={0}
            sx={{
              border: "1px solid gray",
              borderTop: 0,
              borderLeft: 0,
              borderRadius: 0,
              paddingX: "20px",
              paddingY: "15px",
              boxSizing: "border-box",
            }}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 700 }}
              >
                Details
              </Typography>

              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleToggle}
              >
                <ExitToApp fontSize="small" />
              </IconButton>
            </Stack>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              height: "90.8vh",
              borderRadius: 0,
              border: "1px solid gray",
              borderLeft: 0,
              borderTop: 0,
              borderBottom: 0,
              bgcolor: "#eef0f1",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  sx={{ width: "100%" }}
                >
                  <Tab
                    label="All"
                    {...a11yProps(0)}
                    sx={{ width: "50%", textTransform: "capitalize" }}
                  />
                  <Tab
                    label="Waiting"
                    {...a11yProps(1)}
                    sx={{ width: "50%", textTransform: "capitalize" }}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <RightBarCampaignTable tableData={campaignNumber} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <RightBarCampaignTable tableData={campaignWaitingNumber} />
              </TabPanel>
            </Box>
          </Paper>
        </>
      )}
      {createCampaignBtn && (
        <>
          <Paper
            elevation={0}
            sx={{
              border: "1px solid gray",
              borderTop: 0,
              borderLeft: 0,
              borderRadius: 0,
              paddingX: "20px",
              paddingY: "15px",
              boxSizing: "border-box",
            }}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 700 }}
              >
                Preview
              </Typography>

              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleToggle}
              >
                <ExitToApp fontSize="small" />
              </IconButton>
            </Stack>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              height: "90.8vh",

              borderRadius: 0,
              border: "1px solid gray",
              borderLeft: 0,
              borderTop: 0,
              borderBottom: 0,
              bgcolor: "#eef0f1",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <Preview
              startCampaignInfo={startCampaignInfo}
              inputField={inputField}
            />
          </Paper>
        </>
      )}
    </Box>
  );
};

export default RightBar;
