import { Lightbulb, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import ReqResTab from "../../ReqResTabs/ChatReqResTab";
import "@uiw/react-textarea-code-editor/dist/editor.min.css";
import { HTTP_REQUEST, PHP } from "../../Codes/ChatCodes";

const languages = [
  { name: "PHP", code: PHP },
  { name: "HTTP_REQUEST", code: HTTP_REQUEST },
];

const Chat = () => {
  const [inputField, setInputField] = useState({});
  const [requestData, setRequestData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("PHP");
  console.log("requestData:", requestData);

  const [code, setCode] = useState("");
  useEffect(() => {
    setCode(PHP);
  }, []);
  const handleCode = (item) => {
    setCode(item);
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "body") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "mobile_number") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }
    if (event.target.name === "panel_id") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newrequestData = requestData;
      newrequestData[event.target.name] = event.target.value;
      setRequestData({ ...newrequestData });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      setRequestData(newField);
    }
  };
  return (
    <Stack
      spacing={2}
      //  sx={{ height: "90vh", overflow: "auto" }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"left"}
        sx={{
          width: "100%",
          height: "10vh",
          bgcolor: "white",
          borderRadius: "12px",
          paddingX: "10px",
          boxSizing: "border-box",
        }}
        spacing={2}
      >
        <Lightbulb sx={{ color: "#e9c816" }} />
        <Typography variant="p" sx={{ fontWeight: 600, fontSize: "12pt" }}>
          Send a text message to phone number or group
        </Typography>
      </Stack>
      <Grid container columnSpacing={2}>
        <Grid item xs={6} sx={{ marginLeft: "-16px" }}>
          <Stack sx={{}}>
            <Stack
              sx={{
                bgcolor: "#085B48",
                height: "60px",
                paddingX: "15px",
                color: "white",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              alignItems="flex-start"
              justifyContent={"center"}
            >
              Request Test
            </Stack>
            <Box
              sx={{
                border: "1px solid #085B48",
                height: "auto",
                padding: "15px",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"left"}
                sx={{
                  width: "100%",
                  borderRadius: "12px",
                  padding: "10px",
                  boxSizing: "border-box",
                  bgcolor: "#d1ddd4",
                }}
                spacing={2}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", textAlign: "left" }}
                >
                  <strong>Note :</strong> in case the instance not authorized or
                  phone disconnected from internet , the message will add to
                  queue and will be sent when the WhatsApp instance ready.
                </Typography>
              </Stack>
              {/* <Stack spacing={3} sx={{ marginTop: "15px" }}>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 600, fontSize: "12pt" }}
                  >
                    Panel ID :
                  </Typography>
                  <OutlinedInput
                    placeholder=""
                    size="small"
                    sx={{ width: "60%" }}
                    name="panel_id"
                    type="text"
                    onChange={handleChange}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 600, fontSize: "12pt" }}
                  >
                    To :
                  </Typography>{" "}
                  <OutlinedInput
                    placeholder=""
                    size="small"
                    sx={{ width: "60%" }}
                    name="mobile_number"
                    type="number"
                    onChange={handleChange}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 600, fontSize: "12pt" }}
                  >
                    Body :
                  </Typography>{" "}
                  <OutlinedInput
                    placeholder=""
                    size="small"
                    sx={{ width: "60%" }}
                    name="body"
                    type="text"
                    onChange={handleChange}
                  />
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"right"}
                spacing={2}
              >
                {requestData?.panel_id &&
                requestData?.mobile_number &&
                requestData?.body ? (
                  <Button
                    variant="contained"
                    startIcon={<Send />}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: "11pt",
                      bgcolor: "#085B48",
                    }}
                  >
                    Send
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<Send />}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: "11pt",
                      bgcolor: "#085B48",
                    }}
                    disabled
                  >
                    Send
                  </Button>
                )}
              </Stack> */}
              <Box>
                <ReqResTab />
              </Box>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            sx={{
              bgcolor: "#085B48",
              height: "60px",
              paddingX: "15px",
              color: "white",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
            alignItems="flex-start"
            justifyContent={"center"}
          >
            Request Samples
          </Stack>
          <Stack
            sx={{ padding: "15px" }}
            direction="row"
            alignItems={"center"}
            justifyContent="left"
            spacing={1}
          >
            {languages?.map((element) => (
              <Button
                variant={
                  selectedLanguage === element?.name ? "contained" : "outlined"
                }
                color="info"
                size="small"
                sx={{ fontSize: "10pt", paddingY: "1px" }}
                onClick={() => {
                  setSelectedLanguage(element.name);
                  handleCode(element?.code);
                }}
              >
                {element?.name}
              </Button>
            ))}
          </Stack>
          <Box>
            <CodeEditor
              value={code}
              language="php"
              readOnly
              placeholder="Please enter JS code."
              padding={15}
              style={{
                // backgroundColor: "black",
                fontSize: 12,
                // color: "white",
                backgroundColor: "#f5f5f5",
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Chat;
