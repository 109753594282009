import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { Delete, Download, Settings, Update } from "@mui/icons-material";

const columns = [
  { label: "Phone Id" },
  { label: "App Id" },
  { label: "App Token" },
  { label: "Version" },
  { label: "Item" },
  { label: "Product" },
  { label: "Status" },
  { label: "Setting" },
  { label: "Update" },
  { label: "Delete" },
];

export default function GatewayTable({
  allGateway,
  setLeftSideButton,
  setAddNewBtn,
  setUpdateItem,
  handleOpen,
}) {
  console.log("allGateway:", allGateway);
  const [page, setPage] = useState(0);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  //   };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 488, overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  size="small"
                  align="center"
                  style={{ minWidth: "50px" }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "9pt", fontWeight: 700 }}
                  >
                    {" "}
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allGateway?.map((item) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                  <>
                    <TableCell align="center" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.phone_id ? item.phone_id : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.app_id ? item.app_id : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <abbr
                        title={item.app_token}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.app_token
                            ? item.app_token?.slice(0, 10) +
                              (item.app_token?.length > 10 ? "..." : "")
                            : "-"}
                        </Typography>
                      </abbr>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.version ? item.version : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="center" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.item ? item.item : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.product ? item.product : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.state == 1 ? "active" : "inactive"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <IconButton
                        aria-label="setting"
                        size="small"
                        onClick={() => {
                          handleOpen(item);
                          // setcenterSideButton("update");
                          // setAddNewBtn(false);
                        }}
                      >
                        <Settings fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <IconButton
                        aria-label="update"
                        size="small"
                        onClick={() => {
                          setUpdateItem(item);
                          setLeftSideButton("update");
                          setAddNewBtn(false);
                        }}
                      >
                        <Update fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <IconButton aria-label="delete" size="small">
                        <Delete fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                  </>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={allGateway.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
