import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { Grid, OutlinedInput, Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const CreateNewBotModal = ({
  handleOpen,
  handleClose,
  open,
  setAddNewBtn,
  handleBotData,
}) => {
  const [inputField, setInputField] = useState({});
  const [newBotInfo, setNewBotInfo] = useState({});

  const clear = () => {
    setNewBotInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "info") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "Name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newnewBotInfo = newBotInfo;
      newnewBotInfo[event.target.name] = event.target.value;
      setNewBotInfo({ ...newnewBotInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };

  const handleAddNewCustomer = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let updateInfo = newBotInfo;
    updateInfo.user_id = parsedObject?.SopnoID;

    const formData = new FormData();
    formData.append("newBot", JSON.stringify(updateInfo));

    fetch(`https://whatsapp.ghorami.com/api/chatbot_api/bot_item_new.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resultc:", result);
        handleBotData();
        setAddNewBtn(true);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={1}
            sx={{ marginBottom: "20px" }}
          >
            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Item Title
                  </Typography>
                  {inputField.fieldErrorAlert === "Name" && (
                    <small style={{ color: "#CB0101" }}>
                      *Enter item title
                    </small>
                  )}
                </Stack>

                <OutlinedInput
                  type="text"
                  name="Name"
                  size="small"
                  placeholder="Enter item title"
                  sx={{
                    width: "100%",

                    borderRadius: "6px",
                    fontSize: "11pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 500,
                    }}
                  >
                    Item info
                  </Typography>
                  {inputField.fieldErrorAlert === "Name" && (
                    <small style={{ color: "#CB0101" }}>
                      *Enter Valid name
                    </small>
                  )}
                </Stack>

                <OutlinedInput
                  type="text"
                  name="info"
                  size="small"
                  placeholder="Write item info"
                  sx={{
                    width: "100%",

                    borderRadius: "6px",

                    fontSize: "11pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                alignItems={"center"}
                justifyContent="right"
              >
                <Button
                  variant="outlined"
                  color="info"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    clear();
                    setAddNewBtn(false);
                    handleClose();
                  }}
                >
                  Cancel
                </Button>

                {inputField.Name && inputField.info ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      handleAddNewCustomer();
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      setAddNewBtn(false);
                    }}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateNewBotModal;
