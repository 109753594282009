import React from "react";
import { Box } from "@mui/material";
// import payoneer from "../interN/payoneer.png";
import ghorami from "../interN/ghorami_logo.ico";
import paypal from "../interN/paypal.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
const International = ({
  amount,
  note,
  handleClose,
  setToggleDisplay,
  setselectedMethod,
  currentCurrency,
}) => {
  const ImagesInfo = [
    { name: "paypal", image: paypal, id: "9" },
    { name: "ghorami", image: ghorami, id: "8" },
  ];
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleCashIN = (type) => {
    const formData = new FormData();
    formData.append("pay_refer", "");
    formData.append("c_id", user?.umobile);
    formData.append("paytype", "4");
    formData.append("poster", user?.sopnoid);
    formData.append("acAmount", amount);
    formData.append("acNote", note);
    formData.append("receiver", "self_");
    formData.append("c_refer", user?.c_refer);
    formData.append("billing_state", "1");
    formData.append("acType", type);
    axios
      .post(
        `${user.master_url}/profile/ac_bil/api_web/new/pay_new.php`,
        formData
      )
      .then((res) => {
        // console.log(res.data);
        // handleClose();
        if (res.data[0]?.trnxid && res.data[0]?.verfify_type) {
          swal({
            title: "Success",
            text: "Your transaction is successful",
            icon: "success",
            buttons: true,
          }).then((value) => {
            if (value) {
              let url;
              const formData = new FormData();
              if (res.data[0]?.ac_type === "9") {
                url = "https://173.212.230.192:2030/service/paypl/index.php`";
              }

              formData.append("adsrla", res.data[0]?.trnxid);
              formData.append("amnt", res.data[0]?.amount);
              formData.append("email", user?.sopnoid);
              formData.append("cname", user?.uname);
              formData.append("type", res.data[0]?.ac_type);
              axios.post(url, formData).then((res) => {
                // console.log(res.data);
                if (res.data === "success") {
                  navigate("/");
                }
              });
            }
          });
        } else {
          swal({
            title: "Error",
            text: "Your transaction is failed",
            icon: "error",
          });
        }
      });
  };
  return (
    <div>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {currentCurrency !== "BDT" && (
          <img
            src={ImagesInfo[0]?.image}
            alt=""
            style={{
              width: ImagesInfo[0]?.name === "ghorami" ? "11%" : "16%",
              margin: "0 5px",
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={() => {
              // handleCashIN(item?.id);
              setselectedMethod(ImagesInfo[0]);
              setToggleDisplay(true);
            }}
          />
        )}
        <img
          src={ImagesInfo[1]?.image}
          alt=""
          style={{
            width: ImagesInfo[1]?.name === "ghorami" ? "11%" : "16%",
            margin: "0 5px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => {
            // handleCashIN(item?.id);
            setselectedMethod(ImagesInfo[1]);
            setToggleDisplay(true);
          }}
        />
        {/* {ImagesInfo?.map((item) => {
          return (
            item?.image &&
            currentCurrency !== "BDT" &&
            item?.name !== "paypal" && (
              <img
                key={item?.id}
                src={item?.image}
                alt=""
                style={{
                  width: item?.name === "ghorami" ? "11%" : "16%",
                  margin: "0 5px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // handleCashIN(item?.id);
                  setselectedMethod(item);
                  setToggleDisplay(true);
                }}
              />
            )
          );
        })} */}
      </Box>
    </div>
  );
};

export default International;
