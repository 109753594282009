import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Stack } from "@mui/material";
import "./ReqResTab.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CampaignReqResTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Request"
            {...a11yProps(0)}
            sx={{ textTransform: "capitalize", width: "50%" }}
          />
          <Tab
            label="Response"
            {...a11yProps(1)}
            sx={{ textTransform: "capitalize", width: "50%" }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{ padding: "0px", width: "100%" }}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"left"}
          sx={{
            width: "100%",
            height: "8vh",
            bgcolor: "#9acbc0",
            marginTop: "5px",
            paddingX: "15px",
            borderRadius: "4px",
            boxSizing: "border-box",
          }}
          spacing={2}
        >
          <Typography variant="p" sx={{ fontWeight: 600, fontSize: "10pt" }}>
            Request URL
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"left"}
          flexWrap="wrap"
          sx={{
            width: "100%",
            height: "8vh",
            marginTop: "5px",
            paddingX: "15px",
            borderRadius: "4px",
            boxSizing: "border-box",
          }}
          spacing={2}
        >
          <Typography variant="p" sx={{ fontWeight: 600, fontSize: "9pt" }}>
            https://whatsapp.ghorami.com/api/chat_campaign/campaign_st_new.php
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"left"}
          sx={{
            width: "100%",
            height: "8vh",
            bgcolor: "#9acbc0",
            marginTop: "5px",
            paddingX: "15px",
            borderRadius: "4px",
            boxSizing: "border-box",
          }}
          spacing={2}
        >
          <Typography variant="p" sx={{ fontWeight: 600, fontSize: "10pt" }}>
            Request Body
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-betweeen"}
          sx={{
            width: "100%",
            borderBottom: "1px solid gray",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
            sx={{
              width: "25%",
              minHeight: "8vh",
              marginTop: "5px",
              paddingX: "15px",
              boxSizing: "border-box",
            }}
            spacing={2}
          >
            <Typography variant="p" sx={{ fontWeight: 600, fontSize: "10pt" }}>
              Token
              <sup style={{ marginLeft: "5px" }}>
                <small style={{ color: "#d50202" }}>*Required</small>
              </sup>
            </Typography>
          </Stack>
          <Stack
            alignItems="flex-start"
            justifyContent={"center"}
            sx={{
              width: "75%",
              minHeight: "8vh",
              marginTop: "5px",
              paddingX: "15px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: 400, fontSize: "9pt", textAlign: "left" }}
            >
              Panel ID{" "}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-betweeen"}
          sx={{
            width: "100%",
            borderBottom: "1px solid gray",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
            sx={{
              width: "25%",
              minHeight: "8vh",
              marginTop: "5px",
              paddingX: "15px",
              boxSizing: "border-box",
            }}
            spacing={2}
          >
            <Typography variant="p" sx={{ fontWeight: 600, fontSize: "10pt" }}>
              To
              <sup style={{ marginLeft: "5px" }}>
                <small style={{ color: "#d50202" }}>*Required</small>
              </sup>
            </Typography>
          </Stack>
          <Stack
            alignItems="flex-start"
            justifyContent={"center"}
            sx={{
              width: "75%",
              minHeight: "8vh",
              marginTop: "5px",
              paddingX: "15px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: 400, fontSize: "9pt", textAlign: "left" }}
            >
              Phone number with international format e.g.
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: 400, fontSize: "9pt", textAlign: "left" }}
            >
              +14155552671
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: 400, fontSize: "9pt", textAlign: "left" }}
            >
              or
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: 400, fontSize: "9pt", textAlign: "left" }}
            >
              chatID for contact or group e.g 14155552671@c.us or
              14155552671-441234567890@g.us
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-betweeen"}
          sx={{
            width: "100%",
            borderBottom: "1px solid gray",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
            sx={{
              width: "25%",
              minHeight: "8vh",
              marginTop: "5px",
              paddingX: "15px",
              boxSizing: "border-box",
            }}
            spacing={2}
          >
            <Typography variant="p" sx={{ fontWeight: 600, fontSize: "10pt" }}>
              Body
              <sup style={{ marginLeft: "5px" }}>
                <small style={{ color: "#d50202" }}>*Required</small>
              </sup>
            </Typography>
          </Stack>
          <Stack
            alignItems="flex-start"
            justifyContent={"center"}
            sx={{
              width: "75%",
              minHeight: "8vh",
              marginTop: "5px",
              paddingX: "15px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: 400, fontSize: "9pt", textAlign: "left" }}
            >
              Message text, UTF-8 or UTF-16 string with emoji
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: 400, fontSize: "9pt", textAlign: "left" }}
            >
              Max length : 4096 characters .
            </Typography>
          </Stack>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Response
      </TabPanel>
    </Box>
  );
};

export default CampaignReqResTab;
