import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { Box, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const History = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [paymentMethod, setPaymentMethod] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const tableColumnData = [
    {
      name: "Method",
    },
    {
      name: "AC",
    },
    {
      name: "Action",
    },
  ];

  useEffect(() => {
    const formData = new FormData();
    formData.append("SopnoID", user?.sopnoid);
    formData.append("Stat", "1");
    formData.append("action", "fetch");
    axios
      .post(
        `https://whatsapp.ghorami.com/api/profile/ac_bil/api_web/pay_method.php`,
        formData
      )
      .then((res) => {
        // console.log(res.data);
        setPaymentMethod(res.data);
      });
  }, [user?.master_url, user?.sopnoid, reload]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // alert("will delete");
        const formData = new FormData();
        formData.append("id", id);
        formData.append("SopnoID", user?.sopnoid);
        axios
          .post(
            `https://whatsapp.ghorami.com/api/profile/ac_bil/api_web/ac_delete.php`,
            formData
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data === "success") {
              swal("Successfully Deleted!", {
                icon: "success",
              });
              setReload(!reload);
            }
            // setReload(!reload);
          });
      } else {
        swal("Your data is safe!", "", "success");
      }
    });
  };
  return (
    <div>
      <Box>
        {" "}
        <TableContainer
          sx={[
            {
              maxHeight: 440,
              "@media (max-width: 576px)": {
                width: "270px",
              },
            },
          ]}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableColumnData?.map((column) => (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                    }}
                    key={column.name}
                  >
                    {column?.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethod?.length > 0 ? (
                paymentMethod?.map((row, i) => {
                  return (
                    <TableRow
                      hover
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.ac_type}
                      </TableCell>
                      <TableCell align="left">{row?.ac_number}</TableCell>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          color="warning"
                          sx={{
                            fontSize: "1.2rem",
                            color: "red",
                          }}
                          onClick={() => {
                            handleDelete(row?.customer_id);
                          }}
                        >
                          <AiFillDelete />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    colSpan={tableColumnData?.length}
                  >
                    No Payment Method
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default History;
