import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import {
  AttachFileOutlined,
  CallOutlined,
  Check,
  EmojiEmotionsOutlined,
  HighlightOff,
  KeyboardArrowUp,
  Language,
  MicNoneOutlined,
  MoreHoriz,
  PhotoCamera,
  SendOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import useSound from "use-sound";
import fanfareSfx from "../../../../../Audio/small_beep_sms.mp3";

const TextEditor = ({
  setSelectedTemplate,
  setListReplyBtn,
  autoBotBtn,
  selectedCustomer,
  setDropDownBtn,
  setSelectedBtn,
  postInfo,
  setPostInfo,
  setRegNumberInfo,
  regNumberInfo,
  handleTemplateList,
  template,
  setTemplate,
  conversation,
  setConversation,
  accountNumber,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState();
  const [paymentInfo, setPaymentInfo] = useState({});
  const [inputFieldLength, setInputFieldLength] = useState(0);
  const [attachment, setAttachment] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [allActiveTemplate, setAllActiveTemplate] = useState([]);

  console.log("selectedCustomer111:", selectedCustomer);

  const [allParams, setAllParams] = useState([{}]);
  const [templateValue, setTemplateValue] = useState("");
  const [paramInfo, setParamInfo] = useState({});
  const [play, { stop }] = useSound(fanfareSfx);

  // useEffect(() => {
  //   let newObject = localStorage.getItem("wmData");
  //   let parsedObject = JSON.parse(newObject);

  //   fetch(
  //     `https://whatsapp.ghorami.com/api/chatbot_api/get_all_active_template.php?accn_id=${accountNumber}&poster=${
  //       parsedObject?.poster
  //     }&type=${"wam"}`
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setAllActiveTemplate(result);
  //     });
  // }, [accountNumber]);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_all_active_template.php?accn_id=${
        parsedObject?.accn_id
      }&poster=${parsedObject?.poster}&panel_id=${accountNumber}&type=${"wam"}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllActiveTemplate(result);
      });

    fetch(
      `https://whatsapp.ghorami.com/api/get_customer_response.php?customer_number=${
        selectedCustomer?.MobileNumber
      }&panel_id=${accountNumber}&poster=${
        parsedObject?.poster
      }&item=${"template"}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resultuiooo:", result);
        // setAllActiveTemplate(result);
      });
  }, [selectedCustomer?.MobileNumber, accountNumber]);

  useEffect(() => {
    let newFieldarr = [];
    let found = allActiveTemplate?.find(
      (element) => element?.tem_name === templateValue
    );
    console.log("found555:", found);

    let paramArray = found?.param_value?.split(", ");
    console.log("paramArray:", paramArray);
    paramArray?.length > 0 &&
      paramArray?.map((element, index) => {
        element &&
          newFieldarr?.push({
            id: index + 1,
            variant_no: index + 1,
            variant_name: element,

            variant_value: element === "userName" ? selectedCustomer?.Name : "",
          });
      });
    setAllParams([...newFieldarr]);
  }, [templateValue, selectedCustomer?.Name]);

  const handleSend = () => {
    let newParamInfo = paramInfo?.temp_peram?.map((element) => {
      return {
        type: "text",
        text: element?.variant_value,
      };
    });
    console.log("newParamInfo:", newParamInfo);

    let newPostInfoc = postInfo;

    newPostInfoc.receiverN = selectedCustomer?.MobileNumber;

    setPostInfo({ ...newPostInfoc });

    const formData = new FormData();
    formData.append("param", JSON.stringify(newParamInfo));
    formData.append("message", JSON.stringify(postInfo));
    formData.append("param_list", JSON.stringify(paramInfo?.temp_peram));
    // must be one of {ACTION, CURRENCY, DATE_TIME, DOCUMENT, IMAGE, LOCATION, PAYLOAD, TEXT, VIDEO}
    if (postInfo?.message_text || postInfo?.templateName) {
      fetch("https://whatsapp.ghorami.com/api/wm_send.php", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          result?.errTyp === "success"
            ? enqueueSnackbar("Message sent successfully!", result?.errTyp)
            : enqueueSnackbar("Message sending failed!", result?.errTyp);
          result?.errTyp === "success" && play();
          setMessage("");
          setAllParams([]);
          setParamInfo("");

          console.log("messagePost:", result);
        })
        .catch((error) => {
          console.log("messagePost=> ", error);
        });
      setAlertMessage("");
    } else {
      setAlertMessage("Textfield empty");
    }
  };
  const handleAttachment = (e) => {
    if (
      e.target.files[0].type == "application/pdf" ||
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpeg"
    ) {
      // setAttachment(URL.createObjectURL(e.target.files[0]));
      console.log("eeerere:", e.target.files[0]);

      setAttachment(e.target.files[0]);
      let newInfo = regNumberInfo;
      newInfo.attachment = e.target.files[0];
      setRegNumberInfo({ ...newInfo });
      setAlertMessage("");
    } else {
      e.target.value = null;
      setAlertMessage("This is not a valid file!");
    }
  };

  const handleDeleteAttachment = (name) => {
    setAttachment(null);
  };

  const countLength = (e) => {
    setInputFieldLength(e.target.value.length);
    setAlertMessage("");
  };
  const handleMessageChange = (e) => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    setMessage(e.target.value);
    let newPostInfo = postInfo;
    newPostInfo.message_text = e.target.value;
    newPostInfo.templateName = "";
    newPostInfo.receiverN = selectedCustomer?.MobileNumber;
    newPostInfo.receiverNumber = selectedCustomer?.MobileNumber;
    newPostInfo.panel_id = accountNumber;
    newPostInfo.poster_id = parsedObject?.poster;
    setPostInfo({ ...newPostInfo });
  };

  const handleTemplateValue = (event) => {
    setTemplateValue(event.target.value);
    let newPostInfo = postInfo;
    newPostInfo.message_text = event.target.value;
    newPostInfo.templateName = event.target.value;
    setPostInfo({ ...newPostInfo });
  };

  const handleVarientFormChange = (index, event) => {
    let data = [...allParams];

    data[index][event.target.name] = event.target.value;
    data[index].id = index + 1;
    setAllParams(data);

    let newUpdateInfo = paramInfo;
    newUpdateInfo.temp_peram = allParams;
    setParamInfo({ ...newUpdateInfo });
  };
  return (
    <>
      {!autoBotBtn && (
        <Box
          sx={{
            position: "relative",
            paddingX: "20px",
            width: "100%",
            bgcolor: "white",
            borderBottom: 0,
            borderRadius: "8px",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            boxSizing: "border-box",
          }}
        >
          {selectedCustomer?.userId && (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
              sx={{ paddingY: "20px" }}
            >
              {conversation && (
                <TextField
                  variant="standard" // <== changed this
                  margin="normal"
                  required
                  fullWidth
                  name="message"
                  multiline
                  rows={2}
                  autoFocus
                  onChange={countLength}
                  onBlur={handleMessageChange}
                  placeholder="Write your text"
                  inputProps={{
                    maxLength: 800,
                  }}
                  InputProps={{
                    disableUnderline: true, // <== added this
                  }}
                />
              )}

              {template && (
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Select
                    size="small"
                    value={templateValue}
                    onChange={handleTemplateValue}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ width: "50%" }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <em>
                            {" "}
                            <Typography variant="p" sx={{ fontSize: "10pt" }}>
                              Select Template
                            </Typography>
                          </em>
                        );
                      }

                      return;
                    }}
                  >
                    {allActiveTemplate?.map((element, index) => (
                      <MenuItem value={element?.tem_name}>
                        <Typography variant="p" sx={{ fontSize: "10pt" }}>
                          {element?.tem_name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>

                  <TextField
                    variant="standard" // <== changed this
                    margin="normal"
                    required
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    autoComplete="phoneNumber"
                    autoFocus
                    value={templateValue}
                    // onBlur={handleMessageChange}
                    placeholder="Write your text"
                    InputProps={{
                      disableUnderline: true, // <== added this
                    }}
                    // sx={{
                    //   fontSize: "10pt",
                    //   fontWeight: 500,
                    // }}
                  />

                  {allParams?.length > 0 &&
                    allParams?.map((element, index) => (
                      <Stack
                        alignItems="flex-start"
                        justifyContent={"center"}
                        spacing={0.5}
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "10pt",
                            fontWeight: 500,
                          }}
                        >
                          {element?.variant_name?.toUpperCase()}
                        </Typography>
                        {element?.variant_name === "userName" && (
                          <OutlinedInput
                            type={
                              element?.variant_name === "from" ? "url" : "text"
                            }
                            name="variant_value"
                            size="small"
                            placeholder="Enter variant value"
                            value={element?.variant_value}
                            onChange={(event) =>
                              handleVarientFormChange(index, event)
                            }
                            sx={{
                              width: "100%",
                              borderRadius: "6px",
                              fontSize: "9pt",
                            }}
                          />
                        )}
                        {element?.variant_name !== "userName" && (
                          <OutlinedInput
                            type={
                              element?.variant_name === "from" ? "url" : "text"
                            }
                            name="variant_value"
                            size="small"
                            placeholder="Enter variant value"
                            // value={element?.variant_value}
                            onChange={(event) =>
                              handleVarientFormChange(index, event)
                            }
                            sx={{
                              width: "100%",
                              borderRadius: "6px",
                              fontSize: "9pt",
                            }}
                          />
                        )}
                      </Stack>
                    ))}
                </Stack>
              )}

              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="right"
              >
                <IconButton aria-label="delete" size="large">
                  <EmojiEmotionsOutlined />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={handleSend}
                >
                  <SendOutlined />
                </IconButton>
              </Stack>
            </Stack>
          )}
          <Box sx={{ position: "absolute", right: "10px", top: "-22px" }}>
            <Typography>{inputFieldLength}/800</Typography>
          </Box>
          {alertMessage && (
            <Box sx={{ position: "absolute", right: "60px", top: "-23px" }}>
              <Typography variant="p" sx={{ fontSize: "10pt", color: "red" }}>
                {alertMessage}
              </Typography>
            </Box>
          )}
          <Box sx={{ position: "absolute", left: "0px", top: "-35px" }}>
            {attachment && (
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                paddingLeft="15px"
                paddingRight="10px"
                sx={{ bgcolor: "#17a7a7", color: "white" }}
              >
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {attachment.name}
                </Typography>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    handleDeleteAttachment();
                  }}
                >
                  <HighlightOff fontSize="inherit" sx={{ color: "#fff" }} />
                </IconButton>
              </Stack>
            )}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          paddingX: "15px",
          width: "100%",
          bgcolor: "#eef0f1",
          border: "1px solid gray",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          boxSizing: "border-box",
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingY: "10px" }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="right"
            spacing={2}
          >
            {!autoBotBtn && (
              <Button
                aria-label="delete"
                // variant={conversation ? "outlined" : "" }
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxSizing: "border-box",
                  color: conversation ? "white" : "black",
                  bgcolor: conversation ? "darkgreen" : "",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "darkblue",
                  },
                }}
                onClick={() => {
                  let newPostInfo = postInfo;
                  newPostInfo.wr_type = "text";
                  setPostInfo({ ...newPostInfo });

                  setTemplate(false);
                  setListReplyBtn(false);
                  setConversation(true);
                  setSelectedTemplate(false);
                }}
              >
                Conversation
              </Button>
            )}
            <Button
              aria-label="delete"
              // variant={template ? "outlined" : ""}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxSizing: "border-box",
                color: template ? "white" : "black",
                bgcolor: template ? "darkgreen" : "",
                "&:hover": {
                  color: "white",
                  backgroundColor: "darkblue",
                },
              }}
              onClick={() => {
                let newPostInfo = postInfo;
                newPostInfo.wr_type = "template";
                setPostInfo({ ...newPostInfo });

                setTemplate(true);
                setListReplyBtn(false);
                setConversation(false);
                setSelectedTemplate(false);
                setSelectedBtn(false);
                setDropDownBtn(false);
                if (autoBotBtn) {
                  console.log("dhokenai");
                  handleTemplateList();
                }
              }}
            >
              Template
            </Button>
          </Stack>
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="right"
          >
            <IconButton
              aria-label="delete"
              component="label"
              size="small"
              sx={{ zIndex: 10 }}
            >
              <input
                hidden
                type="file"
                name="attachment"
                onChange={handleAttachment}
              />
              <AttachFileOutlined sx={{ fontSize: "16pt" }} />
            </IconButton>

            <IconButton aria-label="delete" size="small">
              <CallOutlined sx={{ fontSize: "16pt" }} />
            </IconButton>
            <IconButton aria-label="delete" size="small">
              <MicNoneOutlined sx={{ fontSize: "16pt" }} />
            </IconButton>
            <IconButton aria-label="delete" size="small">
              <Language sx={{ fontSize: "16pt" }} />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default TextEditor;
