import { CloudUpload, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Theme, useTheme } from "@mui/material/styles";
import "../../css/StartCampaignForm.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName1, theme) {
  return {
    fontWeight:
      personName1.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const NewCampaignForm = ({
  createCampaignBtn,
  accountNumber,
  setAddNewBtn,
  toggle,
  startCampaignInfo,
  setStartCampaignInfo,
  inputField,
  setInputField,
  selectedCustomer,
}) => {
  const theme = useTheme();
  const [personName1, setpersonName1] = useState([]);
  const [personName2, setpersonName2] = useState([]);
  const [personName3, setpersonName3] = useState([]);
  const [personName4, setpersonName4] = useState([]);
  const [personName5, setpersonName5] = useState([]);
  console.log("startCampaignInfo:", startCampaignInfo);
  // const [inputField, setInputField] = useState({});
  const [sendTime, setSendTime] = useState({});
  console.log("sendTime:", sendTime);
  const [allParams, setAllParams] = useState([{}]);
  console.log("allParams:", allParams);

  const [templateList, setTemplateList] = useState([]);
  const [allButton, setAllButton] = useState([]);
  const [allProfession, setAllProfession] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [allRegion, setAllRegion] = useState([]);
  console.log("allRegion:", allRegion);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_all_active_template.php?accn_id=${accountNumber}&poster=${
        parsedObject?.poster
      }&type=${"wam"}`
    )
      .then((res) => res.json())
      .then((result) => {
        setTemplateList(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/chat_contact/get_all_contactlist.php?user_id=${parsedObject?.SopnoID}&panel_id=${accountNumber}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllButton(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/chat_contact/get_all_profession.php?user_id=${parsedObject?.SopnoID}&panel_id=${accountNumber}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllProfession(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/chat_contact/get_all_country.php?user_id=${parsedObject?.SopnoID}&panel_id=${accountNumber}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllCountry(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/chat_contact/get_all_region.php?user_id=${parsedObject?.SopnoID}&panel_id=${accountNumber}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllRegion(result);
      });
  }, []);

  useEffect(() => {
    let newFieldarr = [];
    let found = templateList?.find(
      (element) => element?.tem_name === startCampaignInfo?.template
    );
    console.log("found555:", found);

    let paramArray = found?.param_value?.split(", ");
    console.log("paramArray:", paramArray);
    paramArray?.length > 0 &&
      paramArray?.map((element, index) => {
        element &&
          newFieldarr?.push({
            id: index + 1,
            variant_no: index + 1,
            variant_name: element,

            variant_value: "",
            // variant_value: element === "userName" ? selectedCustomer?.Name : "",
          });
      });
    setAllParams([...newFieldarr]);
  }, [startCampaignInfo?.template, selectedCustomer?.Name]);

  const clear = () => {
    setStartCampaignInfo({});
    setInputField({});
  };
  const handleSend = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    startCampaignInfo.p_user = parsedObject?.SopnoID;

    let newParamInfo = startCampaignInfo?.temp_peram?.map((element) => {
      return {
        type: "text",
        text: element?.variant_value,
      };
    });
    console.log("newParamInfo:", newParamInfo);

    const formData = new FormData();
    formData.append("param", JSON.stringify(newParamInfo));
    formData.append("newCampaign", JSON.stringify(startCampaignInfo));
    formData.append("panel_id", accountNumber);

    fetch(
      `https://whatsapp.ghorami.com/api/chat_campaign/campaign_st_new.php`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resultc:", result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const handleContactGroupChange = (event) => {
    const {
      target: { value },
    } = event;

    setpersonName1(value);
    const newtemplateMessageBotInfo = startCampaignInfo;
    newtemplateMessageBotInfo.contact_group = value.join(", ");
    setStartCampaignInfo({ ...newtemplateMessageBotInfo });
  };
  const handleProfessionChange = (event) => {
    const {
      target: { value },
    } = event;

    setpersonName2(value);
    const newtemplateMessageBotInfo = startCampaignInfo;
    newtemplateMessageBotInfo.profession = value.join(", ");
    setStartCampaignInfo({ ...newtemplateMessageBotInfo });
  };
  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;

    setpersonName3(value);
    const newtemplateMessageBotInfo = startCampaignInfo;
    newtemplateMessageBotInfo.country = value.join(", ");
    setStartCampaignInfo({ ...newtemplateMessageBotInfo });
  };
  const handleRegionChange = (event) => {
    const {
      target: { value },
    } = event;

    setpersonName4(value);
    const newtemplateMessageBotInfo = startCampaignInfo;
    newtemplateMessageBotInfo.region = value.join(", ");
    setStartCampaignInfo({ ...newtemplateMessageBotInfo });
  };
  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;

    setpersonName5(value);
    const newtemplateMessageBotInfo = startCampaignInfo;
    newtemplateMessageBotInfo.language = value.join(", ");
    setStartCampaignInfo({ ...newtemplateMessageBotInfo });
  };
  const handleSendTypeChange = (event) => {
    const updateField = sendTime;
    updateField[event.target.name] = event.target.value;
    setSendTime({ ...updateField });
  };

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "template") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "language") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "min_age") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }
    if (event.target.name === "max_age") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }
    if (event.target.name === "gender") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "campaign_name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "set_time") {
      // isFieldValid = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/.test(event.target.value);
      isFieldValid = true;
    }
    if (event.target.name === "set_Date") {
      // isFieldValid = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/.test(event.target.value);
      isFieldValid = true;
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newstartCampaignInfo = startCampaignInfo;
      newstartCampaignInfo[event.target.name] = event.target.value;
      setStartCampaignInfo({ ...newstartCampaignInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  const handleVarientFormChange = (index, event) => {
    let data = [...allParams];
    // let isFieldValid = false;
    // if (event.target.type === "url") {
    //   isFieldValid =
    //     /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
    //       event.target.value
    //     );
    // } else {
    //   isFieldValid = true;
    // }
    // if (isFieldValid === true) {
    data[index][event.target.name] = event.target.value;
    data[index].id = index + 1;
    setAllParams(data);

    let newUpdateInfo = startCampaignInfo;
    newUpdateInfo.temp_peram = allParams;
    setStartCampaignInfo({ ...newUpdateInfo });
    // }
    // setButtonAlert(false);
  };
  return (
    <Paper
      sx={{
        width: !toggle ? "100%" : "70%",
        overflow: "hidden",
        // paddingRight: "20%",
        paddingLeft: "3%",
        paddingRight: "2%",
        paddingY: "3%",
        boxSizing: "border-box",
      }}
    >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px", width: "100%" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Campaign Name
              </Typography>
              {inputField.fieldErrorAlert === "campaign_name" && (
                <small style={{ color: "#CB0101" }}>*Enter campaign name</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              size="small"
              name="campaign_name"
              placeholder="Name"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Select Contact Group
              </Typography>
              {inputField.fieldErrorAlert === "template_title" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Select
                multiple
                displayEmpty
                value={personName1}
                // name="buttons"
                onChange={handleContactGroupChange}
                input={<OutlinedInput />}
                size="small"
                placeholder="Request"
                sx={{
                  width: "100%",

                  borderRadius: "6px",

                  fontSize: "10pt",
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select contact group</em>;
                  }

                  return selected.join(", ");
                }}
                // MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {allButton?.map((element, index) => (
                  <MenuItem
                    key={index}
                    value={element.Name}
                    style={getStyles(element.Name, personName1, theme)}
                  >
                    {element.Name}
                  </MenuItem>
                ))}
              </Select>
              {/* <Box sx={{ width: "30%" }}>
                <Typography variant="p" fontSize={"10pt"} fontWeight={600}>
                </Typography>
              </Box> */}
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={3.5}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Age group
              </Typography>
              {/* {inputField.fieldErrorAlert === "campaign_name" && (
                <small style={{ color: "#CB0101" }}>*Enter campaign name</small>
              )} */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
              spacing={2}
            >
              <OutlinedInput
                type="number"
                size="small"
                name="min_age"
                sx={{
                  width: "35%",

                  borderRadius: "6px",
                  fontSize: "8pt",
                }}
                onChange={handleChange}
              />
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                To
              </Typography>
              <OutlinedInput
                type="number"
                size="small"
                name="max_age"
                sx={{
                  width: "35%",

                  borderRadius: "6px",
                  fontSize: "8pt",
                }}
                onChange={handleChange}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={4.5}>
          <Stack spacing={0.5} sx={{ marginLeft: "20px" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Select Gender
              </Typography>
              {inputField.fieldErrorAlert === "gender" && (
                <small style={{ color: "#CB0101" }}>*Select valid gender</small>
              )}
            </Stack>

            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="gender"
              defaultValue="All"
              value={inputField.gender}
              onChange={handleChange}
            >
              <FormControlLabel
                sx={{ fontSize: "8pt" }}
                value="Male"
                control={<Radio size="small" />}
                label={
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                    }}
                  >
                    Male
                  </Typography>
                }
                size="small"
              />
              <FormControlLabel
                value="Female"
                control={<Radio size="small" />}
                label={
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                    }}
                  >
                    Female
                  </Typography>
                }
                size="small"
              />
              <FormControlLabel
                value="All"
                size="small"
                control={<Radio size="small" />}
                label={
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                    }}
                  >
                    All
                  </Typography>
                }
              />
            </RadioGroup>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Profession
              </Typography>
            </Stack>

            <Select
              multiple
              displayEmpty
              value={personName2}
              // name="buttons"
              onChange={handleProfessionChange}
              input={<OutlinedInput />}
              size="small"
              placeholder="Request"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "10pt",
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Profession</em>;
                }

                return selected.join(", ");
              }}
              // MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                value={"all"}
                style={getStyles("all", personName2, theme)}
              >
                all
              </MenuItem>
              {allProfession?.map((element, index) => (
                <MenuItem
                  key={index}
                  value={element.Profession}
                  style={getStyles(element.Profession, personName2, theme)}
                >
                  {element.Profession}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Country
              </Typography>
            </Stack>

            <Select
              multiple
              displayEmpty
              value={personName3}
              // name="buttons"
              onChange={handleCountryChange}
              input={<OutlinedInput />}
              size="small"
              placeholder="Request"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "10pt",
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Country</em>;
                }

                return selected.join(", ");
              }}
              // MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                value={"all"}
                style={getStyles("all", personName2, theme)}
              >
                all
              </MenuItem>
              {allCountry?.map((element, index) => (
                <MenuItem
                  key={index}
                  value={element.Country}
                  style={getStyles(element.Country, personName3, theme)}
                >
                  {element.Country}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Region
              </Typography>
              {/* {inputField.fieldErrorAlert === "template" && (
                <small style={{ color: "#CB0101" }}>
                  *Select valid template
                </small>
              )} */}
            </Stack>

            <Select
              multiple
              displayEmpty
              value={personName4}
              // name="buttons"
              onChange={handleRegionChange}
              input={<OutlinedInput />}
              size="small"
              placeholder="Request"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "10pt",
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Region</em>;
                }

                return selected.join(", ");
              }}
              // MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                value={"all"}
                style={getStyles("all", personName2, theme)}
              >
                all
              </MenuItem>
              {allRegion?.map((element, index) => (
                <MenuItem
                  key={index}
                  value={element.Region}
                  style={getStyles(element.Region, personName4, theme)}
                >
                  {element.Region}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Language
              </Typography>
              {/* {inputField.fieldErrorAlert === "template" && (
                <small style={{ color: "#CB0101" }}>
                  *Select valid template
                </small>
              )} */}
            </Stack>

            <Select
              name="language"
              onChange={handleChange}
              input={<OutlinedInput />}
              size="small"
              placeholder="Request"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              <MenuItem value={"Bangla"}>Bangla</MenuItem>
              <MenuItem value={"English"}>English</MenuItem>
              <MenuItem value={"Arabic"}>Arabic</MenuItem>
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 500,
                }}
              >
                Select Template
              </Typography>
              {inputField.fieldErrorAlert === "template" && (
                <small style={{ color: "#CB0101" }}>
                  *Select valid template
                </small>
              )}
            </Stack>

            <Select
              name="template"
              size="small"
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "10pt",
              }}
            >
              {templateList?.map((element, index) => (
                <MenuItem value={element?.tem_name}>
                  <Typography variant="p" sx={{ fontSize: "10pt" }}>
                    {element?.tem_name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          {" "}
          <Stack
            direction={"row"}
            justifyContent="left"
            alignItems="flex-end"
            spacing={1}
            flexWrap="wrap"
          >
            {allParams?.length > 0 &&
              allParams?.map((element, index) => (
                <Stack
                  alignItems="flex-start"
                  justifyContent={"center"}
                  spacing={0.5}
                  sx={{ width: "32%" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                    }}
                  >
                    {element?.variant_name?.toUpperCase()}
                  </Typography>
                  <OutlinedInput
                    type={element?.variant_name === "from" ? "url" : "text"}
                    name="variant_value"
                    size="small"
                    placeholder="Enter variant value"
                    value={element?.variant_value}
                    onChange={(event) => handleVarientFormChange(index, event)}
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "9pt",
                    }}
                  />
                </Stack>
              ))}
          </Stack>
        </Grid>

        {sendTime?.sendTimeType == "Send later" && (
          <>
            <Grid item xs={6}>
              <Stack spacing={0.5} sx={{ width: "100%" }}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                    }}
                  >
                    Set Time
                  </Typography>
                  {inputField.fieldErrorAlert === "set_time" && (
                    <small style={{ color: "#CB0101" }}>
                      *Enter valid link
                    </small>
                  )}
                </Stack>

                <OutlinedInput
                  type="time"
                  size="small"
                  name="set_time"
                  sx={{
                    width: "100%",

                    borderRadius: "6px",
                    fontSize: "10pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={0.5} sx={{ width: "100%" }}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                    }}
                  >
                    Set Date
                  </Typography>
                  {inputField.fieldErrorAlert === "set_Date" && (
                    <small style={{ color: "#CB0101" }}>
                      *Enter valid Date
                    </small>
                  )}
                </Stack>

                <OutlinedInput
                  type="date"
                  size="small"
                  name="set_Date"
                  sx={{
                    width: "100%",

                    borderRadius: "6px",
                    fontSize: "10pt",
                  }}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack spacing={0.5} sx={{ marginLeft: "10px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="sendTimeType"
              defaultValue="Send now"
              value={sendTime.sendTimeType}
              onChange={handleSendTypeChange}
            >
              <FormControlLabel
                sx={{ fontSize: "8pt" }}
                value="Send now"
                control={<Radio size="small" />}
                label={
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                    }}
                  >
                    Send now
                  </Typography>
                }
                size="small"
              />
              <FormControlLabel
                value="Send later"
                control={<Radio size="small" />}
                label={
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                    }}
                  >
                    Send later
                  </Typography>
                }
                size="small"
              />
            </RadioGroup>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
            sx={{ width: "80%" }}
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.template &&
            inputField.campaign_name &&
            inputField.min_age &&
            inputField.max_age &&
            personName1 &&
            personName2 &&
            personName3 &&
            personName4 &&
            personName5 &&
            allParams?.[0]?.variant_value &&
            allParams?.[1]?.variant_value &&
            allParams?.[2]?.variant_value &&
            inputField.gender ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleSend();
                  setAddNewBtn(false);
                }}
              >
                Send
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Send
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NewCampaignForm;
