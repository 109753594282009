import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { Article, Delete, Download, Update } from "@mui/icons-material";
import TemplateDetailsModal from "./TemplateDetails";
import { useNavigate } from "react-router";

const columns = [
  { label: "Name" },
  { label: "Id" },
  { label: "Language" },
  { label: "Category" },
  { label: "Status" },
  { label: "Details" },
];

export default function TemplateTable({
  allTemplate,
  setTempDetailsBtn,
  setAddNewBtn,
  setLeftBarBtn,
  selectedTempId,
  setSelectedTempId,
  setTempUpdate,
}) {
  console.log("allTemplate:", allTemplate);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  //   };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 572, overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                >
                  <strong> {column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {allTemplate?.length > 0 && (
            <TableBody>
              {allTemplate?.map((item) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                    <>
                      <TableCell align="center" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.name ? item.name : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.id ? item.id : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.language ? item.language : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.category ? item.category : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.status == "APPROVED" && (
                            <Typography
                              variant="p"
                              sx={{ color: "#068906", fontSize: "8pt" }}
                            >
                              APPROVED
                            </Typography>
                          )}
                          {item.status == "REJECTED" && (
                            <Typography
                              variant="p"
                              sx={{ color: "#a70a0a", fontSize: "8pt" }}
                            >
                              REJECTED
                            </Typography>
                          )}
                          {item.status == "PENDING" && (
                            <Typography
                              variant="p"
                              sx={{ color: "gray", fontSize: "8pt" }}
                            >
                              PENDING
                            </Typography>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setAddNewBtn(false);
                            setTempUpdate(false);
                            setTempDetailsBtn(true);
                            setSelectedTempId(item?.id);
                          }}
                        >
                          <Article fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={allTemplate.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
