import { HighlightOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const TextTypeForm = ({
  setAddNewBtn,
  referState,
  accountNumber,
  selectedTemplate,
  selectedReplyBtnInfo,
  selectedCustomer,
  dropDownBtn,
  selectedBtn,
  setDropDownBtn,
  setSelectedBtn,
  postInfo,
  setPostInfo,
  setTemplate,
  setListReplyBtn,
  setConversation,
  setSelectedTemplate,
  handleBotResponse,
}) => {
  console.log("selectedBtnrr:", selectedBtn);
  console.log("selectedReplyBtnInfo:", selectedReplyBtnInfo);

  console.log("selectedTemplate:", selectedTemplate);

  const focus = useRef(null);
  const focus2 = useRef(null);
  const focus1 = useRef(null);
  const focus3 = useRef(null);
  const focus4 = useRef(null);
  const focus5 = useRef(null);
  const btn1 = useRef(null);
  const btn2 = useRef(null);
  const btn3 = useRef(null);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [templateMessageBotInfo, setTemplateMessageBotInfo] = useState([]);
  console.log("templateMessageBotInfoooo:", templateMessageBotInfo);
  const [inputField, setInputField] = useState({});
  console.log("inputField:", inputField);
  const [buttonFields, setButtonFields] = useState([{ id: 0 }]);
  const [nameAlert, setNameAlert] = useState(false);
  const [variable, setVariable] = useState(1);
  const [variableArray, setVariableArray] = useState([1]);
  const [buttonAlert, setButtonAlert] = useState(false);
  const [allPhone_code, setAllPhone_code] = useState([]);
  const [immediateInputValue, setImmediateInputValue] = useState("");
  console.log("immediateInputValue:", immediateInputValue);

  const [allButton, setAllButton] = useState([]);
  const [botList, setBotList] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    let newTemplateMessageBotInfo = templateMessageBotInfo;
    newTemplateMessageBotInfo.template_type = selectedTemplate;
    newTemplateMessageBotInfo.template_for = dropDownBtn
      ? selectedReplyBtnInfo?.tem_name
      : selectedBtn?.btn_title;
    setTemplateMessageBotInfo(newTemplateMessageBotInfo);
  }, [selectedTemplate, selectedReplyBtnInfo?.tem_name]);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch("https://whatsapp.ghorami.com/api/chatbot_api/get_all_item.php")
      .then((res) => res.json())
      .then((result) => {
        setBotList(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_wap_template_btn.php?poster_id=${parsedObject?.poster}&accn_id=${parsedObject?.accn_id}`
    )
      .then((res) => res.json())
      .then((result) => setAllButton(result));
  }, []);
  useEffect(() => {
    fetch(`https://ghorami.com/profile/login/api_booking/country_list.php`)
      .then((res) => res.json())
      .then((result) => {
        setAllPhone_code(result);
      });
  }, []);

  const replaceAt = (string, index, char) => {
    let a = string.split("");
    a[index] = char;
    return a.join("");
  };

  const handleVariable = () => {
    // let newTemplateMessageBotInfo = templateMessageBotInfo;
    // newTemplateMessageBotInfo.message_details =
    // newTemplateMessageBotInfo.message_details + "{{" + variable + "}}";
    // setTemplateMessageBotInfo(newTemplateMessageBotInfo);
    console.log("variable:", variable);
    console.log("variableArray:", variableArray);

    let newTemplateMessageBotInfo = templateMessageBotInfo;
    newTemplateMessageBotInfo.message_details =
      newTemplateMessageBotInfo.message_details +
      "{{" +
      variableArray[variable] +
      "}}";

    setTemplateMessageBotInfo(newTemplateMessageBotInfo);
    setImmediateInputValue(newTemplateMessageBotInfo.message_details);
    // setVariable(variable + 1);
    setVariableArray([...variableArray]);
    // setVariableArray([...variableArray, variable]);
    focus5.current.focus();
  };

  // useEffect(() => {
  //   setVariableArray([...variableArray, variable]);

  //   let newTemplateMessageBotInfo = templateMessageBotInfo;
  //   newTemplateMessageBotInfo.message_details =
  //     newTemplateMessageBotInfo.message_details + "{{" + variableArray[variable] + "}}";
  //   setTemplateMessageBotInfo(newTemplateMessageBotInfo);
  // }, [variable]);

  /*................... old version......................*/

  const handleNameChange = (event) => {
    event.target.value = event.target.value.replace(" ", "_");
    // if (!/^[^\s]+$/.test(event.target.value)) {
    //   setButtonAlert(`*${event.target.name.replace("_", " ")} value is empty`);
    //   const newtemplateMessageBotInfo = templateMessageBotInfo;
    //   newtemplateMessageBotInfo[event.target.name] = "";
    //   setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    // } else if (/^[^\s]+$/.test(event.target.value)) {
    setNameAlert(false);
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/check_template_name.php?tem_name=${event.target.value}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resultsdsdrrrsd:", result);

        if (result[0]?.id && result) {
          setNameAlert("*Name Already Exist");
          const newtemplateMessageBotInfo = templateMessageBotInfo;
          newtemplateMessageBotInfo[event.target.name] = "";
          setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
        } else {
          const newtemplateMessageBotInfo = templateMessageBotInfo;
          newtemplateMessageBotInfo[event.target.name] = event.target.value;
          setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
          setNameAlert(false);
          // }
        }
      });
    // }
  };

  /*................... with underScore ......................*/

  // const handleNameChange = (event) => {
  //   event.target.value = event.target.value.replace(" ", "_");
  //   const newtemplateMessageBotInfo = templateMessageBotInfo;
  //   newtemplateMessageBotInfo[event.target.name] = event.target.value;
  //   setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
  //   setNameAlert(false);
  //   fetch(
  //     `https://whatsapp.ghorami.com/api/chatbot_api/check_template_name.php?tem_name=${event.target.value}`
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       console.log("resultsdsdsyyyd:", result);

  //       if (result[0]?.id && result) {
  //         setNameAlert("*Name Already Exist");
  //         const newtemplateMessageBotInfo = templateMessageBotInfo;
  //         newtemplateMessageBotInfo[event.target.name] = "";
  //         setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
  //       }
  //     });
  // };

  const addNewTemplate = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    let updateInfo = templateMessageBotInfo;
    updateInfo.user_id = parsedObject?.SopnoID;
    updateInfo.refer = parsedObject?.accn_id;
    updateInfo.template_type = selectedTemplate;
    updateInfo.item_name = selectedCustomer?.Name;
    updateInfo.template_for = dropDownBtn
      ? selectedReplyBtnInfo?.tem_name
      : selectedBtn?.btn_title;

    const formData = new FormData();
    formData.append("panel_idc", accountNumber);
    formData.append("template_type", selectedTemplate);
    formData.append("new_systemplate", JSON.stringify(updateInfo));

    fetch(
      "https://whatsapp.ghorami.com/api/chatbot_api/wm_autobot_template_new.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result[0]?.result === "success") {
          setTemplate(true);
          setListReplyBtn(false);
          setConversation(false);
          setSelectedTemplate(false);
          setSelectedBtn(false);
          setDropDownBtn(false);
          handleBotResponse();
        }
      })
      .catch((error) => {
        console.log("messagePost=> ", error);
      });
  };
  const addButtonFields = () => {
    let id = Math.floor(Math.random() * 1000000000 + 1);
    let newField = { id: id };
    if (buttonFields.length <= 2) {
      setButtonFields([...buttonFields, newField]);
    } else {
      setButtonFields([...buttonFields]);
    }
  };

  const handleDeleteButtonFields = (id) => {
    let filteredField = buttonFields.filter((element) => element.id != id);
    setButtonFields([...filteredField]);
  };

  const clear = () => {
    setTemplateMessageBotInfo({});
    setInputField({});
    setButtonFields([{ id: 0 }]);
    focus.current.children[0].value = "";
    focus1.current.children[1].value = "";
    focus2.current.children[1].value = "";
    // focus2.current.removeChild(focus2.current.children[0]);
    focus4.current.children[0].value = "";
    btn1.current.children[0].value = "";
    btn2.current.children[0].value = "";
    btn3.current.children[0].value = "";
    focus5.current.value = "";
    console.log("focus.current:", focus2.current.children[1]);
  };
  // const handleButtonChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   setPersonName(value);
  //   const newtemplateMessageBotInfo = templateMessageBotInfo;
  //   newtemplateMessageBotInfo.request = value.join(", ");
  //   setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
  // };
  // const handleItemChange = (event) => {
  //   const newtemplateMessageBotInfo = templateMessageBotInfo;
  //   const newInputField = inputField;
  //   newtemplateMessageBotInfo.item_title = event.target.value;
  //   newInputField.item_title = event.target.value;
  //   setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
  //   setInputField({ ...newInputField });
  // };

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "Name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "item_title") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "web_url") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "language") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "category") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "message_details") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (isFieldValid) {
      if (event.target.name === "item_title") {
        event.target.value = event.target.value.replace(" ", "_");
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newtemplateMessageBotInfo = templateMessageBotInfo;
        newtemplateMessageBotInfo[event.target.name] = event.target.value;
        setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
      } else {
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newtemplateMessageBotInfo = templateMessageBotInfo;
        newtemplateMessageBotInfo[event.target.name] = event.target.value;
        setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
      }
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      setTemplateMessageBotInfo(newField);
    }
  };
  return (
    <Box sx={{}}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        // sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Template Type
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              spacing={1}
            >
              {" "}
              <OutlinedInput
                ref={focus}
                type="text"
                name="template_type"
                size="small"
                value={selectedTemplate}
                placeholder="item"
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  fontSize: "9pt",
                }}
                // onChange={handleChange}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Template for
              </Typography>
              {inputField.fieldErrorAlert === "template_title" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus}
              type="text"
              name="template_type"
              size="small"
              value={
                dropDownBtn
                  ? selectedReplyBtnInfo?.tem_name
                  : selectedBtn?.btn_title
              }
              placeholder="item"
              sx={{
                width: "100%",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              // onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Template Name
              </Typography>
              {nameAlert && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  {nameAlert}
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus1}
              type="text"
              name="template_name"
              size="small"
              placeholder="Template name"
              // value={templateMessageBotInfo?.Name}
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleNameChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Language
              </Typography>
            </Stack>

            <Select
              // value={age}
              ref={focus2}
              name="language"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"en_US"}>English</MenuItem>
              <MenuItem value={"bn_BD"}>Bangla</MenuItem>
              <MenuItem value={"ar_UAE"}>Arabic</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "language" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Category
              </Typography>
            </Stack>

            <Select
              // value={age}

              name="category"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={"marketing"}>Marketing</MenuItem>
              <MenuItem value={"transactional"}>Transactional</MenuItem>
              <MenuItem value={"OTP"}>OTP</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "category" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                web_url
              </Typography>
              {inputField.fieldErrorAlert === "web_url" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Header Field missing
                </small>
              )}
              {templateMessageBotInfo?.web_url?.includes("{{1") ? (
                <Button
                  size="small"
                  sx={{ fontSize: "7pt", color: "black", fontWeight: 700 }}
                  onClick={(event) => {
                    const newtemplateMessageBotInfo = templateMessageBotInfo;
                    newtemplateMessageBotInfo.web_url =
                      newtemplateMessageBotInfo.web_url + " {{1}}";
                    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
                  }}
                  disabled
                >
                  Add variable
                </Button>
              ) : (
                <Button
                  size="small"
                  sx={{ fontSize: "7pt", color: "black", fontWeight: 700 }}
                  onClick={(event) => {
                    const newtemplateMessageBotInfo = templateMessageBotInfo;
                    newtemplateMessageBotInfo.web_url =
                      newtemplateMessageBotInfo.web_url + " {{1}}";
                    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
                  }}
                >
                  Add variable
                </Button>
              )}
            </Stack>

            <OutlinedInput
              ref={focus4}
              type="text"
              name="web_url"
              size="small"
              value={templateMessageBotInfo?.web_url}
              placeholder="web url"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Message Details
              </Typography>
              {inputField.fieldErrorAlert === "message_details" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
              {templateMessageBotInfo?.message_details?.includes("{{1") ? (
                <Button
                  size="small"
                  sx={{ fontSize: "7pt", color: "black", fontWeight: 700 }}
                  onClick={(event) => {
                    const newtemplateMessageBotInfo = templateMessageBotInfo;
                    newtemplateMessageBotInfo.message_details =
                      newtemplateMessageBotInfo.message_details + " {{1}}";
                    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
                  }}
                  disabled
                >
                  Add variable
                </Button>
              ) : (
                <Button
                  size="small"
                  sx={{ fontSize: "7pt", color: "black", fontWeight: 700 }}
                  onClick={(event) => {
                    const newtemplateMessageBotInfo = templateMessageBotInfo;
                    newtemplateMessageBotInfo.message_details =
                      newtemplateMessageBotInfo.message_details + " {{1}}";
                    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
                  }}
                >
                  Add variable
                </Button>
              )}
            </Stack>

            <TextareaAutosize
              ref={focus5}
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter message_details"
              value={templateMessageBotInfo.message_details}
              inputProps={{
                maxLength: 800,
              }}
              name="message_details"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",

                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
              // onKeyDown={handleDownKey}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                setTemplate(true);
                setListReplyBtn(false);
                setConversation(false);
                setSelectedTemplate(false);
                setSelectedBtn(false);
                setDropDownBtn(false);
                handleBotResponse();
                // clear();
                // setAddNewBtn(false);
              }}
            >
              Back
            </Button>

            {templateMessageBotInfo?.template_name &&
            !nameAlert &&
            !buttonAlert &&
            templateMessageBotInfo?.message_details &&
            templateMessageBotInfo?.template_type &&
            templateMessageBotInfo?.template_for &&
            templateMessageBotInfo?.language &&
            templateMessageBotInfo?.category ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  addNewTemplate();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          This is a success message!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TextTypeForm;
