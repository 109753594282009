import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import {
  AttachFileOutlined,
  CallOutlined,
  Check,
  EmojiEmotionsOutlined,
  HighlightOff,
  KeyboardArrowUp,
  Language,
  MicNoneOutlined,
  MoreHoriz,
  PhotoCamera,
  SendOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import RegNumberForm from "./Gateway Setting/NewGatewayForm";
import AddUserForm from "./Users/AddUserForm";
import NewGatewayForm from "./Gateway Setting/NewGatewayForm";
import GatewayDetails from "./Gateway Setting/GatewayDetails";
import GatewayTableRapper from "./Gateway Setting/GatewayTableRapper";
import Chat from "./API/API Pages/ChatApi/ChatApi";
import TemplateApi from "./API/API Pages/TemplateApi/TemplateApi";
import NewCampaignApi from "./API/API Pages/NewCampaignApi/NewCampaignApi";
import OTPApi from "./API/API Pages/OTPApi/OTPApi";

const MainSecDev = ({
  addNewBtn,
  setAddNewBtn,
  selectedCustomer,
  leftSideButton,
  setLeftSideButton,
}) => {
  const [updateItem, setUpdateItem] = useState({});
  console.log("addNewBtn:", addNewBtn);
  console.log("leftSideButton:", leftSideButton);

  return (
    <Box sx={{ boxSizing: "border-box" }}>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid gray",
          borderTop: 0,
          borderRadius: 0,
          paddingX: "20px",
          paddingY: "10px",
          boxSizing: "border-box",
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="left"
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{}}
              onClick={() => {}}
            />
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 700 }}>
              {selectedCustomer?.Name}
            </Typography>
          </Stack>
          <Input
            id="standard-adornment-weight"
            //   value={values.weight}
            //   onChange={handleChange("weight")}
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            sx={{ width: "40%" }}
          />
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent="right"
            alignItems="center"
          >
            {selectedCustomer?.states == 1 ? (
              <Stack
                direction={"row"}
                justifyContent="right"
                alignItems="center"
                sx={{
                  paddingX: "12px",
                  paddingY: "2px",
                  bgcolor: "darkgreen",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "8pt", color: "white" }}
                >
                  LIVE
                </Typography>
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                justifyContent="right"
                alignItems="center"
                sx={{
                  paddingX: "12px",
                  paddingY: "2px",
                  bgcolor: "#c50808",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "8pt", color: "white" }}
                >
                  LIVE
                </Typography>
              </Stack>
            )}

            <IconButton aria-label="delete" size="small">
              <MoreHoriz fontSize="small" />
            </IconButton>
            <IconButton aria-label="delete" size="small">
              <Check fontSize="small" />
            </IconButton>

            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{
                paddingX: "12px",
                paddingY: "2px",
                bgcolor: "darkgreen",
                borderRadius: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                setAddNewBtn(true);
              }}
            >
              <Typography variant="p" sx={{ fontSize: "8pt", color: "white" }}>
                ADD NEW
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          height: "90.8vh",
          overflow: "auto",
          paddingX: "20px",
          paddingY: "20px",
          borderRadius: 0,
          border: "1px solid gray",
          borderTop: 0,
          borderBottom: 0,
          bgcolor: "#dbf0f7",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        {addNewBtn &&
          (leftSideButton === "GatewayS" || leftSideButton === "update") && (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              sx={{
                width: "100%",
              }}
            >
              <Paper
                sx={{
                  width: "60%",
                  height: "auto",
                  overflowY: "auto",
                  paddingX: "25px",
                  paddingTop: "20px",
                  boxSizing: "border-box",
                }}
              >
                <NewGatewayForm />
              </Paper>
            </Stack>
          )}
        {!addNewBtn && leftSideButton === "GatewayS" && (
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            sx={{
              width: "100%",
            }}
          >
            <Paper
              sx={{
                width: "100%",
                height: "auto",
                overflowY: "auto",
                paddingX: "25px",
                paddingTop: "20px",
                boxSizing: "border-box",
              }}
            >
              {/* <GatewayDetails /> */}

              <GatewayTableRapper
                setLeftSideButton={setLeftSideButton}
                setAddNewBtn={setAddNewBtn}
                setUpdateItem={setUpdateItem}
              />
            </Paper>
          </Stack>
        )}
        {!addNewBtn && leftSideButton === "update" && (
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            sx={{
              width: "100%",
            }}
          >
            <Paper
              sx={{
                width: "100%",
                height: "auto",
                overflowY: "auto",
                paddingX: "25px",
                paddingTop: "20px",
                boxSizing: "border-box",
              }}
            >
              <GatewayDetails updateItem={updateItem} />

              {/* <GatewayTableRapper
                setLeftSideButton={setLeftSideButton}
                setAddNewBtn={setAddNewBtn}
              /> */}
            </Paper>
          </Stack>
        )}
        {addNewBtn && leftSideButton === "userS" && (
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            sx={{
              width: "100%",
            }}
          >
            <Paper
              sx={{
                width: "60%",
                height: "auto",
                overflowY: "auto",
                paddingX: "25px",
                paddingTop: "20px",
                boxSizing: "border-box",
              }}
            >
              <AddUserForm />
            </Paper>
          </Stack>
        )}
        {leftSideButton === "Chat" && <Chat />}
        {leftSideButton === "TemplateApi" && <TemplateApi />}
        {leftSideButton === "NewCampaignApi" && <NewCampaignApi />}
        {leftSideButton === "NewOTPApi" && <OTPApi />}
      </Paper>
    </Box>
  );
};

export default MainSecDev;
