import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import TemplateDetails from "./TemplateDetails";
import TemplateMessageForm from "./TemplateMessageForm";
// import BillingPaymentModal from "./BillingPaymentModal";
import TemplateTable from "./TemplateTable";
import TemplateUpdate from "./TemplateUpdate";
// import PaymentMethodCashInModal from "./PaymentMethodCashInModal/PaymentMethodCashInModal";

const Template = ({
  render,
  selectedTempId,
  setSelectedTempId,
  addNewBtn,
  tempDetailsBtn,
  setTempDetailsBtn,
  leftBarBtn,
  handleAddNewButtons,
  setTemplateMessageBotInfo,
  templateMessageBotInfo,
  handleTemplateMessageSave,
  setAddNewBtn,
  setLeftBarBtn,
  referState,
  tempUpdate,
  setTempUpdate,
}) => {
  const [accountNumber, setAccountNumber] = useState("");
  console.log("accountNumber:", accountNumber);
  const [allTemplate, setAllTemplate] = useState([]);
  console.log("allTemplate:", allTemplate);
  const [gateWay, setAllGateWay] = useState([]);
  console.log("gateWay:", gateWay);
  const [paymentInfoInfo, setPaymentInfoInfo] = useState({});
  const [inputField, setInputField] = useState({});
  const [trnxId, setTrnxId] = useState("");
  const [searchedText, setSearchedText] = useState("");
  console.log("searchedText:", searchedText);
  const [payOrReq, setPayOrReq] = useState(false);
  console.log("payOrReq:", payOrReq);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [newBalance, setNewBalance] = useState("");
  const [selectedMethod, setselectedMethod] = useState("");
  console.log("selectedMethod:", selectedMethod);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setPaymentInfoInfo({});
    // setInputField({});
  };
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    setPaymentInfoInfo({});
    setInputField({});
  };

  const handleChange = (event) => {
    setAccountNumber(event.target.value);
  };

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", 1234);
    formData.append("token", Math.round(Math.random() * 10000000000));

    fetch(
      `https://whatsapp.ghorami.com/api/dev_api/get_all_gateway.php?user_id=${parsedObject?.poster}&accn_id=${parsedObject?.accn_id}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtcttt:", result);
        setAllGateWay(result);
        setAccountNumber(result[0]?.temp_refer);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("accn_id", 1234);
    formData.append("token", Math.round(Math.random() * 10000000000));

    fetch(
      `https://whatsapp.ghorami.com/api/wap_template/get_all_template.php?panel_idc=${accountNumber}&poster=${parsedObject?.poster}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        setAllTemplate(result?.data);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [accountNumber, addNewBtn, render]);

  const handleSearch = (e) => {
    setSearchedText(e.target.value);
  };
  const handleGetSearchedItem = () => {
    let searchedItem = allTemplate?.filter(
      (element) =>
        element?.id === searchedText || element?.name === searchedText
    );
    setAllTemplate(searchedItem);
  };

  return (
    <Stack
      spacing={1}
      sx={{
        bgcolor: "#dbf0f7",
        width: "100%",
        height: "100%",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="left"
          spacing={2}
        >
          <Typography variant="p" sx={{ fontWeight: 600 }}>
            Templates
          </Typography>
          <OutlinedInput
            id="outlined-adornment-weight"
            size="small"
            sx={{ fontSize: "10pt" }}
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <Search
                  sx={{ cursor: "pointer" }}
                  onClick={handleGetSearchedItem}
                />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="right"
          spacing={2}
        >
          {!addNewBtn && (
            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{
                paddingX: "12px",
                paddingY: "2px",
                bgcolor: "darkgreen",
                borderRadius: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                setTempDetailsBtn(false);
                handleAddNewButtons();
              }}
            >
              <Typography variant="p" sx={{ fontSize: "8pt", color: "white" }}>
                ADD NEW
              </Typography>
            </Stack>
          )}
          {addNewBtn && (
            <Stack
              direction={"row"}
              justifyContent="right"
              alignItems="center"
              sx={{
                paddingX: "12px",
                paddingY: "2px",
                bgcolor: "darkgreen",
                borderRadius: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                setAddNewBtn(false);
                setTempDetailsBtn(false);
                setTempUpdate(false);
              }}
            >
              <Typography variant="p" sx={{ fontSize: "8pt", color: "white" }}>
                Template List
              </Typography>
            </Stack>
          )}
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="right"
          >
            <Select
              size="small"
              value={accountNumber}
              onChange={handleChange}
              displayEmpty
              sx={{
                fontSize: "10px",
                width: "150px",
                padding: "0px 0px !important",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {gateWay?.map((element) => (
                <MenuItem value={element?.temp_refer} sx={{ fontSize: "10px" }}>
                  <Stack
                    direction={"column"}
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <Typography variant="p" sx={{ fontSize: "8pt" }}>
                      {" "}
                      {element?.busin_name}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "7pt", color: "gray" }}
                    >
                      {element?.wap_business_id}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      </Stack>

      {leftBarBtn === "template" &&
        !addNewBtn &&
        !tempDetailsBtn &&
        !tempUpdate && (
          <Paper sx={{ padding: "10px" }}>
            <TemplateTable
              allTemplate={allTemplate}
              setTempDetailsBtn={setTempDetailsBtn}
              setLeftBarBtn={setLeftBarBtn}
              setAddNewBtn={setAddNewBtn}
              selectedTempId={selectedTempId}
              setSelectedTempId={setSelectedTempId}
              setTempUpdate={setTempUpdate}
            />
          </Paper>
        )}

      {leftBarBtn === "template" &&
        addNewBtn &&
        !tempDetailsBtn &&
        !tempUpdate && (
          <Stack direction={"row"} justifyContent="center" alignItems="center">
            <Box
              sx={{
                height: "85vh",
                width: "50%",
                overflow: "auto",
                borderRadius: "12px",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  bgcolor: "white",
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                <TemplateMessageForm
                  templateMessageBotInfo={templateMessageBotInfo}
                  setTemplateMessageBotInfo={setTemplateMessageBotInfo}
                  handleTemplateMessageSave={handleTemplateMessageSave}
                  setAddNewBtn={setAddNewBtn}
                  referState={referState}
                  accountNumber={accountNumber}
                />
              </Box>
            </Box>
          </Stack>
        )}
      {leftBarBtn === "template" &&
        !addNewBtn &&
        !tempUpdate &&
        tempDetailsBtn && (
          <Stack direction={"row"} justifyContent="center" alignItems="center">
            <Box
              sx={{
                width: "50%",
                bgcolor: "white",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              <TemplateDetails
                // templateMessageBotInfo={templateMessageBotInfo}
                // setTemplateMessageBotInfo={setTemplateMessageBotInfo}
                // handleTemplateMessageSave={handleTemplateMessageSave}
                selectedTempId={selectedTempId}
                accountNumber={accountNumber}
                setAddNewBtn={setAddNewBtn}
                referState={referState}
                tempUpdate={tempUpdate}
                setTempUpdate={setTempUpdate}
                setTempDetailsBtn={setTempDetailsBtn}
              />
            </Box>
          </Stack>
        )}
      {leftBarBtn === "template" &&
        !addNewBtn &&
        !tempDetailsBtn &&
        tempUpdate && (
          <Stack direction={"row"} justifyContent="center" alignItems="center">
            <Box
              sx={{
                width: "50%",
                bgcolor: "white",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              <TemplateUpdate
                templateMessageBotInfo={templateMessageBotInfo}
                setTemplateMessageBotInfo={setTemplateMessageBotInfo}
                handleTemplateMessageSave={handleTemplateMessageSave}
                selectedTempId={selectedTempId}
                accountNumber={accountNumber}
                setAddNewBtn={setAddNewBtn}
                referState={referState}
              />
            </Box>
          </Stack>
        )}
    </Stack>
  );
};

export default Template;
