/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { Card } from "@mui/material";
import New from "./New/New";
import History from "./History/History";

const AddPaymentMethod = () => {
  const [component, setComponent] = useState("New");

  return (
    <Card
      variant="outlined"
      sx={[
        {
          width: "100%",
          boxSizing: "border-box",
          margin: "auto",
          p: 2,
          "@media screen and (max-width:768px)": {
            width: "100%",
          },
        },
      ]}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="contained"
            color={component === "New" ? "primary" : "secondary"}
            sx={[
              {
                mr: 2,
                "@media screen and (max-width:415px)": {
                  mb: 1,
                },
              },
            ]}
            onClick={() => {
              setComponent("New");
            }}
          >
            New{" "}
          </Button>
          <Button
            variant="contained"
            color={component === "History" ? "primary" : "secondary"}
            sx={{ mr: 2 }}
            onClick={() => {
              setComponent("History");
            }}
          >
            All
          </Button>
        </Box>
        <Box
          sx={[
            {
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "rgba(0,0,0,0.7)",
              "@media screen and (max-width:768px)": {
                fontSize: "1.1rem",
              },
            },
          ]}
        >
          Payment Method
        </Box>
      </Box>
      <Box>
        {component === "New" && <New />}
        {component === "History" && <History />}
      </Box>
    </Card>
  );
};

export default AddPaymentMethod;
