import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";

const columns = [
  { label: "Name" },
  { label: "Phone" },
  { label: "Email" },
  { label: "Gender" },
  { label: "Age_limit" },
  { label: "Profession" },
  { label: "Language" },
  { label: "Region" },
  { label: "Country" },
  { label: "state" },
];

export default function ContactDetailsTable({ contactDetails }) {
  console.log("contactDetails:", contactDetails);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 520 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align="left"
                  style={{ minWidth: column.minWidth }}
                >
                  <strong> {column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contactDetails
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                    <>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Name ? item.Name : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Phone ? item.Phone : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Email ? item.Email : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Gender ? item.Gender : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Age_limit ? item.Age_limit : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Profession ? item.Profession : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Language ? item.Language : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Region ? item.Region : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.Country ? item.Country : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Typography variant="p" sx={{ fontSize: "8pt" }}>
                          {item.state == "1" ? "Active" : "Inactive"}
                        </Typography>
                      </TableCell>
                    </>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={contactDetails.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
