import {
  AccountBalance,
  AutoAwesomeMosaic,
  BorderClear,
  Code,
  ContactPage,
  Dashboard,
  Dataset,
  EmailTwoTone,
  Logout,
  Settings,
} from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LeftBar = ({
  render,
  setRender,
  setLeftBarBtn,
  setSelectedCustomer,
  handleContactGroupData,
  setRegNumBtn,
  setPaymentBtn,
  handleMessageBtn,
  handleAutoBotBtn,
  handleCampaignBtn,
  setAddNewBtn,
  setRegNumAddNewBtn,
  setPaymentAddNewBtn,
  setNewContactBtn,
  setCampaignDetailsBtn,
  setCreateCampaignBtn,
  setTempDetailsBtn,
  setTempUpdate,
}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
        bgcolor: "#085b48",
        paddingTop: "40px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          // height: "20vh",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            // bgcolor: "darkgreen",
            // width: "30px",
            // height: "30px",
            borderRadius: "4px",
          }}
        >
          <Dashboard
            sx={{
              color: "white",
              fontSize: "16pt",
              cursor: "pointer",
            }}
            onClick={() => {
              setLeftBarBtn("dashboard");
              handleContactGroupData();
              setRegNumBtn(false);
              setPaymentBtn(false);
              handleMessageBtn(false);
              handleAutoBotBtn(false);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setCampaignDetailsBtn(false);
              setSelectedCustomer({});
              setNewContactBtn(false);
              setCreateCampaignBtn(false);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            // bgcolor: "darkgreen",
            // width: "30px",
            // height: "30px",
            marginTop: "20px",
            borderRadius: "4px",
          }}
        >
          <EmailTwoTone
            sx={{ color: "white", fontSize: "16pt", cursor: "pointer" }}
            onClick={() => {
              setLeftBarBtn("message");
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            // bgcolor: "darkgreen",
            // width: "30px",
            // height: "30px",
            borderRadius: "4px",
          }}
        >
          <ContactPage
            sx={{
              color: "white",
              fontSize: "16pt",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setLeftBarBtn("contact");
              handleContactGroupData();
              setRegNumBtn(false);
              setPaymentBtn(false);
              handleMessageBtn(false);
              handleAutoBotBtn(false);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setCampaignDetailsBtn(false);
              setSelectedCustomer({});
              setNewContactBtn(false);
              setCreateCampaignBtn(false);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            borderRadius: "4px",
          }}
        >
          <Dataset
            sx={{
              color: "white",
              fontSize: "16pt",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setRender(!render);
              setLeftBarBtn("template");
              handleContactGroupData();
              setRegNumBtn(false);
              setPaymentBtn(false);
              handleMessageBtn(false);
              handleAutoBotBtn(false);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setCampaignDetailsBtn(false);
              setSelectedCustomer({});
              setNewContactBtn(false);
              setCreateCampaignBtn(false);
              setTempDetailsBtn(false);
              setTempUpdate(false);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            borderRadius: "4px",
          }}
        >
          <AccountBalance
            sx={{
              color: "white",
              fontSize: "16pt",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setLeftBarBtn("billing");
              handleContactGroupData();
              setRegNumBtn(false);
              setPaymentBtn(false);
              handleMessageBtn(false);
              handleAutoBotBtn(false);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setCampaignDetailsBtn(false);
              setSelectedCustomer({});
              setNewContactBtn(false);
              setCreateCampaignBtn(false);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            borderRadius: "4px",
          }}
        >
          <Settings
            sx={{
              color: "white",
              fontSize: "16pt",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setLeftBarBtn("setting");
              handleContactGroupData();
              setRegNumBtn(false);
              setPaymentBtn(false);
              handleMessageBtn(false);
              handleAutoBotBtn(false);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setCampaignDetailsBtn(false);
              setSelectedCustomer({});
              setNewContactBtn(false);
              setCreateCampaignBtn(false);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            borderRadius: "4px",
          }}
        >
          <Code
            sx={{
              color: "white",
              fontSize: "16pt",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setLeftBarBtn("developer");
              handleContactGroupData();
              setRegNumBtn(false);
              setPaymentBtn(false);
              handleMessageBtn(false);
              handleAutoBotBtn(false);
              handleCampaignBtn(false);
              setAddNewBtn(false);
              setRegNumAddNewBtn(false);
              setPaymentAddNewBtn(false);
              setCampaignDetailsBtn(false);
              setSelectedCustomer({});
              setNewContactBtn(false);
              setCreateCampaignBtn(false);
            }}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          height: "20vh",
          paddingBottom: "20px",
        }}
      >
        <Logout
          sx={{ color: "white", fontSize: "16pt", cursor: "pointer" }}
          onClick={handleLogout}
        />
      </Box>
    </Box>
  );
};

export default LeftBar;
