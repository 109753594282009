import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import { Add, EmailTwoTone } from "@mui/icons-material";
import LeftBar from "./components/LeftBar";
import Inbox from "./components/Inbox";
import Conversation from "./components/Conversation Components/Conversation";
import "./css/message.css";
import RightBar from "./components/RightBar";
import ContactGroup from "./components/Contact/Contact group Components/ContactGroup";
import ContactMainSection from "./components/Contact/ContactMainSection";
import Billing from "./components/Billing/Billing";
import LeftSide from "./components/Settings/LeftSide/LeftSide";
import MainSecSettings from "./components/Settings/MainSecSettings/MainSecSettings";
import LeftSideDev from "./components/Developer/LeftSide/LeftSideDev";
import MainSecDev from "./components/Developer/MainSecSettings/MainSecDev";
import Dashboard from "./components/Dashboard/Dashboard";
import Template from "./components/Template/Template";

const initialPostInfo = {
  receiverN: "",
  message_text: "",
  mtype: "text",
  recipient_type: "individual",
  wr_type: "text",
};

const WhatsApp = () => {
  const [toggle, setToggle] = useState(true);
  console.log("toggle:", toggle);
  const [searchFound, setSearchFound] = useState(false);
  const [messageBtn, setMessageBtn] = useState(false);
  const [leftBarBtn, setLeftBarBtn] = useState(false);
  const [autoBotBtn, setAutoBotBtn] = useState(false);
  const [campaignBtn, setCampaignBtn] = useState(false);
  const [addNewBtn, setAddNewBtn] = useState(false);
  const [listReplyBtn, setListReplyBtn] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tempDetailsBtn, setTempDetailsBtn] = useState(false);
  const [createCampaignBtn, setCreateCampaignBtn] = useState(false);
  const [newContactBtn, setNewContactBtn] = useState(false);
  const [newContactListBtn, setNewContactListBtn] = useState(false);
  const [campaignDetailsBtn, setCampaignDetailsBtn] = useState(false);
  const [regNumAddNewBtn, setRegNumAddNewBtn] = useState(false);
  const [selectedTempId, setSelectedTempId] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [tempUpdate, setTempUpdate] = useState(false);
  const [render, setRender] = useState(false);
  const [conversation, setConversation] = useState(true);
  const [template, setTemplate] = useState(false);
  console.log("renderyyy:", render);

  const [regNumberInfo, setRegNumberInfo] = useState({ state: "Active" });
  const [paymentAddNewBtn, setPaymentAddNewBtn] = useState(false);
  const [regNumBtn, setRegNumBtn] = useState(false);
  const [paymentBtn, setPaymentBtn] = useState(false);
  const [postInfo, setPostInfo] = useState(initialPostInfo);

  const [allCustomer, setAllCustomer] = useState([]);
  const [allBot, setAllBot] = useState([]);
  console.log("allBot:", allBot);
  const [contactGroup, setContactGroup] = useState([]);
  const [referState, setReferState] = useState("");
  console.log("referState:", referState);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  console.log("selectedCustomer818:", selectedCustomer);
  const [dropDownBtn, setDropDownBtn] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(false);
  const [startCampaignInfo, setStartCampaignInfo] = useState({});
  const [inputField, setInputField] = useState({});
  const [customerResponse, setCustomerResponse] = useState([]);
  console.log("customerResponse818:", customerResponse);
  const [botResponse, setBotResponse] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState({});
  const [campaignDetailsList, setCampaignDetailsList] = useState([]);
  const [campaignNumber, setCampaignNumber] = useState([]);
  const [campaignWaitingNumber, setCampaignWaitingNumber] = useState([]);
  const [templateMessageBotInfo, setTemplateMessageBotInfo] = useState({
    request: "",
  });
  const [accountNumber, setAccountNumber] = useState("");
  console.log("accountNumber818:", accountNumber);
  const [leftSideButton, setLeftSideButton] = useState([]);

  const handleCustomerResponse = (MobileNumber) => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `https://whatsapp.ghorami.com/api/get_customer_response.php?customer_number=${MobileNumber}&panel_id=${accountNumber}&poster=${
        parsedObject?.poster
      }&item=${"template"}`
    )
      .then((res) => res.json())
      .then((result) => {
        setCustomerResponse(result);
        console.log("result999911:", result);
      });
  };
  const handleBotResponse = (referState) => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `https://whatsapp.ghorami.com/api/get_customer_response.php?customer_number=${
        selectedCustomer?.MobileNumber
      }&panel_id=${accountNumber}&poster=${
        parsedObject?.poster
      }&item=${"template"}`
    )
      .then((res) => res.json())
      .then((result) => {
        setBotResponse(result);
        console.log("resultyuyy:", result);
      });
  };
  const handleTemplateList = (itemName) => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_all_template_listOrder.php?item_name=${
        itemName ? itemName : selectedCustomer?.Name
      }&panel_id=${parsedObject?.SopnoID}&accn=${parsedObject?.accn_id}`
    )
      .then((res) => res.json())
      .then((result) => setTemplateList(result));
  };
  const handleCampaignDetailsList = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `https://whatsapp.ghorami.com/api/chat_campaign/get_all_campaign.php?user_id=${parsedObject?.SopnoID}`
    )
      .then((res) => res.json())
      .then((result) => setCampaignDetailsList(result));
  };
  const handleCampaignDetails = (referState) => {
    fetch(
      `https://whatsapp.ghorami.com/api/chat_campaign/get_all_campaign_details.php?campaign_refer=${referState}`
    )
      .then((res) => res.json())
      .then((result) => setCampaignDetails(result[0]));
  };
  const handleCampaignNumber = (referState) => {
    fetch(
      `https://whatsapp.ghorami.com/api/chat_campaign/get_all_campaign_number.php?campaign_refer=${referState}`
    )
      .then((res) => res.json())
      .then((result) => setCampaignNumber(result));
  };
  const handleCampaignWaitingNumber = (referState) => {
    fetch(
      `https://whatsapp.ghorami.com/api/chat_campaign/get_all_campaign_que.php?campaign_refer=${referState}`
    )
      .then((res) => res.json())
      .then((result) => setCampaignWaitingNumber(result));
  };

  const handleCustomerData = () => {
    fetch("https://whatsapp.ghorami.com/api/wm_all_customer.php")
      .then((res) => res.json())
      .then((result) => {
        setAllCustomer(result.slice(0, 10));
      });
  };
  const handleBotData = () => {
    fetch("https://whatsapp.ghorami.com/api/chatbot_api/get_all_item.php")
      .then((res) => res.json())
      .then((result) => {
        setAllBot(result);
      });
  };

  const handleContactGroupData = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    console.log("newObject:", parsedObject);
    console.log("newObject?.SopnoID:", parsedObject?.SopnoID);
    fetch(
      `https://whatsapp.ghorami.com/api/chat_contact/get_all_contactlist.php?user_id=${parsedObject?.SopnoID}`
    )
      .then((res) => res.json())
      .then((result) => {
        setContactGroup(result.slice(0, 10));
      });
  };

  const handleSearchCustomer = (value) => {
    const formData = new FormData();
    formData.append("query", value);

    fetch(
      `https://whatsapp.ghorami.com/api/wm_check_customer.php?customer_id=${value}`
    )
      .then((res) => res.json())
      .then((result) => {
        setSelectedCustomer(result[0]);
        setSearchFound(true);
        console.log("resultc:", result[0]);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const handleAddNewButtons = () => {
    if (regNumBtn) {
      setRegNumAddNewBtn(true);
      setPaymentAddNewBtn(false);
      setRegNumBtn(false);
      // setPaymentBtn(false);
    } else if (paymentBtn) {
      setPaymentAddNewBtn(true);
      setRegNumAddNewBtn(false);
      setPaymentBtn(false);

      // setRegNumBtn(false);
    } else {
      handleAddNewBtn(true);
      setSelectedTemplate(false);
      setRegNumBtn(false);
      setPaymentBtn(false);
    }
  };

  const handleSelectedCustomer = (value) => {
    setSelectedCustomer(value);
    let initialPhone = value.MobileNumber;
    let finalPhone;
    if (initialPhone && initialPhone.length < 11) {
      finalPhone = initialPhone;
    } else {
      finalPhone = initialPhone;
      // finalPhone = "88" + initialPhone;
    }

    let newPostInfo = postInfo;
    newPostInfo.receiverN = finalPhone;
    setPostInfo({ ...newPostInfo });
  };
  const handleTemplateMessageSave = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let updateInfo = templateMessageBotInfo;
    updateInfo.user_id = parsedObject?.SopnoID;
    updateInfo.refer = referState;
    console.log("updateInfo:", updateInfo);
    const formData = new FormData();
    formData.append("new_systemplate", JSON.stringify(updateInfo));

    fetch(
      "https://whatsapp.ghorami.com/api/chatbot_api/wm_auto_template_new.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("systemMessagePostBot:", result);
      })
      .catch((error) => {
        console.log("messagePost=> ", error);
      });
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handleMessageBtn = (value) => {
    setMessageBtn(value);
  };
  const handleAutoBotBtn = (value) => {
    setAutoBotBtn(value);
  };
  const handleCampaignBtn = (value) => {
    setCampaignBtn(value);
  };
  const handleAddNewBtn = (value) => {
    setAddNewBtn(value);
  };

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={0.5}>
          <LeftBar
            setTempDetailsBtn={setTempDetailsBtn}
            setLeftBarBtn={setLeftBarBtn}
            setSelectedCustomer={setSelectedCustomer}
            handleContactGroupData={handleContactGroupData}
            setRegNumBtn={setRegNumBtn}
            setPaymentBtn={setPaymentBtn}
            handleMessageBtn={handleMessageBtn}
            handleAutoBotBtn={handleAutoBotBtn}
            handleCampaignBtn={handleCampaignBtn}
            setAddNewBtn={setAddNewBtn}
            setRegNumAddNewBtn={setRegNumAddNewBtn}
            setPaymentAddNewBtn={setPaymentAddNewBtn}
            setNewContactBtn={setNewContactBtn}
            setCampaignDetailsBtn={setCampaignDetailsBtn}
            setCreateCampaignBtn={setCreateCampaignBtn}
            setTempUpdate={setTempUpdate}
            render={render}
            setRender={setRender}
          />
        </Grid>
        {leftBarBtn == "message" && (
          <>
            <Grid item xs={2.5}>
              <Inbox
                render={render}
                setRender={setRender}
                campaignDetailsList={campaignDetailsList}
                allCustomer={allCustomer}
                allBot={allBot}
                setReferState={setReferState}
                messageBtn={messageBtn}
                setListReplyBtn={setListReplyBtn}
                autoBotBtn={autoBotBtn}
                campaignBtn={campaignBtn}
                regNumBtn={regNumBtn}
                paymentBtn={paymentBtn}
                newContactBtn={newContactBtn}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                setSelectedTemplate={setSelectedTemplate}
                searchFound={searchFound}
                campaignDetailsBtn={campaignDetailsBtn}
                setStartCampaignInfo={setStartCampaignInfo}
                setInputField={setInputField}
                setToggle={setToggle}
                setTemplate={setTemplate}
                setCreateCampaignBtn={setCreateCampaignBtn}
                setCampaignDetailsBtn={setCampaignDetailsBtn}
                handleCampaignNumber={handleCampaignNumber}
                handleCampaignWaitingNumber={handleCampaignWaitingNumber}
                setSearchFound={setSearchFound}
                setRegNumBtn={setRegNumBtn}
                setConversation={setConversation}
                handleSelectedCustomer={handleSelectedCustomer}
                handleSearchCustomer={handleSearchCustomer}
                handleCustomerData={handleCustomerData}
                handleBotData={handleBotData}
                setNewContactBtn={setNewContactBtn}
                setRegNumAddNewBtn={setRegNumAddNewBtn}
                setPaymentAddNewBtn={setPaymentAddNewBtn}
                setPaymentBtn={setPaymentBtn}
                setAddNewBtn={setAddNewBtn}
                handleMessageBtn={handleMessageBtn}
                handleAutoBotBtn={handleAutoBotBtn}
                handleCampaignBtn={handleCampaignBtn}
                handleCustomerResponse={handleCustomerResponse}
                handleBotResponse={handleBotResponse}
                handleTemplateList={handleTemplateList}
                handleCampaignDetailsList={handleCampaignDetailsList}
                handleCampaignDetails={handleCampaignDetails}
              />
            </Grid>
            <Grid item xs={9}>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="center"
                sx={{ boxSizing: "border-box" }}
              >
                <Box
                  sx={{ width: "75%", transition: "width .5s" }}
                  className={toggle ? "collapse" : ""}
                >
                  <Conversation
                    setSelectedTemplate={setSelectedTemplate}
                    selectedTemplate={selectedTemplate}
                    setListReplyBtn={setListReplyBtn}
                    listReplyBtn={listReplyBtn}
                    referState={referState}
                    handleBotData={handleBotData}
                    handleToggle={handleToggle}
                    toggle={toggle}
                    messageBtn={messageBtn}
                    autoBotBtn={autoBotBtn}
                    campaignBtn={campaignBtn}
                    addNewBtn={addNewBtn}
                    regNumBtn={regNumBtn}
                    paymentBtn={paymentBtn}
                    createCampaignBtn={createCampaignBtn}
                    regNumAddNewBtn={regNumAddNewBtn}
                    paymentAddNewBtn={paymentAddNewBtn}
                    newContactBtn={newContactBtn}
                    postInfo={postInfo}
                    leftBarBtn={leftBarBtn}
                    selectedCustomer={selectedCustomer}
                    campaignDetailsBtn={campaignDetailsBtn}
                    startCampaignInfo={startCampaignInfo}
                    setStartCampaignInfo={setStartCampaignInfo}
                    inputField={inputField}
                    dropDownBtn={dropDownBtn}
                    setDropDownBtn={setDropDownBtn}
                    selectedBtn={selectedBtn}
                    setSelectedBtn={setSelectedBtn}
                    setInputField={setInputField}
                    setCampaignDetailsBtn={setCampaignDetailsBtn}
                    handleAddNewButtons={handleAddNewButtons}
                    setNewContactBtn={setNewContactBtn}
                    setPostInfo={setPostInfo}
                    setRegNumBtn={setRegNumBtn}
                    setPaymentBtn={setPaymentBtn}
                    setAddNewBtn={setAddNewBtn}
                    setRegNumAddNewBtn={setRegNumAddNewBtn}
                    setPaymentAddNewBtn={setPaymentAddNewBtn}
                    handleMessageBtn={handleMessageBtn}
                    handleAutoBotBtn={handleAutoBotBtn}
                    handleCampaignBtn={handleCampaignBtn}
                    setCreateCampaignBtn={setCreateCampaignBtn}
                    handleAddNewBtn={handleAddNewBtn}
                    handleCustomerData={handleCustomerData}
                    regNumberInfo={regNumberInfo}
                    setRegNumberInfo={setRegNumberInfo}
                    customerResponse={customerResponse}
                    botResponse={botResponse}
                    templateList={templateList}
                    campaignDetailsList={campaignDetailsList}
                    campaignDetails={campaignDetails}
                    handleCustomerResponse={handleCustomerResponse}
                    handleBotResponse={handleBotResponse}
                    handleTemplateList={handleTemplateList}
                    handleCampaignDetailsList={handleCampaignDetailsList}
                    handleCampaignDetails={handleCampaignDetails}
                    templateMessageBotInfo={templateMessageBotInfo}
                    setTemplateMessageBotInfo={setTemplateMessageBotInfo}
                    handleTemplateMessageSave={handleTemplateMessageSave}
                    setConversation={setConversation}
                    conversation={conversation}
                    template={template}
                    setTemplate={setTemplate}
                    render={render}
                    setRender={setRender}
                    accountNumber={accountNumber}
                    setAccountNumber={setAccountNumber}
                  />
                </Box>
                <Box
                  sx={{
                    width: "25%",
                    transition: "width .5s",
                    overflow: "hidden",
                  }}
                  className={toggle ? "shift" : ""}
                >
                  <RightBar
                    handleToggle={handleToggle}
                    selectedCustomer={selectedCustomer}
                    campaignBtn={campaignBtn}
                    campaignNumber={campaignNumber}
                    campaignWaitingNumber={campaignWaitingNumber}
                    createCampaignBtn={createCampaignBtn}
                    startCampaignInfo={startCampaignInfo}
                    inputField={inputField}
                    setInputField={setInputField}
                  />
                </Box>
              </Stack>
            </Grid>
          </>
        )}
        {leftBarBtn == "contact" && (
          <>
            <Grid item xs={2.5}>
              <ContactGroup
                allCustomer={allCustomer}
                contactGroup={contactGroup}
                setReferState={setReferState}
                messageBtn={messageBtn}
                setShowTable={setShowTable}
                autoBotBtn={autoBotBtn}
                campaignBtn={campaignBtn}
                regNumBtn={regNumBtn}
                paymentBtn={paymentBtn}
                newContactBtn={newContactBtn}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                setNewContactListBtn={setNewContactListBtn}
                searchFound={searchFound}
                setSearchFound={setSearchFound}
                setRegNumBtn={setRegNumBtn}
                handleSelectedCustomer={handleSelectedCustomer}
                handleSearchCustomer={handleSearchCustomer}
                handleCustomerData={handleCustomerData}
                handleContactGroupData={handleContactGroupData}
                setNewContactBtn={setNewContactBtn}
                setRegNumAddNewBtn={setRegNumAddNewBtn}
                setPaymentAddNewBtn={setPaymentAddNewBtn}
                setPaymentBtn={setPaymentBtn}
                setAddNewBtn={setAddNewBtn}
                handleMessageBtn={handleMessageBtn}
                handleAutoBotBtn={handleAutoBotBtn}
                handleCampaignBtn={handleCampaignBtn}
              />
            </Grid>
            <Grid item xs={9}>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="center"
                sx={{ boxSizing: "border-box" }}
              >
                <Box
                  sx={{ width: "75%", transition: "width .5s" }}
                  className={toggle ? "collapse" : ""}
                >
                  <ContactMainSection
                    leftBarBtn={leftBarBtn}
                    referState={referState}
                    handleBotData={handleBotData}
                    handleToggle={handleToggle}
                    messageBtn={messageBtn}
                    autoBotBtn={autoBotBtn}
                    campaignBtn={campaignBtn}
                    addNewBtn={addNewBtn}
                    showTable={showTable}
                    regNumBtn={regNumBtn}
                    paymentBtn={paymentBtn}
                    regNumAddNewBtn={regNumAddNewBtn}
                    paymentAddNewBtn={paymentAddNewBtn}
                    newContactBtn={newContactBtn}
                    selectedCustomer={selectedCustomer}
                    handleAddNewButtons={handleAddNewButtons}
                    setNewContactBtn={setNewContactBtn}
                    newContactListBtn={newContactListBtn}
                    setNewContactListBtn={setNewContactListBtn}
                    setRegNumBtn={setRegNumBtn}
                    setPaymentBtn={setPaymentBtn}
                    setAddNewBtn={setAddNewBtn}
                    setRegNumAddNewBtn={setRegNumAddNewBtn}
                    setPaymentAddNewBtn={setPaymentAddNewBtn}
                    handleMessageBtn={handleMessageBtn}
                    handleAutoBotBtn={handleAutoBotBtn}
                    handleCampaignBtn={handleCampaignBtn}
                    handleAddNewBtn={handleAddNewBtn}
                    regNumberInfo={regNumberInfo}
                    setRegNumberInfo={setRegNumberInfo}
                  />
                </Box>
                <Box
                  sx={{
                    width: "25%",
                    transition: "width .5s",
                    overflow: "hidden",
                  }}
                  className={toggle ? "shift" : ""}
                >
                  <RightBar
                    handleToggle={handleToggle}
                    selectedCustomer={selectedCustomer}
                  />
                </Box>
              </Stack>
            </Grid>
          </>
        )}
        {(leftBarBtn == "dashboard" || !leftBarBtn) && (
          <Grid item xs={11.5}>
            <Box sx={{ height: "100vh", overflow: "auto" }}>
              <Dashboard
                addNewBtn={addNewBtn}
                templateMessageBotInfo={templateMessageBotInfo}
                setTemplateMessageBotInfo={setTemplateMessageBotInfo}
                handleTemplateMessageSave={handleTemplateMessageSave}
                setAddNewBtn={setAddNewBtn}
                referState={referState}
              />
            </Box>
          </Grid>
        )}
        {leftBarBtn == "billing" && (
          <Grid item xs={11.5}>
            <Box sx={{ height: "100vh", overflow: "auto" }}>
              <Billing render={render} setRender={setRender} />
            </Box>
          </Grid>
        )}
        {leftBarBtn == "template" && (
          <Grid item xs={11.5}>
            <Box sx={{ height: "100vh", overflow: "auto" }}>
              <Template
                render={render}
                addNewBtn={addNewBtn}
                leftBarBtn={leftBarBtn}
                setAddNewBtn={setAddNewBtn}
                handleAddNewButtons={handleAddNewButtons}
                referState={referState}
                setTemplateMessageBotInfo={setTemplateMessageBotInfo}
                templateMessageBotInfo={templateMessageBotInfo}
                handleTemplateMessageSave={handleTemplateMessageSave}
                setTempDetailsBtn={setTempDetailsBtn}
                setLeftBarBtn={setLeftBarBtn}
                tempDetailsBtn={tempDetailsBtn}
                selectedTempId={selectedTempId}
                setSelectedTempId={setSelectedTempId}
                setTempUpdate={setTempUpdate}
                tempUpdate={tempUpdate}
              />
            </Box>
          </Grid>
        )}
        {leftBarBtn == "setting" && (
          <>
            <Grid item xs={2.5}>
              <LeftSide
                setLeftSideButton={setLeftSideButton}
                leftSideButton={leftSideButton}
                setAddNewBtn={setAddNewBtn}
              />
            </Grid>
            <Grid item xs={9}>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="center"
                sx={{ boxSizing: "border-box" }}
              >
                <Box sx={{ width: "100%", transition: "width .5s" }}>
                  <MainSecSettings
                    addNewBtn={addNewBtn}
                    setAddNewBtn={setAddNewBtn}
                    leftSideButton={leftSideButton}
                  />
                </Box>
              </Stack>
            </Grid>
          </>
        )}
        {leftBarBtn == "developer" && (
          <>
            <Grid item xs={2.5}>
              <LeftSideDev
                setLeftSideButton={setLeftSideButton}
                leftSideButton={leftSideButton}
                setAddNewBtn={setAddNewBtn}
              />
            </Grid>
            <Grid item xs={9}>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="center"
                sx={{ boxSizing: "border-box" }}
              >
                <Box sx={{ width: "100%", transition: "width .5s" }}>
                  <MainSecDev
                    addNewBtn={addNewBtn}
                    setAddNewBtn={setAddNewBtn}
                    leftSideButton={leftSideButton}
                    setLeftSideButton={setLeftSideButton}
                  />
                </Box>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default WhatsApp;
