import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";

const Preview = ({ startCampaignInfo, inputField }) => {
  const [templateDetail, setTemplateDetail] = useState({});
  console.log("templateDexxxztail:", templateDetail);
  console.log("startCampaignvcvcvInfo:", startCampaignInfo);
  useEffect(() => {
    fetch(
      `https://whatsapp.ghorami.com/api/chat_campaign/get_template_details.php?template_id=${inputField?.template}`
    )
      .then((res) => res.json())
      .then((result) => setTemplateDetail(result[0]));
  }, [inputField?.template]);
  return (
    <Stack
      alignItems="flex-start"
      justifyContent="center"
      spacing={1}
      sx={{ padding: "10px" }}
    >
      {inputField?.campaign_name && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            color: "black",
          }}
        >
          Name: {inputField?.campaign_name}
        </Typography>
      )}
      {startCampaignInfo?.contact_group && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            // fontWeight: 600,
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Contact Group : {startCampaignInfo?.contact_group}
        </Typography>
      )}
      {(inputField?.max_age || inputField?.min_age) && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            // fontWeight: 600,
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Age limit: {inputField?.min_age} - {inputField?.max_age}
        </Typography>
      )}
      {inputField?.gender && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Gender: {inputField?.gender}
        </Typography>
      )}
      {startCampaignInfo?.country && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Country : {startCampaignInfo?.country}
        </Typography>
      )}
      {startCampaignInfo?.region && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Region : {startCampaignInfo?.region}
        </Typography>
      )}
      {startCampaignInfo?.profession && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Profession : {startCampaignInfo?.profession}
        </Typography>
      )}
      {inputField?.language && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Language : {inputField?.language}
        </Typography>
      )}

      {inputField?.set_time && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Time : {inputField?.set_time}
        </Typography>
      )}
      {inputField?.set_Date && (
        <Typography
          variant="p"
          sx={{
            fontSize: "10pt",
            //   lineHeight: "8px",
            textAlign: "left",
            color: "black",
          }}
        >
          Date : {inputField?.set_Date}
        </Typography>
      )}
      {inputField?.template && (
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="flex-end"
          justifyContent="left"
          sx={{ marginY: "20px" }}
        >
          {/* <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: "30px", height: "30px" }}
          /> */}
          <Stack
            spacing={0.3}
            alignItems="flex-start"
            justifyContent="right"
            sx={{
              // padding: "15px",
              color: "white",

              borderRadius: "8px",
            }}
          >
            <Stack alignItems={"flex-start"} sx={{ paddingBottom: "5px" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9.5pt",
                  lineHeight: "8px",
                  color: "black",
                }}
              >
                Title - {templateDetail?.tem_name}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              alignItems={"flex-start"}
              sx={{
                bgcolor: "White",
                color: "black",
                padding: "15px",
                width: "auto",
                borderRadius: "4px",
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  lineHeight: "15px",

                  textAlign: "left",
                }}
              >
                {templateDetail?.tem_header}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  lineHeight: "15px",
                  textAlign: "left",
                }}
              >
                {templateDetail?.message}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              sx={{
                width: "100%",
              }}
            >
              {templateDetail?.button &&
                JSON.parse(templateDetail?.button)?.map((element) => (
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      width: "49.5%",
                      marginX: "1px",
                      fontSize: "7pt",
                      color: "black",
                      bgcolor: "white",
                      border: "1px solid gray",
                      padding: "4px !important",
                      // "&:hover": { border: "1px solid gray" },
                    }}
                  >
                    {element?.btn_title}
                  </Button>
                ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Preview;
