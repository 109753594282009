import { Reply } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import SceletonChatLeft from "../../MessageTextBox/SceletonChatLeft";
import SceletonChatRight from "../../MessageTextBox/SceletonChatRight";

const templateType = [
  "interactive",
  "replay_action",
  "text_type",
  "media_type",
  "location",
  // "contact",
  // "replay_only",
  // "delete",
];
const OneConversation = ({
  eachItem,
  template,
  setAddNewBtn,
  setSelectedTemplate,
  selectedTemplate,
  botResponse,
  setTemplate,
  setConversation,
  referState,
  listReplyBtn,
  setListReplyBtn,
  setSelectedReplyBtnInfo,
  selectedCustomer,
  dropDownBtn,
  setDropDownBtn,
  selectedBtn,
  setSelectedBtn,
  handleBotResponse,
  handleOpen,
  setDeleteItem,
}) => {
  console.log("hoiseee");
  const [templateDetails, setTemplateDetails] = useState({});
  console.log("templateDetails:", templateDetails);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_template_details.php?template_name=${eachItem?.template_refer}&template_type=${eachItem?.template_type}&panel_id=${parsedObject?.SopnoID}&accn=${parsedObject?.accn_id}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("result55:", result);
        console.log("result55:", "resultAsce");
        setTemplateDetails(result?.[0]);
        // setBotResponse(result);
        // console.log("result5555:", result);
      });
  }, [eachItem?.template_refer, eachItem?.template_name]);

  return (
    <Box>
      <Stack
        spacing={1}
        direction={"row"}
        alignItems="flex-end"
        justifyContent="right"
        sx={{ marginY: "20px" }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: "8pt", fontWeight: 600, color: "gray" }}
        >
          {templateDetails?.timestamp?.split(" ")?.[1]}
        </Typography>
        <Stack spacing={1} alignItems="flex-end" justifyContent="right">
          {" "}
          <Typography
            variant="p"
            sx={{
              fontSize: "9.5pt",
              fontWeight: 700,
              lineHeight: "8px",
              color: "gray",
            }}
          >
            Request
          </Typography>
          <Stack
            spacing={1}
            alignItems="flex-start"
            justifyContent="right"
            sx={{
              paddingX: "15px",
              paddingY: "10px",
              bgcolor: "#c4e1eb",
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "10pt", lineHeight: "15px", fontWeight: 600 }}
            >
              {templateDetails?.request}
              {/* {console.log("?.request:", templateDetails)} */}
            </Typography>
          </Stack>
        </Stack>

        <Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
          sx={{ width: "30px", height: "30px" }}
        />
      </Stack>

      {templateDetails?.message ? (
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="flex-end"
          justifyContent="left"
          sx={{ marginY: "20px" }}
        >
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: "30px", height: "30px" }}
          />
          <Stack
            spacing={0.3}
            alignItems="flex-start"
            justifyContent="right"
            sx={{
              // padding: "15px",
              color: "white",

              borderRadius: "8px",
            }}
          >
            <Stack alignItems={"flex-start"} sx={{ paddingBottom: "5px" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9.5pt",
                  fontWeight: 700,
                  lineHeight: "8px",
                  color: "black",
                }}
              >
                Response - {templateDetails?.tem_name}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              alignItems={"flex-start"}
              sx={{
                bgcolor: "White",
                color: "black",
                padding: "15px",
                width: "360px",
                borderRadius: "4px",
                boxSizing: "border-box",
                position: "relative",
              }}
            >
              <IconButton
                size="small"
                sx={{ position: "absolute", top: "2px", right: "2px" }}
                onClick={() => {
                  dropDownBtn
                    ? setDropDownBtn(false)
                    : setDropDownBtn(templateDetails?.id);
                  setSelectedReplyBtnInfo(templateDetails);
                  setSelectedBtn(false);
                }}
              >
                <Reply sx={{ fontSize: "14pt" }} />
              </IconButton>
              {dropDownBtn === templateDetails?.id && (
                <Box
                  sx={{
                    position: "absolute",
                    bgcolor: "white",
                    top: "-8px",
                    right: "0px",
                    width: "30%",
                    transform: "translate(105%, 0%)",
                  }}
                >
                  {templateType?.map(
                    (element, index) =>
                      ((templateDetails?.button &&
                        JSON.parse(templateDetails?.button)?.filter(
                          (btn) => btn?.btn_type === "QUICK_REPLY"
                        )?.length <= 0) ||
                        !templateDetails?.button) && (
                        <>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              width: "100%",
                              marginX: "1px",
                              fontSize: "7pt",
                              color: "black",
                              border: "1px solid gray",
                              padding: "4px !important",
                            }}
                            onClick={() => {
                              if (element === "delete") {
                                handleOpen();
                                setDeleteItem(templateDetails);
                              } else {
                                setAddNewBtn(false);
                                setSelectedTemplate(element);
                                setTemplate(false);
                              }
                            }}
                          >
                            {element}
                            {console.log("elementwww:", element)}
                          </Button>
                        </>
                      )
                  )}
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "100%",
                      marginX: "1px",
                      fontSize: "7pt",
                      color: "black",
                      border: "1px solid gray",
                      padding: "4px !important",
                    }}
                    onClick={() => {
                      handleOpen();
                      setDeleteItem(templateDetails);
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              )}
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  lineHeight: "15px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                {templateDetails?.tem_header}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  lineHeight: "15px",
                  textAlign: "left",
                }}
              >
                {templateDetails?.message}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"flex-start"}
              sx={{
                width: "100%",
              }}
            >
              {templateDetails?.button &&
                JSON.parse(templateDetails?.button)?.map((btn) => (
                  <Stack
                    alignItems={"center"}
                    sx={{ width: "49.5%" }}
                    spacing={1}
                  >
                    {" "}
                    <Button
                      size="small"
                      variant={
                        selectedBtn?.btn_value === btn?.btn_value
                          ? "contained"
                          : "outlined"
                      }
                      color="success"
                      sx={{
                        width: "100%",
                        marginX: "1px",
                        fontSize: "7pt",
                        color:
                          selectedBtn?.btn_value === btn?.btn_value
                            ? "white"
                            : "black",
                        // bgcolor: "white",
                        border: "1px solid gray",
                        padding: "4px !important",
                      }}
                      onClick={() => {
                        if (btn?.btn_type === "LIST_REPLY") {
                          setSelectedReplyBtnInfo({
                            ...btn,
                            ...templateDetails,
                          });
                          setListReplyBtn(true);
                          setTemplate(false);
                          setConversation(false);
                        } else if (btn?.btn_type === "QUICK_REPLY") {
                          selectedBtn?.btn_value === btn?.btn_value
                            ? setSelectedBtn(false)
                            : setSelectedBtn(btn);
                          setDropDownBtn(false);
                        }
                      }}
                    >
                      {btn?.btn_title}
                    </Button>
                    {selectedBtn?.btn_value === btn?.btn_value && template && (
                      <Stack
                        sx={{
                          width: "100%",
                        }}
                      >
                        {templateType?.map((element, index) => (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              textTransform: "capitalize",
                              width: "100%",
                              marginX: "1px",
                              fontSize: "7pt",
                              color: "black",
                              bgcolor: "white",
                              border: "1px solid gray",
                              padding: "4px !important",
                            }}
                            onClick={() => {
                              if (element === "delete") {
                                handleOpen();
                              } else {
                                setAddNewBtn(false);
                                setSelectedTemplate(element);
                                setTemplate(false);
                              }
                            }}
                          >
                            {element}
                          </Button>
                        ))}
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            width: "100%",
                            marginX: "1px",
                            fontSize: "7pt",
                            bgcolor: "white",
                            color: "black",
                            border: "1px solid gray",
                            textTransform: "capitalize",
                            padding: "4px !important",
                          }}
                          onClick={() => {
                            handleOpen();
                            setDeleteItem(templateDetails);
                          }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                ))}
            </Stack>
          </Stack>

          <Typography
            variant="p"
            sx={{ fontSize: "8pt", fontWeight: 600, color: "gray" }}
          >
            {templateDetails?.timestamp?.split(" ")?.[1]}
          </Typography>
        </Stack>
      ) : (
        <SceletonChatLeft />
      )}
    </Box>
  );
};

export default OneConversation;
