import BKash from "../wallet_pic/bKash.png";
import Nagad from "../wallet_pic/nagad.png";
import upay from "../wallet_pic/upay_logo.png";
// import overdraft from "../wallet_pic/overDraft.jpg";
import oKwallet from "../wallet_pic/okWallet.png";
import Rocket from "../wallet_pic/rocket.png";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
const Wallet = ({
  amount,
  note,
  handleClose,
  setToggleDisplay,
  setselectedMethod,
}) => {
  const navigate = useNavigate();
  const ImagesInfo = [
    {
      name: "BKash",
      image: BKash,
      id: "2",
    },
    {
      name: "Nagad",
      image: Nagad,
      id: "1",
    },
    // {
    //   name: "Rocket",
    //   image: Rocket,
    //   id: "3",
    // },
    // { name: "oKwallet", image: oKwallet, id: "4" },
    // { name: "upay", image: upay, id: "10" },
  ];
  const user = JSON.parse(localStorage.getItem("user"));
  const handleCashIN = (type) => {
    const formData = new FormData();
    formData.append("pay_refer", "");
    formData.append("c_id", user?.umobile);
    formData.append("paytype", "4");
    formData.append("poster", user?.sopnoid);
    formData.append("acAmount", amount);
    formData.append("acNote", note);
    formData.append("receiver", "self_");
    formData.append("c_refer", user?.c_refer);
    formData.append("billing_state", "1");
    formData.append("acType", type);
    axios
      .post(
        `${user.master_url}/profile/ac_bil/api_web/new/pay_new.php`,
        formData
      )
      .then((res) => {
        console.log(res.data);
        if (res.data[0]?.trnxid && res.data[0]?.verfify_type) {
          swal({
            title: "Success",
            text: "Your transaction is successful",
            icon: "success",
            buttons: true,
          }).then((value) => {
            if (value) {
              let url;
              const formData = new FormData();
              if (res.data[0]?.ac_type === "1") {
                url = "https://173.212.230.192:2030/service/index.php";
              } else if (res.data[0]?.ac_type === "2") {
                url = "https://173.212.230.192:2030/bkash/payment.php";
              } else if (res.data[0]?.ac_type === "3") {
                url = "https://173.212.230.192:2030/service/rocket/index.php";
              } else if (res.data[0]?.ac_type === "4") {
                url = "https://173.212.230.192:2030/service/okwallet/index.php";
              } else if (res.data[0]?.ac_type === "10") {
                url = "https://173.212.230.192:2030/service/upay/index.php";
              }

              formData.append("adsrla", res.data[0]?.trnxid);
              formData.append("amnt", res.data[0]?.amount);
              formData.append("email", user?.sopnoid);
              formData.append("cname", user?.uname);
              formData.append("type", res.data[0]?.ac_type);
              axios.post(url, formData).then((res) => {
                // console.log(res.data);
                if (res.data === "success") {
                  navigate("/");
                }
              });
            }
          });
        } else {
          swal("Error", "Something went wrong", "error");
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {ImagesInfo?.map((item) => {
        return (
          item.image && (
            <img
              key={item?.id}
              src={item?.image}
              alt=""
              style={{
                width: "16%",
                margin: "0 5px",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => {
                // handleCashIN(item?.id);
                setselectedMethod(item);
                setToggleDisplay(true);
              }}
            />
          )
        );
      })}
    </Box>
  );
};

export default Wallet;
