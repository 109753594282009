import { Clear, HighlightOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const TemplateMessageFormBot = ({
  handleBotData,
  templateMessageBotInfo,
  setTemplateMessageBotInfo,
  setAddNewBtn,
  referState,
  accountNumber,
  selectedCustomer,
}) => {
  const focus = useRef(null);
  const focus2 = useRef(null);
  const focus1 = useRef(null);
  const focus3 = useRef(null);
  const focus4 = useRef(null);
  const focus5 = useRef(null);
  const btn1 = useRef(null);
  const btn2 = useRef(null);
  const btn3 = useRef(null);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  console.log("personName:", personName);
  console.log("templateMeRRRE:", templateMessageBotInfo);

  const [inputField, setInputField] = useState({});
  console.log("inputField:", inputField);
  const [buttonFields, setButtonFields] = useState([{ id: 0 }]);
  const [nameAlert, setNameAlert] = useState(false);
  const [variable, setVariable] = useState(1);
  const [variableArray, setVariableArray] = useState([1]);
  console.log("variableArray:", variableArray);
  const [buttonAlert, setButtonAlert] = useState(false);
  const [allPhone_code, setAllPhone_code] = useState([]);

  const [allButton, setAllButton] = useState([]);
  const [botList, setBotList] = useState([]);
  const [tempParam, setTempParam] = useState([]);
  console.log("tempParam:", tempParam);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch("https://whatsapp.ghorami.com/api/chatbot_api/get_all_item.php")
      .then((res) => res.json())
      .then((result) => {
        setBotList(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/get_wap_template_btn.php?poster_id=${parsedObject?.poster}&accn_id=${parsedObject?.accn_id}`
    )
      .then((res) => res.json())
      .then((result) => setAllButton(result));
  }, []);
  useEffect(() => {
    fetch(`https://ghorami.com/profile/login/api_booking/country_list.php`)
      .then((res) => res.json())
      .then((result) => {
        setAllPhone_code(result);
      });
  }, []);

  const replaceAt = (string, index, char) => {
    let a = string.split("");
    a[index] = char;
    return a.join("");
  };
  useEffect(() => {
    let newArr = [];
    let aLength = (templateMessageBotInfo?.details?.match(/{{/g) || [])?.length;
    console.log("aLength:", aLength);

    for (let i = 0; i <= aLength; i++) {
      newArr.push(i + 1);
    }

    setVariableArray([...newArr]);
    let newFieldarr = [];

    if (newArr?.length > 1) {
      newArr?.map((element, index) => {
        newFieldarr?.push({
          id: index,
          variant_no: element,
          variant_value: templateMessageBotInfo?.temp_peram?.[index]
            ? templateMessageBotInfo?.temp_peram?.[index]?.variant_value
            : "",
        });
      });
    }
    console.log("newFieldarr:", newFieldarr);
    newFieldarr.pop();

    setTempParam(newFieldarr);
    console.log(
      "lengthString",
      (templateMessageBotInfo?.details?.match(/{{/g) || []).length
    );
    setVariable(aLength);

    let splitedArray = templateMessageBotInfo?.details?.split("{{");
    console.log("splitedArray1:", splitedArray);
    for (let i = 1; i < splitedArray?.length; i++) {
      console.log("splitedArray[i][0]:", splitedArray[i][3]);
      for (let j = 1; j < newArr?.length; j++) {
        if (i === j) {
          splitedArray[i] = replaceAt(splitedArray[i], 0, "{{" + newArr[j - 1]);
        }
      }
    }
    console.log("splitedArray2:", splitedArray);
    let newTemplateMessageBotInfo = templateMessageBotInfo;
    newTemplateMessageBotInfo.details = splitedArray?.join("");
    setTemplateMessageBotInfo(newTemplateMessageBotInfo);
  }, [variable, templateMessageBotInfo?.details]);

  console.log("variable:", variable);

  const addNewTemplate = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    let updateInfo = templateMessageBotInfo;
    updateInfo.user_id = parsedObject?.SopnoID;
    updateInfo.refer = parsedObject?.accn_id;
    updateInfo.item_title = selectedCustomer?.Name;
    const formData = new FormData();
    formData.append("panel_idc", accountNumber);
    formData.append("new_systemplate", JSON.stringify(updateInfo));

    fetch(
      "https://whatsapp.ghorami.com/api/chatbot_api/wm_auto_template_new.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.[0]?.result === "success") {
          handleClick();
          setAddNewBtn(false);
          handleBotData();
        }
      })
      .catch((error) => {
        console.log("messagePost=> ", error);
      });
  };
  const handleVariable = () => {
    console.log("variable:", variable);
    console.log("variableArray:", variableArray);

    let newTemplateMessageBotInfo = templateMessageBotInfo;
    newTemplateMessageBotInfo.details =
      newTemplateMessageBotInfo.details + "{{" + variableArray[variable] + "}}";
    setTemplateMessageBotInfo(newTemplateMessageBotInfo);
    setVariableArray([...variableArray]);
    focus5.current.focus();
  };

  const handleFormChange = (index, event) => {
    if (!/^[^\s]+$/.test(event.target.value)) {
      setButtonAlert("*Space not allowed");
      let newtemplateMessageBotInfo = templateMessageBotInfo;
      newtemplateMessageBotInfo.buttons = [];
      setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    } else if (/^[^\s]+$/.test(event.target.value)) {
      setButtonAlert(false);
      fetch(
        `https://whatsapp.ghorami.com/api/chatbot_api/check_button.php?btn_title=${event.target.value}`
      )
        .then((res) => res.json())
        .then((result) => {
          console.log("resultsdsdsd:", result);

          if (result[0]?.id && result) {
            setButtonAlert("*Name Already Exist");
            let newtemplateMessageBotInfo = templateMessageBotInfo;
            newtemplateMessageBotInfo.buttons = [];
            setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
          } else {
            let data = [...buttonFields];
            if (event.target.name === "url_type") {
              data[index].btn_value = "";
            }
            if (event.target.name === "btn_type") {
              data[index] = {};
            }
            if (
              data[index].btn_type === "URL" &&
              data[index].url_type === "dynamic" &&
              event.target.name === "btn_value"
            ) {
              if (
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
                  event.target.value
                )
              ) {
                data[index][event.target.name] =
                  event.target.value.replace(/^\/+|\/+$/g, "") + "/{{1}}";
              } else {
                setButtonAlert("*Invalid url");
                data[index][event.target.name] = "";
              }
            } else if (
              data[index].btn_type === "URL" &&
              data[index].url_type === "static" &&
              event.target.name === "btn_value"
            ) {
              if (
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
                  event.target.value
                )
              ) {
                data[index][event.target.name] = event.target.value;
              } else {
                setButtonAlert("*Invalid url");
                data[index][event.target.name] = "";
              }
            } else {
              data[index][event.target.name] = event.target.value;
            }

            data[index].id = index;
            setButtonFields(data);

            let newtemplateMessageBotInfo = templateMessageBotInfo;
            newtemplateMessageBotInfo.buttons = buttonFields;
            setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
            setButtonAlert(false);
            // }
          }
        });
    }
  };
  const handleVarientFormChange = (index, event) => {
    let data = [...tempParam];

    data[index][event.target.name] = event.target.value;
    data[index].id = index;
    setTempParam(data);

    let newUpdateInfo = templateMessageBotInfo;
    newUpdateInfo.temp_peram = tempParam;
    setTemplateMessageBotInfo({ ...newUpdateInfo });
    setButtonAlert(false);
  };
  const handleDeleteVarient = (variant_no) => {
    let strToReplace = "{{" + variant_no + "}}";
    console.log("strToReplace:", strToReplace);
    let newTemplateMessageBotInfo = templateMessageBotInfo;

    let remaining = tempParam?.filter(
      (element) => element?.variant_no !== variant_no
    );

    newTemplateMessageBotInfo.temp_peram = remaining;

    newTemplateMessageBotInfo.details =
      newTemplateMessageBotInfo?.details?.replace(strToReplace, "");
    console.log(
      "newTemplatd44555:",
      newTemplateMessageBotInfo?.details?.replace(strToReplace, "")
    );
    setTemplateMessageBotInfo({ ...newTemplateMessageBotInfo });
  };

  const handleNameChange = (event) => {
    event.target.value = event.target.value.replace(" ", "_");
    const newtemplateMessageBotInfo = templateMessageBotInfo;
    newtemplateMessageBotInfo[event.target.name] = event.target.value;
    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    setNameAlert(false);
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/check_template_name.php?tem_name=${event.target.value}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resultsdsdsd:", result);

        if (result[0]?.id && result) {
          setNameAlert("*Name Already Exist");
          const newtemplateMessageBotInfo = templateMessageBotInfo;
          newtemplateMessageBotInfo[event.target.name] = "";
          setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
        }
      });
  };

  const addButtonFields = () => {
    let id = Math.floor(Math.random() * 1000000000 + 1);
    let newField = { id: id };
    if (buttonFields.length <= 2) {
      setButtonFields([...buttonFields, newField]);
    } else {
      setButtonFields([...buttonFields]);
    }
  };

  const handleDeleteButtonFields = (id) => {
    let filteredField = buttonFields.filter((element) => element.id != id);
    setButtonFields([...filteredField]);
  };

  const clear = () => {
    setTemplateMessageBotInfo({});
    setInputField({});
    setButtonFields([{ id: 0 }]);
    focus.current.children[0].value = "";
    focus1.current.children[1].value = "";
    focus2.current.children[1].value = "";
    // focus2.current.removeChild(focus2.current.children[0]);
    focus4.current.children[0].value = "";
    btn1.current.children[0].value = "";
    btn2.current.children[0].value = "";
    btn3.current.children[0].value = "";
    focus5.current.value = "";
    console.log("focus.current:", focus2.current.children[1]);
  };
  const handleButtonChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(value);
    const newtemplateMessageBotInfo = templateMessageBotInfo;
    newtemplateMessageBotInfo.request = value.join(", ");
    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
  };
  const handleItemChange = (event) => {
    const newtemplateMessageBotInfo = templateMessageBotInfo;
    const newInputField = inputField;
    newtemplateMessageBotInfo.item_title = event.target.value;
    newInputField.item_title = event.target.value;
    setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
    setInputField({ ...newInputField });
  };

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "item_title") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "Name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "message_type") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "header") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "footer") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "language") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "category") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "details") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      if (event.target.name === "item_title") {
        event.target.value = event.target.value.replace(" ", "_");
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newtemplateMessageBotInfo = templateMessageBotInfo;
        newtemplateMessageBotInfo[event.target.name] = event.target.value;
        setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
      } else {
        const updateField = inputField;
        updateField[event.target.name] = event.target.value;
        updateField.fieldErrorAlert = "";
        setInputField({ ...updateField });

        const newtemplateMessageBotInfo = templateMessageBotInfo;
        newtemplateMessageBotInfo[event.target.name] = event.target.value;
        setTemplateMessageBotInfo({ ...newtemplateMessageBotInfo });
      }
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      setTemplateMessageBotInfo(newField);
    }
  };
  return (
    <Box sx={{ paddingY: "15px", paddingX: "20px" }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        // sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={4}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Message Type
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              spacing={1}
            >
              {" "}
              <Select
                ref={focus2}
                name="message_type"
                onChange={handleChange}
                displayEmpty
                size="small"
                sx={{
                  width: "100%",

                  borderRadius: "6px",

                  fontSize: "9pt",
                }}
              >
                <MenuItem value={"interactive"}>Interactive</MenuItem>
                <MenuItem value={"location"}>Location</MenuItem>
                <MenuItem value={"contacts"}>Contacts</MenuItem>
                <MenuItem value={"reaction"}>Reaction</MenuItem>
                <MenuItem value={"image"}>Media</MenuItem>
                <MenuItem value={"text"}>Text</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Item Title
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              spacing={1}
            >
              {" "}
              <OutlinedInput
                ref={focus}
                type="text"
                name="item_title"
                size="small"
                value={selectedCustomer?.Name}
                placeholder="item"
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  fontSize: "9pt",
                }}
                // onChange={handleChange}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Template for
              </Typography>
              {inputField.fieldErrorAlert === "template_title" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
            </Stack>

            <Select
              multiple
              displayEmpty
              value={personName}
              // name="buttons"
              onChange={handleButtonChange}
              input={<OutlinedInput />}
              size="small"
              placeholder="Request"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select template</em>;
                }

                return selected.join(", ");
              }}
              // MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {allButton?.map((element, index) => (
                <MenuItem
                  key={index}
                  value={element.btn_value}
                  style={getStyles(element.btn_value, personName, theme)}
                >
                  {element.btn_value}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Name
              </Typography>
              {nameAlert && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  {nameAlert}
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus1}
              type="text"
              name="Name"
              size="small"
              placeholder="Name"
              // value={templateMessageBotInfo?.Name}
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleNameChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Language
              </Typography>
            </Stack>

            <Select
              // value={age}
              ref={focus2}
              name="language"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"en_US"}>English</MenuItem>
              <MenuItem value={"bn_BD"}>Bangla</MenuItem>
              <MenuItem value={"ar_UAE"}>Arabic</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "language" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Category
              </Typography>
            </Stack>

            <Select
              // value={age}

              name="category"
              onChange={handleChange}
              displayEmpty
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
            >
              <MenuItem value={"marketing"}>Marketing</MenuItem>
              <MenuItem value={"transactional"}>Transactional</MenuItem>
              <MenuItem value={"OTP"}>OTP</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "category" && (
              <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                *Header Field missing
              </small>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Header
              </Typography>
              {inputField.fieldErrorAlert === "header" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Header Field missing
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus4}
              type="text"
              name="header"
              size="small"
              placeholder="Header"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Info/Details
              </Typography>
              {inputField.fieldErrorAlert === "details" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
              <Button
                size="small"
                sx={{ fontSize: "7pt", color: "black", fontWeight: 700 }}
                onClick={() => {
                  handleVariable();
                }}
              >
                Add variable
              </Button>
            </Stack>

            <TextareaAutosize
              ref={focus5}
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter details about template"
              value={templateMessageBotInfo.details}
              inputProps={{
                maxLength: 800,
              }}
              name="details"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",

                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
            spacing={2}
          >
            <Stack spacing={0.5} sx={{ width: "40%" }}>
              {tempParam?.map((element, index) => (
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                    }}
                  >
                    #{element?.variant_no}
                  </Typography>
                  <OutlinedInput
                    type="text"
                    name="variant_value"
                    size="small"
                    placeholder="Enter variant value"
                    // value={element?.variant_value}
                    onBlur={(event) => handleVarientFormChange(index, event)}
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "9pt",
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleDeleteVarient(element?.variant_no);
                    }}
                  >
                    <Clear sx={{ fontSize: "12pt" }} />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
            {/* {tempParam?.length > 0 && (
              <Stack spacing={0.5} sx={{ width: "15%" }}>
                {tempParam[tempParam?.length - 1]?.variant_value ? (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      // updateVariant();
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    disabled
                    sx={{ textTransform: "capitalize" }}
                  >
                    Update
                  </Button>
                )}
              </Stack>
            )} */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Footer
              </Typography>
              {inputField.fieldErrorAlert === "footer" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *footer Field missing
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={focus4}
              type="text"
              name="footer"
              size="small"
              placeholder="footer"
              sx={{
                width: "100%",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "9pt",
              fontWeight: 500,
              marginLeft: "9px",
              marginTop: "8px",
              marginBottom: "-3px",
              cursor: "pointer",
            }}
            onClick={addButtonFields}
          >
            Add Button +
          </Typography>

          {buttonAlert && (
            <small style={{ color: "#CB0101", fontSize: "8pt" }}>
              {buttonAlert}
            </small>
          )}
        </Stack>
        {buttonFields?.map((input, index) => {
          return (
            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ boxSizing: "border-box" }}
              >
                <Stack
                  alignItems={"flex-start"}
                  sx={{
                    width: "90%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                    }}
                  >
                    Button Type
                  </Typography>
                  <Select
                    // value={age}
                    ref={btn1}
                    type="text"
                    name="btn_type"
                    size="small"
                    placeholder="Type"
                    onChange={(event) => handleFormChange(index, event)}
                    displayEmpty
                    sx={{
                      width: "100%",

                      borderRadius: "6px",

                      fontSize: "9pt",
                    }}
                  >
                    <MenuItem value={"QUICK_REPLY"}>Quick Replay</MenuItem>
                    <MenuItem value={"PHONE_NUMBER"}>
                      Call Phone Number
                    </MenuItem>
                    <MenuItem value={"URL"}>Visit Website</MenuItem>
                    <MenuItem value={"LIST_REPLY"}>List Reply</MenuItem>
                  </Select>
                </Stack>
                <Stack
                  alignItems={"flex-start"}
                  sx={{
                    width: "90%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                    }}
                  >
                    Button Title
                  </Typography>
                  <OutlinedInput
                    ref={btn2}
                    type="text"
                    name="btn_title"
                    size="small"
                    placeholder="Title"
                    sx={{
                      width: "100%",

                      borderRadius: "6px",

                      fontSize: "9pt",
                      boxSizing: "border-box",
                    }}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </Stack>
                {input?.btn_type === "PHONE_NUMBER" && (
                  <Stack
                    alignItems={"flex-start"}
                    sx={{
                      width: "40%",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "8pt",
                        fontWeight: 400,
                      }}
                    >
                      Country
                    </Typography>
                    <Select
                      displayEmpty
                      onChange={(event) => handleFormChange(index, event)}
                      size="small"
                      name="phone_code"
                      placeholder="Request"
                      sx={{
                        width: "100%",
                        borderRadius: "6px",
                        fontSize: "9pt",
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {allPhone_code?.map((element, index) => (
                        <MenuItem key={index} value={element.phone_code}>
                          {element.phone_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                )}
                {input?.btn_type === "URL" && (
                  <Stack
                    alignItems={"flex-start"}
                    sx={{
                      width: "40%",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "8pt",
                        fontWeight: 400,
                      }}
                    >
                      Url Type
                    </Typography>
                    <Select
                      displayEmpty
                      onChange={(event) => handleFormChange(index, event)}
                      size="small"
                      name="url_type"
                      placeholder="Request"
                      sx={{
                        width: "100%",

                        borderRadius: "6px",
                        fontSize: "9pt",
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem key={index} value={"static"}>
                        Static
                      </MenuItem>
                      <MenuItem key={index} value={"dynamic"}>
                        Dynamic
                      </MenuItem>
                    </Select>
                  </Stack>
                )}
                <Stack
                  alignItems={"flex-start"}
                  sx={{
                    width: "90%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                    }}
                  >
                    {input?.btn_type === "PHONE_NUMBER" && "Phone Number"}
                    {input?.btn_type === "URL" && "Web Url"}
                    {(input?.btn_type === "QUICK_REPLY" ||
                      !input?.btn_type ||
                      input?.btn_type === "LIST_REPLY") &&
                      "Buton Value"}
                  </Typography>
                  <OutlinedInput
                    ref={btn3}
                    type={
                      input?.btn_type === "PHONE_NUMBER" ? "number" : "text"
                    }
                    // value={input?.btn_value}
                    name="btn_value"
                    size="small"
                    placeholder="value"
                    sx={{
                      width: "100%",
                      borderRadius: "6px",
                      fontSize: "9pt",
                      boxSizing: "border-box",
                    }}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </Stack>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    handleDeleteButtonFields(input.id);
                  }}
                >
                  <HighlightOff fontSize="inherit" />
                </IconButton>
              </Stack>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                // setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.header &&
            !nameAlert &&
            !buttonAlert &&
            inputField.details &&
            selectedCustomer?.Name &&
            templateMessageBotInfo.Name &&
            inputField.language &&
            inputField.message_type &&
            inputField.category &&
            inputField.footer &&
            buttonFields[0]?.btn_type &&
            buttonFields[0]?.btn_title &&
            buttonFields[0]?.btn_value ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  addNewTemplate();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          This is a success message!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TemplateMessageFormBot;
