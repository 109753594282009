import { Avatar, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const TextBoxBot = ({ botResponse }) => {
  console.log("botResponse:", botResponse);
  return (
    <Box>
      {botResponse?.map(
        (element) => (
          //   element?.b_type == "text" &&
          //   element?.text && (
          <Box>
            {element?.w_from == "8801849225187" && (
              <Stack
                spacing={1}
                direction={"row"}
                alignItems="flex-end"
                justifyContent="right"
                sx={{ marginY: "20px" }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", fontWeight: 700, color: "gray" }}
                >
                  17:12
                </Typography>
                <Stack
                  spacing={1}
                  alignItems="flex-start"
                  justifyContent="right"
                  sx={{
                    padding: "15px",
                    color: "white",
                    bgcolor: "#1976d2",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9.5pt",
                      fontWeight: 700,
                      lineHeight: "8px",
                      color: "white",
                    }}
                  >
                    Request
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", lineHeight: "15px" }}
                  >
                    {element?.message}
                  </Typography>
                </Stack>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: "30px", height: "30px" }}
                />
              </Stack>
            )}

            {element?.w_to == "8801849225187" && (
              <Stack
                spacing={1}
                direction={"row"}
                alignItems="flex-end"
                justifyContent="left"
                sx={{ marginY: "20px" }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: "30px", height: "30px" }}
                />
                <Stack
                  spacing={1}
                  alignItems="flex-start"
                  justifyContent="right"
                  sx={{
                    padding: "15px",
                    bgcolor: "#c4e1eb",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9.5pt",
                      fontWeight: 700,
                      lineHeight: "8px",
                      color: "gray",
                    }}
                  >
                    Response
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", lineHeight: "15px" }}
                  >
                    {element?.response}
                  </Typography>
                </Stack>
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", fontWeight: 700, color: "gray" }}
                >
                  17:12
                </Typography>
              </Stack>
            )}
          </Box>
        )
        //   )
      )}
    </Box>
  );
};

export default TextBoxBot;
