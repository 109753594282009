import { CloudUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const AddNewContactListForm = ({
  regNumberInfo,
  setRegNumberInfo,
  setAddNewBtn,
  setNewContactListBtn,
}) => {
  console.log("regNumberInfo:", regNumberInfo);
  const [inputField, setInputField] = useState({ state: "Active" });
  const [pdfAlertMessage, setPdfAlertMessage] = useState("");
  const [attachment, setAttachment] = useState("");

  const handleNewContactSubmit = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    const formData = new FormData();
    formData.append("Name", regNumberInfo.contactlist_name);
    formData.append("details", regNumberInfo.details);
    formData.append("csv", regNumberInfo.csv);
    formData.append("state", regNumberInfo.state);
    formData.append("p_user", parsedObject?.SopnoID);

    fetch(
      "https://whatsapp.ghorami.com/api/chat_contact/new_contact_list.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        res.json();
      })

      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("newOrderApiError=> ", error));
  };

  const clear = () => {
    setNewContactListBtn(false);
    setRegNumberInfo({});
    setInputField({});
  };
  const handleAttachment = (e) => {
    if (e.target.files[0].type != "text/csv") {
      e.target.value = null;
      setPdfAlertMessage("Please upload CSV file only!");
    } else {
      setAttachment(URL.createObjectURL(e.target.files[0]));
      let newInfo = regNumberInfo;
      newInfo.csv = e.target.files[0];
      setRegNumberInfo({ ...newInfo });
      setPdfAlertMessage("");
    }
  };

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "state") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "details") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "contactlist_name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newstartCampaignInfo = regNumberInfo;
      newstartCampaignInfo[event.target.name] = event.target.value;
      setRegNumberInfo({ ...newstartCampaignInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                }}
              >
                Contactlist Name
              </Typography>
              {inputField.fieldErrorAlert === "name" && (
                <small style={{ color: "#CB0101" }}>
                  * Contactlist Name field empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              size="small"
              name="contactlist_name"
              placeholder="Name"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "11pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                }}
              >
                Info/Details
              </Typography>
              {inputField.fieldErrorAlert === "details" && (
                <small style={{ color: "#CB0101", fontSize: "8pt" }}>
                  *Title field missing
                </small>
              )}
            </Stack>

            <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter about your Service"
              // value={about}
              name="details"
              className="customTextField"
              style={{
                fontSize: "11pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",

                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                }}
              >
                Upload Number (csv file)
              </Typography>
              {pdfAlertMessage && (
                <small style={{ color: "#CB0101" }}>{pdfAlertMessage}</small>
              )}
            </Stack>

            <OutlinedInput
              type="file"
              name="attachment"
              size="small"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "11pt",
              }}
              onChange={handleAttachment}
              endAdornment={
                <InputAdornment position="end">
                  <CloudUpload sx={{ color: "#760505", fontSize: "16pt" }} />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                }}
              >
                Select State
              </Typography>
              {inputField.fieldErrorAlert === "state" && (
                <small style={{ color: "#CB0101" }}>*Select valid state</small>
              )}
            </Stack>

            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="state"
              defaultValue="Active"
              value={inputField.state}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Active"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="Inactive"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>

            {/* <Select
              name="state"
              size="small"
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Select state</em>
              </MenuItem>
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Inactive"}>Inactive</MenuItem>
            </Select> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.state &&
            inputField.contactlist_name &&
            attachment &&
            inputField.details ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                  handleNewContactSubmit();
                }}
              >
                Start
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Start
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddNewContactListForm;
