import { Settings } from "@mui/icons-material";
import {
  Alert,
  Box,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ConfirmModal from "./ConfirmModal";

const columns = [
  { label: "Title" },
  { label: "Subtitle" },
  { label: "Value" },
  { label: "Setting" },
];

const RightSide = ({ selectedReplyBtnInfo, selectedCustomer, render }) => {
  // console.log("rendertttttt:", render);
  const [allButton, setAllButton] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("renderuiu:", render);

  const [snackOpen, setSnackOpen] = useState(false);

  const handleClick = (message) => {
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `https://whatsapp.ghorami.com/api/chatbot_api/fun_wm_button_list_getAll.php?template_name=${selectedReplyBtnInfo?.tem_name}&button=${selectedReplyBtnInfo?.btn_value}&accn_id=${parsedObject?.accn_id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setAllButton(result);
      });
  }, [render]);
  return (
    <Box sx={{ paddingX: "30px" }}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      <ConfirmModal
        open={open}
        handleClose={handleClose}
        handleClick={handleClick}
        selectedReplyBtnInfo={selectedReplyBtnInfo}
      />

      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mb: "20px" }}
      >
        <Stack direction={"row"} alignItems="center" justifyContent={"center"}>
          <Typography variant="p" sx={{ fontSize: "11pt", fontWeight: 600 }}>
            Template Name{" "}
          </Typography>
          <Typography variant="p" sx={{ fontSize: "10pt" }}>
            {": " + allButton[0]?.template_name}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center" justifyContent={"center"}>
          <Typography variant="p" sx={{ fontSize: "11pt", fontWeight: 600 }}>
            Button Name{" "}
          </Typography>
          <Typography variant="p" sx={{ fontSize: "10pt" }}>
            {": " + allButton[0]?.button}
          </Typography>
        </Stack>
      </Stack>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ height: 420, overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <strong> {column.label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {allButton?.length > 0 && (
              <TableBody>
                {allButton?.map((item) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item.code}
                    >
                      <>
                        <TableCell align="center" size="small">
                          <Typography variant="p" sx={{ fontSize: "8pt" }}>
                            {item.title ? item.title : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" size="small">
                          <Typography variant="p" sx={{ fontSize: "8pt" }}>
                            {item.sub_title ? item.sub_title : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" size="small">
                          <Typography variant="p" sx={{ fontSize: "8pt" }}>
                            {item.title_value ? item.title_value : "-"}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" size="small">
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleOpen();
                              //   setAddNewBtn(false);
                              //   setTempUpdate(false);
                              //   setTempDetailsBtn(true);
                              //   setSelectedTempId(item?.id);
                            }}
                          >
                            <Settings
                              fontSize="small"
                              sx={{ fontSize: "12pt" }}
                            />
                          </IconButton>
                        </TableCell>
                      </>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={allTemplate.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      </Paper>
    </Box>
  );
};

export default RightSide;
