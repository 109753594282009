import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { Delete, Download, Settings } from "@mui/icons-material";

const columns = [
  { label: "Phone" },
  { label: "Phone Id" },
  { label: "Name" },
  { label: "Quality" },
  { label: "Messaging Limit" },
  { label: "Hit Count" },
  { label: "Country" },
  { label: "Status" },
  { label: "Certificate" },
  { label: "Delete" },
  { label: "Setting" },
];

export default function BusinessTable({ allPhoneNo }) {
  console.log("allPhoneNo:", allPhoneNo);
  const [page, setPage] = useState(0);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  //   };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 488, overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  size="small"
                  align="left"
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "9pt", fontWeight: 700 }}
                  >
                    {" "}
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allPhoneNo?.map((item) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                  <>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.phone ? item.phone : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.phone_id ? item.phone_id : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.name ? item.name : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.quality ? item.quality : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.number_limit ? item.number_limit : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.Hit ? item.Hit : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.country ? item.country : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.status ? item.status : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.certificate ? item.certificate : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <IconButton aria-label="delete" size="small">
                        <Delete fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <IconButton aria-label="delete" size="small">
                        <Settings fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                  </>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={allPhoneNo.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
