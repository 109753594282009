import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import {
  AttachFileOutlined,
  CallOutlined,
  Check,
  EmojiEmotionsOutlined,
  HighlightOff,
  KeyboardArrowUp,
  Language,
  MicNoneOutlined,
  MoreHoriz,
  PhotoCamera,
  SendOutlined,
} from "@mui/icons-material";
import NewContactForm from "../Conversation Components/New Contact/NewContactForm";
import AddNewContactListForm from "./Main Section Components/AddNewContactListForm";
import ContactDetailsTable from "./Main Section Components/ContactDetailsTable";

const ContactMainSection = ({
  leftBarBtn,
  newContactListBtn,
  setNewContactListBtn,
  referState,
  handleToggle,
  messageBtn,

  showTable,
  addNewBtn,

  selectedCustomer,

  setAddNewBtn,

  regNumberInfo,
  setRegNumberInfo,
  handleAddNewButtons,
}) => {
  const [contactDetails, setContactDetails] = useState([]);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    fetch(
      `https://whatsapp.ghorami.com/api/chat_contact/get_all_contact_details.php?contact_id=${referState}&accn_id=${parsedObject?.accn_id}&poster=${parsedObject?.poster}`
    )
      .then((res) => res.json())
      .then((result) => setContactDetails(result));
  }, [referState, addNewBtn]);
  return (
    <Box sx={{ boxSizing: "border-box" }}>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid gray",
          borderTop: 0,
          borderRadius: 0,
          paddingX: "20px",
          paddingY: "10px",
          boxSizing: "border-box",
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="left"
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{}}
              onClick={handleToggle}
            />
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 700 }}>
              {selectedCustomer?.Name}
            </Typography>
          </Stack>
          <Input
            id="standard-adornment-weight"
            //   value={values.weight}
            //   onChange={handleChange("weight")}
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            sx={{ width: "40%" }}
          />
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent="right"
            alignItems="center"
          >
            {selectedCustomer?.states == 1 ? (
              <Stack
                direction={"row"}
                justifyContent="right"
                alignItems="center"
                sx={{
                  paddingX: "12px",
                  paddingY: "2px",
                  bgcolor: "darkgreen",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "8pt", color: "white" }}
                >
                  LIVE
                </Typography>
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                justifyContent="right"
                alignItems="center"
                sx={{
                  paddingX: "12px",
                  paddingY: "2px",
                  bgcolor: "#c50808",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "8pt", color: "white" }}
                >
                  LIVE
                </Typography>
              </Stack>
            )}

            <IconButton aria-label="delete" size="small">
              <MoreHoriz fontSize="small" />
            </IconButton>
            <IconButton aria-label="delete" size="small">
              <Check fontSize="small" />
            </IconButton>
            {!messageBtn && (
              <Stack
                direction={"row"}
                justifyContent="right"
                alignItems="center"
                sx={{
                  paddingX: "12px",
                  paddingY: "2px",
                  bgcolor: "darkgreen",
                  borderRadius: "12px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleAddNewButtons();
                  setNewContactListBtn(false);
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "8pt", color: "white" }}
                >
                  ADD NEW
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          height: "90.8vh",
          paddingX: "20px",
          paddingY: "20px",
          borderRadius: 0,
          border: "1px solid gray",
          borderTop: 0,
          borderBottom: 0,
          bgcolor: "#eef0f1",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        {newContactListBtn && (
          <>
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
            >
              <Paper
                sx={{
                  width: "70%",
                  // height: "60vh",
                  // overflowY: "scroll",
                  paddingX: "25px",
                  paddingTop: "20px",
                  boxSizing: "border-box",
                }}
              >
                <AddNewContactListForm
                  setRegNumberInfo={setRegNumberInfo}
                  regNumberInfo={regNumberInfo}
                  setNewContactListBtn={setNewContactListBtn}
                  setAddNewBtn={setAddNewBtn}
                />
              </Paper>
            </Stack>
            <Button
              size="small"
              style={{ textTransform: "capitalize" }}
              href={
                "https://yeapbe.com/profile/login/files/1_download.php?val=profile/login/files/6a736d7a72686b321402202121/2ZCZUSG/MYSITIRLT9/F78dd804e1eb4be5df49ad7eeedfb698_contab.csv"
              }
              // target="_blank"
            >
              Sample contact list file
            </Button>
          </>
        )}
        {leftBarBtn == "contact" && addNewBtn && (
          <Stack direction={"row"} justifyContent="center" alignItems="center">
            <Paper
              sx={{
                width: "70%",
                // height: "60vh",
                // overflowY: "scroll",
                paddingX: "25px",
                paddingTop: "20px",
                boxSizing: "border-box",
              }}
            >
              <NewContactForm
                setRegNumberInfo={setRegNumberInfo}
                regNumberInfo={regNumberInfo}
                setAddNewBtn={setAddNewBtn}
                referState={referState}
              />
              {/* <NewContactTab
                setRegNumberInfo={setRegNumberInfo}
                regNumberInfo={regNumberInfo}
                setAddNewBtn={setAddNewBtn}
              /> */}
            </Paper>
          </Stack>
        )}
        {showTable && (
          <>
            <ContactDetailsTable contactDetails={contactDetails} />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default ContactMainSection;
