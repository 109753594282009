import {
  Anchor,
  Diamond,
  HighlightOff,
  Person,
  TextSnippet,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

const AddUserForm = ({ setAddNewBtn }) => {
  const [regNumberInfo, setRegNumberInfo] = useState({});
  console.log("regNumberInfosdsd:", regNumberInfo);
  const [inputField, setInputField] = useState({});
  console.log("inputField:", inputField);
  const [pdfAlertMessage, setPdfAlertMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const [allPhone_code, setAllPhone_code] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  console.log("categoryList:", categoryList);
  console.log("allPhone_code:", allPhone_code);

  useEffect(() => {
    fetch(`https://ghorami.com/profile/login/api_booking/country_list.php`)
      .then((res) => res.json())
      .then((result) => {
        setAllPhone_code(result);
      });
    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/get_business_category.php`
    )
      .then((res) => res.json())
      .then((result) => {
        setCategoryList(result);
      });
  }, []);

  const handleSend = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    regNumberInfo.p_user = parsedObject?.SopnoID;
    let others = {
      category: regNumberInfo.category,
      phone_code: regNumberInfo.phone_code,
      name: regNumberInfo.name,
      phone_number: regNumberInfo.phone_number,
      city: regNumberInfo.city,
      address: regNumberInfo.address,
      email: regNumberInfo.email,
      p_user: parsedObject?.SopnoID,
    };
    const formData = new FormData();
    formData.append("newUser", JSON.stringify(others));
    formData.append("profile_pic", regNumberInfo.profilePic);

    fetch(
      `https://whatsapp.ghorami.com/api/setting_ap/new_user_account_request.php`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result[0]?.account_id) {
          swal({
            title: result[0]?.resultState,
            text: result[0]?.resultMessage,
            icon: result[0]?.result,
            button: "Done",
          });
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const handleAttachment = (e) => {
    if (
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpeg"
    ) {
      // setAttachment(URL.createObjectURL(e.target.files[0]));
      console.log("eeerere:", e.target.files[0]);

      setAttachment(e.target.files[0]);
      let newInfo = regNumberInfo;
      newInfo[e.target.name] = e.target.files[0];
      setRegNumberInfo({ ...newInfo });
      setPdfAlertMessage("");
    } else {
      e.target.value = null;
      setPdfAlertMessage("This is not a valid file!");
    }
  };

  const handleAttachmentCancel = (name) => {
    let newInfo = regNumberInfo;
    newInfo[name] = {};
    setRegNumberInfo({ ...newInfo });
  };
  const clear = () => {
    setRegNumberInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "phone_number") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "category") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "email") {
      isFieldValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        event.target.value
      );
    }
    if (event.target.name === "phone_code") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }
    if (event.target.name === "city") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "address") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "description") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "url") {
      isFieldValid =
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
          event.target.value
        );
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newregNumberInfo = regNumberInfo;
      newregNumberInfo[event.target.name] = event.target.value;
      setRegNumberInfo({ ...newregNumberInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Phone Number
              </Typography>
              {inputField.fieldErrorAlert == "phone_number" && (
                <small style={{ color: "#CB0101" }}>
                  *Number field is empty
                </small>
              )}
            </Stack>

            <Stack direction={"row"} justifyContent="left">
              <Select
                displayEmpty
                onChange={handleChange}
                size="small"
                name="phone_code"
                placeholder="Request"
                sx={{
                  width: "15%",

                  borderRadius: "6px",
                  fontSize: "9pt",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {allPhone_code?.map((element, index) => (
                  <MenuItem key={index} value={element.phone_code}>
                    {element.phone_code}
                  </MenuItem>
                ))}
              </Select>
              <OutlinedInput
                type="number"
                name="phone_number"
                size="small"
                placeholder="Enter your phone number"
                sx={{
                  width: "85%",

                  borderRadius: "6px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  paddingY: "0px",
                  fontSize: "9pt",
                  boxSizing: "border-box",
                }}
                onChange={handleChange}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Name
              </Typography>
              {inputField.fieldErrorAlert === "name" && (
                <small style={{ color: "#CB0101" }}>*Name field is empty</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="name"
              size="small"
              placeholder="Enter your name"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Category
              </Typography>
              {inputField.fieldErrorAlert === "category" && (
                <small style={{ color: "#CB0101" }}>*Select category</small>
              )}
            </Stack>

            <Select
              displayEmpty
              onChange={handleChange}
              size="small"
              name="category"
              placeholder="Request"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {categoryList?.map((element, index) => (
                <MenuItem key={index} value={element.category_name}>
                  {element.category_name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Email
              </Typography>
              {inputField.fieldErrorAlert === "email" && (
                <small style={{ color: "#CB0101" }}>*Enter Valid email</small>
              )}
            </Stack>

            <OutlinedInput
              type="email"
              name="email"
              size="small"
              placeholder="Write answer"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                City
              </Typography>
              {inputField.fieldErrorAlert === "city" && (
                <small style={{ color: "#CB0101" }}>*Enter Valid city</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="city"
              size="small"
              placeholder="Write answer"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Address
              </Typography>
              {inputField.fieldErrorAlert === "address" && (
                <small style={{ color: "#CB0101" }}>*Enter Valid address</small>
              )}
            </Stack>

            <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter your address"
              // value={about}
              name="address"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",

                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Upload Profile Photo (jpg/png/ file)
              </Typography>
              {pdfAlertMessage && (
                <small style={{ color: "#CB0101" }}>{pdfAlertMessage}</small>
              )}
            </Stack>
          </Stack>

          <Stack
            spacing={0.5}
            direction="row"
            justifyContent={"center"}
            alignItems="center"
            sx={{ width: "100%", height: "auto" }}
          >
            <Button component="label">
              <Stack
                alignItems={"center"}
                justifyContent="center"
                sx={{
                  width: "80px",
                  height: "80px",
                  border: ".5px solid gray",
                  borderRadius: "4px",
                }}
              >
                <Person sx={{ fontSize: "16pt" }} />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "11pt",
                    fontWeight: 700,
                    textTransform: "capitalize",
                  }}
                >
                  Profile
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "11pt",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    lineHeight: "6px",
                  }}
                >
                  Photo
                </Typography>
              </Stack>
              <input
                type="file"
                hidden
                name="profilePic"
                size="small"
                sx={{
                  width: "100%",

                  borderRadius: "6px",
                  fontSize: "9pt",
                }}
                onChange={handleAttachment}
              />
            </Button>
          </Stack>
          <Stack spacing={0.5} sx={{ marginTop: "10px" }}>
            {regNumberInfo?.profilePic?.name && (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", fontWeight: 700 }}
                >
                  Profile Picture - {regNumberInfo?.profilePic?.name}
                </Typography>

                <HighlightOff
                  sx={{ fontSize: "11pt", cursor: "pointer" }}
                  onClick={() => {
                    handleAttachmentCancel("profilePic");
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.phone_number &&
            inputField.phone_code &&
            inputField.name &&
            inputField.category &&
            inputField.email &&
            inputField.city &&
            inputField.address &&
            inputField.category &&
            regNumberInfo?.profilePic?.name ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleSend();
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {}}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUserForm;
