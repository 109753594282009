import {
  AccountCircle,
  Add,
  AppRegistration,
  Category,
  PermDeviceInformation,
  Search,
  TipsAndUpdates,
  Token,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";

const GatewayDetails = ({ updateItem }) => {
  const inputEl = useRef(null);
  const [update, setUpdate] = useState(false);
  const [initialProduct, setInitialProduct] = useState("");
  const [initialVersion, setInitialVersion] = useState("");
  const [initialPhoneId, setInitialPhoneId] = useState("");
  const [initialAppId, setInitialAppId] = useState("");
  const [initialAppToken, setInitialAppToken] = useState("");
  const [product, setProduct] = useState("whatsapp");
  const [versionList, setVersionList] = useState([]);
  const [version, setVersion] = useState("");
  const [phoneId, setPhoneId] = useState("");
  const [appId, setAppId] = useState("");
  const [appToken, setAppToken] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [filter, setFilter] = useState("");
  const [allUser, setAllUser] = useState([]);
  console.log("allUser:", allUser);

  useEffect(() => {
    fetch(
      `https://whatsapp.ghorami.com/api/dev_api/get_all_wp_version.php?ref=${product}`
    )
      .then((res) => res.json())
      .then((result) => {
        setVersionList(result);
      });
  }, [product]);
  useEffect(() => {
    console.log("updateItemInside:", updateItem);
    setInitialProduct(updateItem?.product);
    setProduct(updateItem?.product);
    setInitialVersion(updateItem?.version);
    setVersion(updateItem?.version);
    setInitialPhoneId(updateItem?.phone_id);
    setPhoneId(updateItem?.phone_id);
    setInitialAppId(updateItem?.app_id);
    setAppId(updateItem?.app_id);
    setInitialAppToken(updateItem?.app_token);
    setAppToken(updateItem?.app_token);
  }, [updateItem?.id]);

  const handleSend = () => {
    console.log("ggclicked");
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let newGateWay = {};
    newGateWay.product = product;
    newGateWay.app_id = appId;
    newGateWay.version = version;
    newGateWay.phone_id = phoneId;
    newGateWay.app_token = appToken;
    newGateWay.p_user = parsedObject?.SopnoID;

    const formData = new FormData();
    formData.append("newGateway", JSON.stringify(newGateWay));

    fetch(`https://whatsapp.ghorami.com/api/dev_api/new_gateway.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result[0]?.account_id) {
          swal({
            title: result[0]?.resultState,
            text: result[0]?.resultMessage,
            icon: result[0]?.result,
            button: "Done",
          });
          setUpdate(false);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };
  const handleChange = (event) => {
    setAccountNumber(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };
  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };
  const handlePhoneIdChange = (event) => {
    setPhoneId(event.target.value);
  };
  const handleAppIdChange = (event) => {
    setAppId(event.target.value);
  };
  const handleAppTokenChange = (event) => {
    setAppToken(event.target.value);
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  return (
    <Stack
      spacing={3}
      sx={{
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="p" sx={{ fontWeight: 700, fontSize: "13pt" }}>
          Gateway Info
        </Typography>
        {!update && (
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              // console.log("dsds", inputEl.current.children[0].focus());
              setUpdate(true);
              inputEl.children[0].focus();
            }}
          >
            Update
          </Button>
        )}
        {update && (
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              handleSend();
              // setUpdate(false);
            }}
          >
            Save
          </Button>
        )}
      </Stack>

      <Stack>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
          sx={{ borderTop: "1px solid #e7e7e7", paddingY: "5px" }}
        >
          <Box sx={{ width: "12%", textAlign: "left" }}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Category sx={{ fontSize: "14pt", color: "#23b7a9" }} />

              <Typography variant="p" sx={{ fontSize: "12pt" }}>
                Product
              </Typography>
            </Stack>
          </Box>

          <Box sx={{ width: "90%", textAlign: "left" }}>
            {update && (
              <Select
                type="text"
                name="product"
                size="small"
                value={update ? product : initialProduct}
                sx={{
                  borderRadius: "4px",
                  fontSize: "10pt",
                  boxSizing: "border-box",
                  width: "24%",
                }}
                onChange={handleProductChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"whatsapp"}>whatsapp</MenuItem>
              </Select>
            )}
            {!update && (
              <OutlinedInput
                ref={inputEl}
                type="text"
                name="product"
                size="small"
                value={initialProduct}
                placeholder="Enter your phone number"
                // startAdornment={
                //   <InputAdornment position="start">
                //     <Search sx={{ fontSize: "13pt", cursor: "pointer" }} />
                //   </InputAdornment>
                // }
                sx={{
                  borderRadius: "4px",
                  fontSize: "10pt",
                  boxSizing: "border-box",
                }}
              />
            )}
          </Box>
        </Stack>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="center"
          justifyContent="left"
          sx={{ borderTop: "1px solid #e7e7e7", paddingY: "5px" }}
        >
          <Box sx={{ width: "12%", textAlign: "left" }}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <TipsAndUpdates sx={{ fontSize: "14pt", color: "#23b7a9" }} />
              <Typography variant="p" sx={{ fontSize: "12pt" }}>
                Version
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ width: "90%", textAlign: "left" }}>
            {update && (
              <Select
                type="text"
                name="version"
                size="small"
                value={update ? version : initialVersion}
                sx={{
                  borderRadius: "4px",
                  fontSize: "10pt",
                  boxSizing: "border-box",
                  width: "24%",
                }}
                onChange={handleVersionChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                {versionList?.map((element) => (
                  <MenuItem value={element?.ver_code}>
                    {element?.version_name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {!update && (
              <OutlinedInput
                type="text"
                name="version"
                size="small"
                value={initialVersion}
                placeholder="Enter your phone number"
                sx={{
                  borderRadius: "4px",
                  fontSize: "10pt",
                  boxSizing: "border-box",
                }}
              />
            )}
          </Box>
        </Stack>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="center"
          justifyContent="left"
          sx={{ borderTop: "1px solid #e7e7e7", paddingY: "5px" }}
        >
          <Box sx={{ width: "12%", textAlign: "left" }}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <PermDeviceInformation
                sx={{ fontSize: "14pt", color: "#23b7a9" }}
              />

              <Typography variant="p" sx={{ fontSize: "12pt" }}>
                Phone ID
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ width: "90%", textAlign: "left" }}>
            <OutlinedInput
              type="number"
              name="phoneId"
              size="small"
              value={update ? phoneId : initialPhoneId}
              placeholder="Enter your phone number"
              // startAdornment={
              //   <InputAdornment position="start">
              //     <Search sx={{ fontSize: "13pt", cursor: "pointer" }} />
              //   </InputAdornment>
              // }
              sx={{
                borderRadius: "4px",
                fontSize: "10pt",
                boxSizing: "border-box",
              }}
              onChange={handlePhoneIdChange}
            />
          </Box>
        </Stack>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="center"
          justifyContent="left"
          sx={{ borderTop: "1px solid #e7e7e7", paddingY: "5px" }}
        >
          <Box sx={{ width: "12%", textAlign: "left" }}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <AppRegistration sx={{ fontSize: "14pt", color: "#23b7a9" }} />

              <Typography variant="p" sx={{ fontSize: "12pt" }}>
                App ID
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ width: "90%", textAlign: "left" }}>
            <OutlinedInput
              type="text"
              name="appId"
              size="small"
              value={update ? appId : initialAppId}
              placeholder="Enter your phone number"
              // startAdornment={
              //   <InputAdornment position="start">
              //     <Search sx={{ fontSize: "13pt", cursor: "pointer" }} />
              //   </InputAdornment>
              // }
              sx={{
                borderRadius: "4px",
                fontSize: "10pt",
                boxSizing: "border-box",
              }}
              onChange={handleAppIdChange}
            />
          </Box>
        </Stack>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="center"
          justifyContent="left"
          sx={{ borderTop: "1px solid #e7e7e7", paddingY: "5px" }}
        >
          <Box sx={{ width: "12%", textAlign: "left" }}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Token sx={{ fontSize: "14pt", color: "#23b7a9" }} />

              <Typography variant="p" sx={{ fontSize: "12pt" }}>
                App token
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ width: "90%", textAlign: "left" }}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter your address"
              value={update ? appToken : initialAppToken}
              type="text"
              name="appToken"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "23.7%",
                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleAppTokenChange}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GatewayDetails;
