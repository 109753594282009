import { CloudUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const NewContactForm = ({
  setNewContactBtn,
  setAddNewBtn,
  setNewContactListBtn,
  handleCustomerData,
  handleMessageBtn,
  referState,
}) => {
  const [inputField, setInputField] = useState({});
  const [regNumberInfo, setRegNumberInfo] = useState({});
  console.log("referState2222:", referState);
  console.log("regNumberInfo:", regNumberInfo);
  const [pdfAlertMessage, setPdfAlertMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const [allPhone_code, setAllPhone_code] = useState([]);

  const [requestData, setRequestData] = useState("");
  const [fetchUser, setFetchUser] = useState([]);
  console.log("fetchUser:", fetchUser);
  const [fetchedPhone, setFetchedPhone] = useState("");
  console.log("fetchedPhone:", fetchedPhone);
  const [fetchedEmail, setFetchedEmail] = useState("");
  console.log("fetchedEmail:", fetchedEmail);
  const [fetchedName, setFetchedName] = useState("");
  const [fetchedLocation, setFetchedLocation] = useState("");
  const [fetchedAddress, setFetchedAddress] = useState("");
  const [fetchedAge_limit, setFetchedAge_limit] = useState("");
  const [fetchedGender, setFetchedGender] = useState("");
  const [fetchedProfession, setFetchedProfession] = useState("");
  const [fetchedCountry, setFetchedCountry] = useState("");

  useEffect(() => {
    fetch(`https://ghorami.com/profile/login/api_booking/country_list.php`)
      .then((res) => res.json())
      .then((result) => {
        setAllPhone_code(result);
      });
  }, []);
  useEffect(() => {
    const formData = new FormData();
    formData.append("query", requestData);

    fetch(
      `https://whatsapp.ghorami.com/api/wm_check_customer.php?customer_id=${requestData}`
    )
      .then((res) => res.json())
      .then((result) => {
        setFetchUser(result[0]);
        if (result[0].MobileNumber) {
          setFetchedPhone(result.p_phone);
          let newUser = regNumberInfo;
          newUser.mobile_number = result[0].MobileNumber;
          setRegNumberInfo(newUser);
        }
        if (result[0].Email) {
          setFetchedEmail(result[0].Email);
          let newUser = regNumberInfo;
          newUser.email = result[0].Email;
          setRegNumberInfo(newUser);
        }
        if (result[0].Name) {
          setFetchedName(result[0].Name);
          let newUser = regNumberInfo;
          newUser.name = result[0].Name;
          setRegNumberInfo(newUser);
        }
        if (result[0].Location) {
          setFetchedLocation(result[0].Location);
          let newUser = regNumberInfo;
          newUser.location = result[0].Location;
          setRegNumberInfo(newUser);
        }
        if (result[0].gender) {
          setFetchedGender(result[0].gender);
          let newUser = regNumberInfo;
          newUser.gender = result[0].gender;
          setRegNumberInfo(newUser);
        }
        if (result[0].Address) {
          setFetchedAddress(result[0].Address);
          let newUser = regNumberInfo;
          newUser.address = result[0].Address;
          setRegNumberInfo(newUser);
        }
        if (result[0].profession) {
          setFetchedProfession(result[0].profession);
          let newUser = regNumberInfo;
          newUser.profession = result[0].profession;
          setRegNumberInfo(newUser);
        }
        if (result[0].age_limit) {
          setFetchedAge_limit(result[0].age_limit);
          let newUser = regNumberInfo;
          newUser.age_limit = result[0].age_limit;
          setRegNumberInfo(newUser);
        }
        // setFetchedPhone(result[0].MobileNumber);
        // setFetchedEmail(result[0].Email);
        // setFetchedName(result[0].Name);
        // setFetchedLocation(result[0].Location);
        // setFetchedGender(result[0].gender);
        // setFetchedAddress(result[0].Address);
        // setFetchedProfession(result[0].profession);
        // setFetchedAge_limit(result[0].age_limit);
        console.log("resultc:", result[0]);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [requestData]);

  const handleNewContactSubmit = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();

    let newInfo = regNumberInfo;
    newInfo.referId = referState;
    setRegNumberInfo({ ...newInfo });

    formData.append("new_contact", JSON.stringify(regNumberInfo));
    formData.append("poster_id", parsedObject?.poster);
    formData.append("accn_id", parsedObject?.accn_id);

    fetch("https://whatsapp.ghorami.com/api/wm_customer_new.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        res.json();
      })

      .then((result) => {
        console.log(result);
        handleCustomerData();
        setNewContactBtn(false);
      })
      .catch((error) => console.log("newOrderApiError=> ", error));
  };

  const handleAttachment = (e) => {
    if (
      e.target.files[0].type == "application/pdf" ||
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpeg"
    ) {
      // setAttachment(gender.createObjectgender(e.target.files[0]));
      console.log("eeerere:", e.target.files[0]);

      setAttachment(e.target.files[0]);
      let newInfo = regNumberInfo;
      newInfo.attachment = e.target.files[0];
      setRegNumberInfo({ ...newInfo });
      setPdfAlertMessage("");
    } else {
      e.target.value = null;
      setPdfAlertMessage("This is not a valid file!");
    }
  };

  const clear = () => {
    // setNewContactListBtn(false);
    setRegNumberInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "name") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
      setFetchedName(event.target.value);
    }
    if (event.target.name === "country") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
      setFetchedCountry(event.target.value);
    }
    if (event.target.name === "mobile_number") {
      isFieldValid = /[0-9]+/.test(event.target.value);
      setFetchedPhone(event.target.value);
      setRequestData(event.target.value);
    }

    if (event.target.name === "email") {
      isFieldValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        event.target.value
      );
      setFetchedEmail(event.target.value);
      setRequestData(event.target.value);
    }
    if (event.target.name === "phone_code") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }
    if (event.target.name === "location") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
      setFetchedLocation(event.target.value);
    }
    if (event.target.name === "address") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
      setFetchedAddress(event.target.value);
    }
    if (event.target.name === "profession") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
      setFetchedProfession(event.target.value);
    }
    if (event.target.name === "age_limit") {
      isFieldValid = /[0-9]+/.test(event.target.value);
      setFetchedAge_limit(event.target.value);
    }
    if (event.target.name === "gender") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
      setFetchedGender(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newregNumberInfo = regNumberInfo;
      newregNumberInfo[event.target.name] = event.target.value;
      setRegNumberInfo({ ...newregNumberInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      const newregNumberInfo = regNumberInfo;
      newregNumberInfo[event.target.name] = "";
      setRegNumberInfo({ ...newregNumberInfo });
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Mobile Number
              </Typography>
            </Stack>

            <Stack direction={"row"} justifyContent="left">
              <Select
                displayEmpty
                onChange={handleChange}
                size="small"
                name="phone_code"
                placeholder="Request"
                sx={{
                  width: "30%",

                  borderRadius: "6px",
                  fontSize: "9pt",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {allPhone_code?.map((element, index) => (
                  <MenuItem key={index} value={element.phone_code}>
                    {element.phone_code}
                  </MenuItem>
                ))}
              </Select>
              <OutlinedInput
                type="number"
                name="mobile_number"
                size="small"
                value={fetchedPhone}
                placeholder="Enter your company name"
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  fontSize: "9pt",
                }}
                onChange={handleChange}
              />
            </Stack>
            {inputField.fieldErrorAlert === "mobile_number" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *Mobile number field empty
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Email
              </Typography>
            </Stack>

            <OutlinedInput
              type="email"
              name="email"
              size="small"
              value={fetchedEmail}
              placeholder="Write answer"
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
            {inputField.fieldErrorAlert === "email" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *Enter Valid email
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Name
              </Typography>
            </Stack>

            <OutlinedInput
              type="text"
              name="name"
              size="small"
              value={fetchedName}
              placeholder="Enter your phone number"
              sx={{
                width: "100%",

                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
            {inputField.fieldErrorAlert === "name" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *Name field empty
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Aproximate Age
              </Typography>
            </Stack>

            <OutlinedInput
              type="number"
              name="age_limit"
              size="small"
              value={fetchedAge_limit}
              placeholder="Enter your age"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
            {inputField.fieldErrorAlert === "age_limit" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *Age field empty
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Gender
              </Typography>
            </Stack>

            <Select
              name="gender"
              size="small"
              value={fetchedGender}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ fontSize: "9pt" }}
            >
              <MenuItem value="">
                <em>select one</em>
              </MenuItem>
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
            {inputField.fieldErrorAlert === "gender" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *profession field empty
              </Typography>
            )}
          </Stack>
        </Grid>{" "}
        <Grid item xs={4}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Profession
              </Typography>
            </Stack>

            <OutlinedInput
              type="text"
              name="profession"
              size="small"
              value={fetchedProfession}
              placeholder="Enter your profession"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
            {inputField.fieldErrorAlert === "profession" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *profession field empty
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Location
              </Typography>
            </Stack>

            <OutlinedInput
              type="text"
              name="location"
              size="small"
              value={fetchedLocation}
              placeholder="Write location"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
            {inputField.fieldErrorAlert === "location" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *Enter Valid location
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Country
              </Typography>
            </Stack>

            <Select
              name="country"
              size="small"
              // value={fetchedGender}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ fontSize: "9pt" }}
            >
              {allPhone_code?.map((element, index) => (
                <MenuItem key={index} value={element.ccode}>
                  {element.country_name}
                </MenuItem>
              ))}
            </Select>
            {inputField.fieldErrorAlert === "country" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *profession field empty
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                Address
              </Typography>
            </Stack>

            <OutlinedInput
              type="text"
              name="address"
              size="small"
              placeholder="Write answer"
              value={fetchedAddress}
              sx={{
                width: "100%",

                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
            {inputField.fieldErrorAlert === "address" && (
              <Typography style={{ color: "#CB0101", fontSize: "7pt" }}>
                *Enter Valid address
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                handleMessageBtn(true);
                setNewContactBtn(false);
                setAddNewBtn(false);
              }}
            >
              Back
            </Button>

            {regNumberInfo?.phone_code &&
            regNumberInfo?.mobile_number &&
            regNumberInfo?.name &&
            regNumberInfo?.age_limit &&
            regNumberInfo?.gender &&
            regNumberInfo?.profession &&
            regNumberInfo?.location &&
            regNumberInfo?.country &&
            regNumberInfo?.address ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                  handleNewContactSubmit();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                disabled
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewContactForm;
