import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const SystemMessageFormBot = ({
  handleSystemMessageBotSave,
  systemMessageInfo,
  setSystemMessageInfo,
  setAddNewBtn,
}) => {
  const [inputField, setInputField] = useState({});

  const clear = () => {
    setSystemMessageInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "question") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (event.target.name === "answer") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "referenceUrl") {
      isFieldValid =
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
          event.target.value
        );
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newSystemMessageInfo = systemMessageInfo;
      newSystemMessageInfo[event.target.name] = event.target.value;
      setSystemMessageInfo({ ...newSystemMessageInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };
  return (
    <Box sx={{ paddingY: "15px", paddingX: "20px" }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Question
              </Typography>
              {inputField.fieldErrorAlert === "question" && (
                <small style={{ color: "#CB0101" }}>
                  *Enter valid question
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="question"
              size="small"
              placeholder="Enter your question"
              sx={{
                width: "100%",

                borderRadius: "6px",
                padding: "2px",
                fontSize: "11pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Answer
              </Typography>
              {inputField.fieldErrorAlert === "answer" && (
                <small style={{ color: "#CB0101" }}>*Enter Valid answer</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="answer"
              size="small"
              placeholder="Write answer"
              sx={{
                width: "100%",

                borderRadius: "6px",
                padding: "2px",
                fontSize: "11pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "11pt",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                Reference Pic/Web Url
              </Typography>
              {inputField.fieldErrorAlert === "referenceUrl" && (
                <small style={{ color: "#CB0101" }}>*Enter valid link</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              size="small"
              name="referenceUrl"
              placeholder="Link"
              sx={{
                width: "100%",

                borderRadius: "6px",
                fontSize: "11pt",
              }}
              // value={videoUrl}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField?.question && inputField?.answer ? (
              <Button
                variant="contained"
                color="info"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: "lightBlue",
                  color: "black",
                }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save & Add New
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="info"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: "lightBlue",
                  color: "black",
                }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save & Add New
              </Button>
            )}

            {inputField?.question && inputField?.answer ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                  handleSystemMessageBotSave();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddNewBtn(false);
                }}
              >
                Save
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SystemMessageFormBot;
