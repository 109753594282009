import { AccountCircle, Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import BillingTable from "../../../Billing/BillingTable";
import DefaultGatewayModal from "./DefaultGatewayModal";
import GatewayTable from "./GatewayTable";
import BusinessTable from "./GatewayTable";

const GatewayTableRapper = ({
  setLeftSideButton,
  setAddNewBtn,
  setUpdateItem,
}) => {
  const [settingItem, setSettingItem] = useState({});
  console.log("settingItem:", settingItem);
  const [accountNumber, setAccountNumber] = useState("");
  const [filter, setFilter] = useState("");
  const [allGateway, setAllGateway] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (item) => {
    setSettingItem(item);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let accn_id = 12345678;

    // formData.append("token", Math.round(Math.random() * 10000000000));

    fetch(
      `https://whatsapp.ghorami.com/api/dev_api/get_all_gateway.php?user_id=${parsedObject?.SopnoID}&accn_id=${accn_id}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        setAllGateway(result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);

  const handleChange = (event) => {
    setAccountNumber(event.target.value);
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  return (
    <Box>
      <Stack
        spacing={1}
        sx={{
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="p" sx={{ fontWeight: 700, fontSize: "13pt" }}>
            All Gateway
          </Typography>

          <Select
            size="small"
            value={accountNumber}
            onChange={handleChange}
            displayEmpty
            sx={{ fontSize: "10px", width: "140px" }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" sx={{ fontSize: "10px" }}>
              <em>Ghorami Technology</em>
            </MenuItem>
            <MenuItem sx={{ fontSize: "10px" }} value={10}>
              Ten
            </MenuItem>
            <MenuItem sx={{ fontSize: "10px" }} value={20}>
              Twenty
            </MenuItem>
            <MenuItem sx={{ fontSize: "10px" }} value={30}>
              Thirty
            </MenuItem>
          </Select>
        </Stack>

        <Paper sx={{ padding: "10px" }}>
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent="left"
          >
            <OutlinedInput
              type="number"
              name="phone_number"
              size="small"
              placeholder="Enter your phone number"
              startAdornment={
                <InputAdornment position="start">
                  <Search sx={{ fontSize: "13pt", cursor: "pointer" }} />
                </InputAdornment>
              }
              sx={{
                borderRadius: "4px",
                fontSize: "10pt",
                boxSizing: "border-box",
              }}
              // onChange={handleChange}
            />
            <Select
              size="small"
              value={filter}
              onChange={handleFilterChange}
              displayEmpty
              sx={{ fontSize: "10pt", width: "150px" }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" sx={{ fontSize: "10pt" }}>
                <em>Download</em>
              </MenuItem>
              <MenuItem sx={{ fontSize: "10pt" }} value={10}>
                Ten
              </MenuItem>
              <MenuItem sx={{ fontSize: "10pt" }} value={20}>
                Twenty
              </MenuItem>
              <MenuItem sx={{ fontSize: "10px" }} value={30}>
                Thirty
              </MenuItem>
            </Select>
          </Stack>
          <GatewayTable
            allGateway={allGateway}
            setLeftSideButton={setLeftSideButton}
            setAddNewBtn={setAddNewBtn}
            setUpdateItem={setUpdateItem}
            handleOpen={handleOpen}
          />
        </Paper>
      </Stack>
      <DefaultGatewayModal
        open={open}
        handleClose={handleClose}
        settingItem={settingItem}
      />
    </Box>
  );
};

export default GatewayTableRapper;
