import { Add, RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddUserForm from "../Settings/MainSecSettings/Users/AddUserForm";
import TemplateMessageFormBot from "../Conversation Components/TemplateMessageFormBot";
import DashboardMain from "./DashboardMain";
import RegNumberForm from "../Settings/MainSecSettings/Bussiness/RegNumberForm";

const Dashboard = ({
  addNewBtn,
  templateMessageBotInfo,
  setTemplateMessageBotInfo,
  handleTemplateMessageSave,
  setAddNewBtn,
  referState,
}) => {
  return (
    <Box>
      {!addNewBtn && <DashboardMain setAddNewBtn={setAddNewBtn} />}
      {addNewBtn === "number" && (
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            width: "100%",
            bgcolor: "#dbf0f7",
            height: "100vh",
          }}
        >
          <Paper
            sx={{
              width: "50%",
              height: "auto",
              overflowY: "auto",
              paddingX: "25px",
              paddingTop: "20px",
              boxSizing: "border-box",
            }}
          >
            <Stack direction={"row"} justifyContent="center">
              <Typography
                variant="p"
                sx={{
                  fontSize: "14pt",
                  fontWeight: 600,
                }}
              >
                Add new number
              </Typography>
            </Stack>
            <RegNumberForm setAddNewBtn={setAddNewBtn} />
          </Paper>
        </Stack>
      )}
      {addNewBtn === "user" && (
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            width: "100%",
            bgcolor: "#dbf0f7",
            height: "100vh",
          }}
        >
          <Paper
            sx={{
              width: "50%",
              height: "auto",
              overflowY: "auto",
              paddingX: "25px",
              paddingTop: "20px",
              boxSizing: "border-box",
            }}
          >
            <AddUserForm setAddNewBtn={setAddNewBtn} />
          </Paper>
        </Stack>
      )}
      {addNewBtn === "template" && (
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            width: "100%",
            bgcolor: "#dbf0f7",
            height: "100vh",
          }}
        >
          <Paper
            sx={{
              width: "50%",
              height: "auto",
              overflowY: "auto",
              paddingX: "25px",
              paddingTop: "20px",
              boxSizing: "border-box",
            }}
          >
            <Stack direction={"row"} justifyContent="center">
              <Typography
                variant="p"
                sx={{
                  fontSize: "14pt",
                  fontWeight: 600,
                }}
              >
                Add new Template
              </Typography>
            </Stack>
            <TemplateMessageFormBot
              templateMessageBotInfo={templateMessageBotInfo}
              setTemplateMessageBotInfo={setTemplateMessageBotInfo}
              handleTemplateMessageSave={handleTemplateMessageSave}
              setAddNewBtn={setAddNewBtn}
              referState={referState}
            />
          </Paper>
        </Stack>
      )}
    </Box>
    // <Stack
    //   spacing={1}
    //   sx={{
    //     bgcolor: "#dbf0f7",
    //     width: "100%",
    //     height: "100vh",
    //     padding: "20px",
    //     boxSizing: "border-box",
    //   }}
    // >
    //   <Stack
    //     direction={"row"}
    //     alignItems="center"
    //     justifyContent="space-between"
    //   >
    //     <Typography
    //       variant="p"
    //       sx={{ fontSize: "14pt", fontWeight: 600, marginLeft: "12px" }}
    //     >
    //       Dashboard
    //     </Typography>
    //   </Stack>
    //   <Grid container spacing={2}>
    //     <Grid item xs={3}>
    //       <Box>
    //         <Card sx={{}}>
    //           <CardContent>
    //             <Stack spacing={1}>
    //               <Stack
    //                 direction={"row"}
    //                 justifyContent={"space-between"}
    //                 alignItems="center"
    //                 // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
    //               >
    //                 <Box sx={{ width: "25%" }}>
    //                   <Stack
    //                     justifyContent={"center"}
    //                     alignItems="center"
    //                     sx={{
    //                       height: "40px",
    //                       width: "40px",
    //                       bgcolor: "lightblue",
    //                     }}
    //                   >
    //                     <RemoveRedEye sx={{ color: "white" }} />
    //                   </Stack>
    //                 </Box>
    //                 <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
    //                   <Typography sx={{ fontSize: "11pt" }}>Balance</Typography>
    //                   <Typography sx={{ fontSize: "20pt" }}>
    //                     {dbSummery.balance}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //               <Divider></Divider>
    //               <Typography sx={{ fontSize: "10pt" }}>
    //                 Start from 1 jan 2023
    //               </Typography>
    //             </Stack>
    //           </CardContent>
    //         </Card>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={3}>
    //       <Box>
    //         <Card sx={{}}>
    //           <CardContent>
    //             <Stack spacing={1}>
    //               <Stack
    //                 direction={"row"}
    //                 justifyContent={"space-between"}
    //                 alignItems="center"
    //                 // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
    //               >
    //                 <Box sx={{ width: "25%" }}>
    //                   <Stack
    //                     justifyContent={"center"}
    //                     alignItems="center"
    //                     sx={{
    //                       height: "40px",
    //                       width: "40px",
    //                       bgcolor: "lightblue",
    //                     }}
    //                   >
    //                     <RemoveRedEye sx={{ color: "white" }} />
    //                   </Stack>
    //                 </Box>
    //                 <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
    //                   <Typography sx={{ fontSize: "11pt" }}>Bill</Typography>
    //                   <Typography sx={{ fontSize: "20pt" }}>
    //                     {dbSummery.bill}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //               <Divider></Divider>
    //               <Typography sx={{ fontSize: "10pt" }}>
    //                 Start from 1 jan 2023
    //               </Typography>
    //             </Stack>
    //           </CardContent>
    //         </Card>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={3}>
    //       <Box>
    //         <Card sx={{}}>
    //           <CardContent>
    //             <Stack spacing={1}>
    //               <Stack
    //                 direction={"row"}
    //                 justifyContent={"space-between"}
    //                 alignItems="center"
    //                 // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
    //               >
    //                 <Box sx={{ width: "25%" }}>
    //                   <Stack
    //                     justifyContent={"center"}
    //                     alignItems="center"
    //                     sx={{
    //                       height: "40px",
    //                       width: "40px",
    //                       bgcolor: "lightblue",
    //                     }}
    //                   >
    //                     <RemoveRedEye sx={{ color: "white" }} />
    //                   </Stack>
    //                 </Box>
    //                 <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
    //                   <Typography sx={{ fontSize: "11pt" }}>
    //                     Bot message
    //                   </Typography>
    //                   <Typography sx={{ fontSize: "20pt" }}>
    //                     {dbSummery.bot_message}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //               <Divider></Divider>
    //               <Typography sx={{ fontSize: "10pt" }}>
    //                 Start from 1 jan 2023
    //               </Typography>
    //             </Stack>
    //           </CardContent>
    //         </Card>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={3}>
    //       <Box>
    //         <Card sx={{}}>
    //           <CardContent>
    //             <Stack spacing={1}>
    //               <Stack
    //                 direction={"row"}
    //                 justifyContent={"space-between"}
    //                 alignItems="center"
    //                 // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
    //               >
    //                 <Box sx={{ width: "25%" }}>
    //                   <Stack
    //                     justifyContent={"center"}
    //                     alignItems="center"
    //                     sx={{
    //                       height: "40px",
    //                       width: "40px",
    //                       bgcolor: "lightblue",
    //                     }}
    //                   >
    //                     <RemoveRedEye sx={{ color: "white" }} />
    //                   </Stack>
    //                 </Box>
    //                 <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
    //                   <Typography sx={{ fontSize: "11pt" }}>Hit</Typography>
    //                   <Typography sx={{ fontSize: "20pt" }}>
    //                     {dbSummery.hit}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //               <Divider></Divider>
    //               <Typography sx={{ fontSize: "10pt" }}>
    //                 Start from 1 jan 2023
    //               </Typography>
    //             </Stack>
    //           </CardContent>
    //         </Card>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={3}>
    //       <Box>
    //         <Card sx={{}}>
    //           <CardContent>
    //             <Stack spacing={1}>
    //               <Stack
    //                 direction={"row"}
    //                 justifyContent={"space-between"}
    //                 alignItems="center"
    //                 // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
    //               >
    //                 <Box sx={{ width: "25%" }}>
    //                   <Stack
    //                     justifyContent={"center"}
    //                     alignItems="center"
    //                     sx={{
    //                       height: "40px",
    //                       width: "40px",
    //                       bgcolor: "lightblue",
    //                     }}
    //                   >
    //                     <RemoveRedEye sx={{ color: "white" }} />
    //                   </Stack>
    //                 </Box>
    //                 <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
    //                   <Typography sx={{ fontSize: "11pt" }}>Number</Typography>
    //                   <Typography sx={{ fontSize: "20pt" }}>
    //                     {dbSummery.number}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //               <Divider></Divider>
    //               {/* <Typography sx={{ fontSize: "10pt" }}>
    //                 Start from 1 jan 2023
    //               </Typography> */}
    //               <Button
    //                 startIcon={<Add sx={{ fontSize: "10pt" }} />}
    //                 sx={{ fontSize: "10pt", textTransform: "capitalize" }}
    //               >
    //                 Add new product
    //               </Button>
    //             </Stack>
    //           </CardContent>
    //         </Card>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={3}>
    //       <Box>
    //         <Card sx={{}}>
    //           <CardContent>
    //             <Stack spacing={1}>
    //               <Stack
    //                 direction={"row"}
    //                 justifyContent={"space-between"}
    //                 alignItems="center"
    //                 // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
    //               >
    //                 <Box sx={{ width: "25%" }}>
    //                   <Stack
    //                     justifyContent={"center"}
    //                     alignItems="center"
    //                     sx={{
    //                       height: "40px",
    //                       width: "40px",
    //                       bgcolor: "lightblue",
    //                     }}
    //                   >
    //                     <RemoveRedEye sx={{ color: "white" }} />
    //                   </Stack>
    //                 </Box>
    //                 <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
    //                   <Typography sx={{ fontSize: "11pt" }}>
    //                     Template
    //                   </Typography>
    //                   <Typography sx={{ fontSize: "20pt" }}>
    //                     {dbSummery.template}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //               <Divider></Divider>
    //               {/* <Typography sx={{ fontSize: "10pt" }}>
    //                 Start from 1 jan 2023
    //               </Typography> */}
    //               <Button
    //                 startIcon={<Add sx={{ fontSize: "10pt" }} />}
    //                 sx={{ fontSize: "10pt", textTransform: "capitalize" }}
    //               >
    //                 Add new template
    //               </Button>
    //             </Stack>
    //           </CardContent>
    //         </Card>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={3}>
    //       <Box>
    //         <Card sx={{}}>
    //           <CardContent>
    //             <Stack spacing={1}>
    //               <Stack
    //                 direction={"row"}
    //                 justifyContent={"space-between"}
    //                 alignItems="center"
    //                 // sx={{ height: "40px", width: "40px", bgcolor: "lightblue" }}
    //               >
    //                 <Box sx={{ width: "25%" }}>
    //                   <Stack
    //                     justifyContent={"center"}
    //                     alignItems="center"
    //                     sx={{
    //                       height: "40px",
    //                       width: "40px",
    //                       bgcolor: "lightblue",
    //                     }}
    //                   >
    //                     <RemoveRedEye sx={{ color: "white" }} />
    //                   </Stack>
    //                 </Box>
    //                 <Stack alignItems={"flex-start"} sx={{ width: "75%" }}>
    //                   <Typography sx={{ fontSize: "11pt" }}>User</Typography>
    //                   <Typography sx={{ fontSize: "20pt" }}>
    //                     {dbSummery.user}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //               <Divider></Divider>
    //               {/* <Typography sx={{ fontSize: "10pt" }}>
    //                 Start from 1 jan 2023
    //               </Typography> */}
    //               <Button
    //                 startIcon={<Add sx={{ fontSize: "10pt" }} />}
    //                 sx={{ fontSize: "10pt", textTransform: "capitalize" }}
    //               >
    //                 Add new user
    //               </Button>
    //             </Stack>
    //           </CardContent>
    //         </Card>
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </Stack>
  );
};

export default Dashboard;
