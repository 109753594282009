import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";

const columns = [
  { label: "Transaction ID" },
  { label: "Date" },
  { label: "Amount" },
  { label: "Payment method" },
  { label: "Payment status" },
  { label: "VAT Invoice ID" },
  { label: "Action" },
];

export default function BillingTable({ allBilling }) {
  console.log("allBilling:", allBilling);
  const [page, setPage] = useState(0);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  //   };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 398, overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align="left"
                  style={{ minWidth: column.minWidth }}
                >
                  <strong> {column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allBilling?.map((item) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                  <>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.trnx_id ? item.trnx_id : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.time_stamp ? item.time_stamp : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.amount ? item.amount : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.pay_method ? item.pay_method : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.pay_status == "1" ? "paid" : "due"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item.VAT_id ? item.VAT_id : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <IconButton aria-label="delete" size="small">
                        <Download fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={allBilling.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
